import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledRobotAssignSiteModal = styled(Modal)`
  .robot-assign-site__error-message {
    color: ${(props): string => props.theme.colors.semanticError};
    margin: 0px;
  }

  .robot-assign-site__select {
    width: 100%;
  }
`;
