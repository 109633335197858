import styled from 'styled-components';

export const StyledRobotRowList = styled.div`
  .robot-row-group__wrapper {
    background-color: ${(props): string => props.theme.colors.white};
    width: 100%;
    padding: 24px;
    margin: 8px 0;

    .robot-row-group__wrapper-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .robot-row-group__details-wrapper {
      max-height: 924px;
    }

    .robot-summary-row {
      &.robot-summary-row__faded-in {
        opacity: 0;
        animation: fadeIn 1000ms ease-out forwards;

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }

      &.robot-summary-row__faded-out {
        animation: fadeOut 1000ms ease-in-out forwards;

        @keyframes fadeOut {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
            display: none;
          }
        }
      }
    }
  }
`;
