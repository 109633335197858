import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import {
  CleaningWidgetsFleetDashboardActions,
  IGetCleaningListWithOperatingTimeRequestAction,
  IGetCleaningListWithOperatingTimeSuccessAction,
  IGetCleaningListWithOperatingTimeErrorAction,
} from './cleaningWidgetsFleetDashboardActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { MachinesListWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';

export function* getCleaningListWithOperatingTimeSaga(
  action: IGetCleaningListWithOperatingTimeRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachinesListWithCleaningReport>
  | PutEffect<IGetCleaningListWithOperatingTimeSuccessAction>
  | PutEffect<IGetCleaningListWithOperatingTimeErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.listMachinesWithOperatingTime, action.payload);

    yield* put(CleaningWidgetsFleetDashboardActions.getCleaningListWithOperatingTimeSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CleaningWidgetsFleetDashboardActions.getCleaningListWithOperatingTimeError({
        error,
      })
    );
  }
}

export function* cleaningWidgetsFleetDashboardSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(
    CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST,
    getCleaningListWithOperatingTimeSaga
  );
}
