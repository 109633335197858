import { FleetOperatingTime } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class FleetOperatingTimeSelectors {
  public static selectData = (state: IState): Optional<FleetOperatingTime> =>
    state.modules.cleaning.widgets['fleet-operating-time'].data;

  public static selectIsLoading = (state: IState): Optional<boolean> =>
    state.modules.cleaning.widgets['fleet-operating-time'].isLoading;
}
