import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Row, Col, Spin } from 'antd';
import { ThemeConstants } from '../../../../../../../../../../config/theme';
import { RobotBox } from '../../../RobotBox/RobotBox';
import { RobotBoxCV50 } from '../../../RobotCV50/RobotBoxCV50/RobotBoxCV50';
import { RobotKpiBar } from '../../../RobotKpiBar/RobotKpiBar';
import * as robotDashboardSelectors from '../../../../../state/RobotDashboardSelectors';
import { StyledRobotBoxList } from './RobotBoxList.styles';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import {
  MachineConnectionStatus,
  RobotDashboardKpIsData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';
import { SiteData } from 'app/modules/site-management/interfaces/Site.types';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { NoStyleButton } from 'lib/components/Button/NoStyleButton/NoStyleButton';

export const RobotBoxGroup = ({
  cleaningStatistic,
  isShowStatus,
  site,
  robots,
}: {
  cleaningStatistic: Optional<RobotDashboardKpIsData>;
  isShowStatus: boolean;
  site?: SiteData;
  robots?: Machine[];
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areSiteRobotsLoading = !!useSelector(robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsLoading);
  const areSiteRobotsWithCleaningStatisticLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithCleaningStatisticLoading
  );
  const areSiteRobotListPicturesLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsPicturesLoading
  );
  const areSiteRobotListTelemetriesLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsTelemetriesLoading
  );
  const areSiteRobotListLatestCtrLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsLatestCtrLoading
  );
  const areSiteRobotListLatestRoutineLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsLatestRoutineLoading
  );

  const areUnassignedRobotsLoading = !!useSelector(robotDashboardSelectors.selectIsRobotUnassignedListLoading);
  const areUnassignedRobotListPicturesLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListPicturesLoading
  );
  const areUnassignedRobotListTelemetriesLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListTelemetriesLoading
  );
  const areUnassignedRobotListLatestCtrLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListLatestCtrLoading
  );
  const areUnassignedRobotListLatestRoutineLoading = !!useSelector(
    robotDashboardSelectors.selectAreRobotUnassignedListLatestRoutineLoading
  );
  const areUnassignedRobotKpisLoading = !!useSelector(
    robotDashboardSelectors.selectIsRobotDashboardUnassignedRobotsKPIsLoading
  );

  const robotRealTimeUpdatedId = useSelector(robotDashboardSelectors.selectRobotDashboardRealTimeRobotId);
  const isRobotCardFadedOut = useSelector(robotDashboardSelectors.selectRobotDashboardRealTimeIsComponentFadedOut);

  const handleOpenRobotDetailsPanel = (robotId: string): void => {
    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: robotId }));
  };

  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  useEffect(
    () => () => {
      dispatch(DrawersActions.hideMachineDetailsDrawer());
    },
    [dispatch]
  );

  if (site?.machines?.data.length === 0) return <></>;

  if (
    site?.machines?.data.length === 0 ||
    site?.machines?.data.filter(robot =>
      isHidingOfflineRobots
        ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
          robot.connectionStatus !== MachineConnectionStatus.Unknown
        : robot
    ).length === 0
  ) {
    return <></>;
  }

  const renderGroupRobotBoxList = (
    robotsData: Optional<Machine[]>,
    areRobotListPicturesLoading: boolean,
    areRobotListTelemetriesLoading: boolean,
    areRobotListLatestCtrLoading: boolean,
    areRobotListLatestRoutineLoading: boolean
  ): JSX.Element => (
    <Row gutter={[ThemeConstants.XL_GAP, ThemeConstants.XL_GAP]} className="robot-box-group__row" justify="start">
      {robotsData &&
        robotsData?.length > 0 &&
        RobotUtils.getRobotListSorted(robotsData)
          .filter(robot =>
            isHidingOfflineRobots
              ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                robot.connectionStatus !== MachineConnectionStatus.Unknown
              : robot
          )
          .map(robot => (
            <Col
              key={robot.id}
              className={classnames({
                'robot-box__faded-out': robotRealTimeUpdatedId === robot.id && isRobotCardFadedOut,
                'robot-box__faded-in': robotRealTimeUpdatedId !== robot.id || !isRobotCardFadedOut,
              })}
            >
              <NoStyleButton onClick={(): void => handleOpenRobotDetailsPanel(robot.id)}>
                {RobotUtils.getRobotType(robot) === 'B50' ? (
                  <RobotBox
                    robot={robot}
                    isShowStatus={isShowStatus}
                    areRobotListPicturesLoading={areRobotListPicturesLoading}
                    areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
                    areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
                    areRobotListLatestRoutineLoading={areRobotListLatestRoutineLoading}
                  />
                ) : (
                  <RobotBoxCV50
                    robot={robot}
                    isShowStatus={isShowStatus}
                    areRobotListPicturesLoading={areRobotListPicturesLoading}
                    areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
                    areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
                    areRobotListLatestRoutineLoading={areRobotListLatestRoutineLoading}
                  />
                )}
              </NoStyleButton>
            </Col>
          ))}
    </Row>
  );

  return (
    <div className="robot-box-group__wrapper">
      <h4 className="robot-box-group__site-name">{site?.name || t('robotDashboard.unassigned')}</h4>

      <Spin spinning={areUnassignedRobotKpisLoading || areSiteRobotsWithCleaningStatisticLoading}>
        <RobotKpiBar
          totalCleaningHours={cleaningStatistic?.totalCleaningHrs}
          totalCleanedArea={cleaningStatistic?.totalCleanedArea}
          totalDistance={cleaningStatistic?.distanceDriven}
          taskCoverage={cleaningStatistic?.taskCoverage}
          taskCompletionRate={cleaningStatistic?.tasksCompleted}
        />
      </Spin>

      {site ? (
        <Spin spinning={areSiteRobotsLoading}>
          {renderGroupRobotBoxList(
            site?.machines?.data,
            areSiteRobotListPicturesLoading,
            areSiteRobotListTelemetriesLoading,
            areSiteRobotListLatestCtrLoading,
            areSiteRobotListLatestRoutineLoading
          )}
        </Spin>
      ) : (
        <Spin spinning={areUnassignedRobotsLoading}>
          {renderGroupRobotBoxList(
            robots,
            areUnassignedRobotListPicturesLoading,
            areUnassignedRobotListTelemetriesLoading,
            areUnassignedRobotListLatestCtrLoading,
            areUnassignedRobotListLatestRoutineLoading
          )}
        </Spin>
      )}
    </div>
  );
};

export const RobotBoxList = (): JSX.Element => {
  const sitesRobots = useSelector(robotDashboardSelectors.selectRobotListGroupedBySiteSiteList);
  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData);
  const unassignedRobotsKPIs = useSelector(robotDashboardSelectors.selectRobotDashboardUnassignedRobotsKPIsData);

  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  const isRenderUnassignedRobotBoxGroup = useMemo(
    () =>
      (unassignedRobots && unassignedRobots.length > 0) ||
      (unassignedRobots &&
        unassignedRobots.filter(robot =>
          isHidingOfflineRobots
            ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
              robot.connectionStatus !== MachineConnectionStatus.Unknown
            : robot
        ).length > 0),
    [unassignedRobots, isHidingOfflineRobots]
  );

  return (
    <StyledRobotBoxList>
      {sitesRobots.map(site => (
        <RobotBoxGroup
          key={site.id}
          cleaningStatistic={site?.cleaningStatistic?.data}
          site={site}
          isShowStatus={true}
        />
      ))}
      {isRenderUnassignedRobotBoxGroup && (
        // Group unassigned robots
        <RobotBoxGroup cleaningStatistic={unassignedRobotsKPIs} robots={unassignedRobots || []} isShowStatus={true} />
      )}
    </StyledRobotBoxList>
  );
};
