import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect, useDeepCompareMemo } from 'use-deep-compare';
import { Empty, Spin } from 'antd';
import * as robotDashboardSelectors from '../../../../state/RobotDashboardSelectors';
import { RobotDashboardActions } from '../../../../state/RobotDashboardSlice';
import { RobotAssignSiteModal } from '../../../../modals/components/RobotAssignSiteModal';
import { RobotUtils } from '../../../../../../../../utils/robot/RobotUtils';
import { selectRobotAssignSiteModalVisible } from '../../../../modals/state/RobotModalsSelectors';
import { RobotBoxList } from './RobotBoxList/RobotBoxList';
import { RobotRowList } from './RobotRowList/RobotRowList';
import { StyledRobotListGroupedBySite } from './RobotListGroupedBySite.styles';
import {
  MachineClassification,
  MachineConnectionStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { OpenSearch, UNASSIGNED } from 'config/constants';
import { RobotStatusDisplayName } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { SubscriptionMachineEvent } from 'app/modules/machine-inventory/interfaces/MachineSubscription.types';

interface IRobotListGroupedBySiteProps {
  isListLayout: boolean;
}

export const RobotListGroupedBySite = ({ isListLayout }: IRobotListGroupedBySiteProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sitesRobots = useSelector(robotDashboardSelectors.selectRobotListGroupedBySiteSiteList);
  const areSitesRobotsLoading = !!useSelector(robotDashboardSelectors.selectIsRobotListGroupedBySiteSiteListLoading);
  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData);
  const areUnassignedRobotsLoading = !!useSelector(robotDashboardSelectors.selectIsRobotUnassignedListLoading);

  const startDate = useSelector(robotDashboardSelectors.selectPeriodStartDate);
  const endDate = useSelector(robotDashboardSelectors.selectPeriodEndDate);
  const robotStatusesFilter = useSelector(robotDashboardSelectors.selectStatus);
  const activeSitesFilter = useSelector(robotDashboardSelectors.selectActiveSites);

  const isRobotAssignModalVisible = useSelector(selectRobotAssignSiteModalVisible);

  const robotRealTimeUpdatedEvent = useSelector(robotDashboardSelectors.selectRobotDashboardRealTimeUpdatedEvent);

  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  const unassignedRobotsFilter = useDeepCompareMemo(
    () =>
      unassignedRobots
        ? unassignedRobots
            .filter(robot =>
              isHidingOfflineRobots
                ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                  robot.connectionStatus !== MachineConnectionStatus.Unknown
                : robot
            )
            .map(robot => robot.id)
        : undefined,
    [unassignedRobots, isHidingOfflineRobots]
  );

  const haveNoRobots = sitesRobots.map(siteRobots => siteRobots.machines?.data.length === 0).every(el => el);
  const haveNoUnassignedRobots = !unassignedRobots || unassignedRobots.length === 0;

  const haveNoRobotsToDisplay = useMemo(
    () => (!sitesRobots || sitesRobots.length === 0 || haveNoRobots) && haveNoUnassignedRobots,
    [sitesRobots, haveNoRobots, haveNoUnassignedRobots]
  );

  const sitesMachineIds = useDeepCompareMemo(
    () =>
      sitesRobots.flatMap(siteRobots =>
        siteRobots.machines?.data
          .filter(robot =>
            isHidingOfflineRobots
              ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                robot.connectionStatus !== MachineConnectionStatus.Unknown
              : robot
          )
          .map(robot => robot.id)
      ),
    [sitesRobots, isHidingOfflineRobots]
  );

  useEffect(() => {
    const { connectionStatuses, robotStatuses } = RobotUtils.getRobotStatusesFilter(robotStatusesFilter);
    if (activeSitesFilter[0] === UNASSIGNED && activeSitesFilter.length === 1) {
      dispatch(RobotDashboardActions.robotListGroupedBySiteListSitesResetState());
      return;
    }
    dispatch(
      RobotDashboardActions.robotListGroupedBySiteListSitesMachinesRequest({
        filter: {
          ids: activeSitesFilter.length ? activeSitesFilter : undefined,
        },
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
        machinesOptions: {
          filter: {
            connectionStatuses: connectionStatuses.length > 0 ? connectionStatuses : undefined,
            robotStatuses: robotStatuses.length > 0 ? robotStatuses : undefined,
            classifications: [MachineClassification.Robot],
            isRobotStatusEmpty: robotStatusesFilter.includes(RobotStatusDisplayName.NA) || undefined,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        },
      })
    );
  }, [dispatch, activeSitesFilter, robotStatusesFilter]);

  useDeepCompareEffect(() => {
    const { connectionStatuses, robotStatuses } = RobotUtils.getRobotStatusesFilter(robotStatusesFilter);

    if (sitesMachineIds.length <= 0) return;
    if (activeSitesFilter[0] === UNASSIGNED && activeSitesFilter.length === 1) return;
    dispatch(
      RobotDashboardActions.robotListGroupedBySiteListSitesRequest({
        filter: {
          ids: activeSitesFilter.length ? activeSitesFilter : undefined,
        },
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
        cleaningStatisticFilter: {
          period: {
            endAt: endDate,
            startAt: startDate,
          },
          classifications: [MachineClassification.Robot],
          connectionStatuses: connectionStatuses.length > 0 ? connectionStatuses : undefined,
          robotStatuses: robotStatuses.length > 0 ? robotStatuses : undefined,
          machineIds: sitesMachineIds,
        },
      })
    );
  }, [dispatch, activeSitesFilter, robotStatusesFilter, startDate, endDate, sitesMachineIds]);

  useEffect(() => {
    const { connectionStatuses, robotStatuses } = RobotUtils.getRobotStatusesFilter(robotStatusesFilter);

    if (activeSitesFilter.includes(UNASSIGNED) || activeSitesFilter.length === 0) {
      dispatch(
        RobotDashboardActions.robotUnassignedListRequest({
          filter: {
            connectionStatuses: connectionStatuses.length > 0 ? connectionStatuses : undefined,
            robotStatuses: robotStatuses.length > 0 ? robotStatuses : undefined,
            classifications: [MachineClassification.Robot],
            isStandalone: true,
            isRobotStatusEmpty: robotStatusesFilter.includes(RobotStatusDisplayName.NA) || undefined,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, activeSitesFilter, robotStatusesFilter]);

  useDeepCompareEffect(() => {
    if (
      activeSitesFilter.includes(UNASSIGNED) ||
      (activeSitesFilter.length === 0 && robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.SITE_CHANGED)
    ) {
      dispatch(
        RobotDashboardActions.robotDashboardUnassignedRobotsKPIsRequest({
          filter: {
            period: {
              endAt: endDate,
              startAt: startDate,
            },
            machineIds: unassignedRobotsFilter,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, endDate, startDate, unassignedRobotsFilter, activeSitesFilter]);

  useEffect(
    () => (): void => {
      dispatch(RobotDashboardActions.robotListResetState());
      dispatch(RobotDashboardActions.robotUnassignedListResetState());
      dispatch(RobotDashboardActions.robotListGroupedBySiteListSitesResetState());
      dispatch(RobotDashboardActions.robotDashboardUnassignedRobotsKPIsResetState());
    },
    [dispatch]
  );

  if (areUnassignedRobotsLoading || areSitesRobotsLoading) {
    return (
      <StyledRobotListGroupedBySite>
        <div className="robot-list-grouped-by-site__load-indicator">
          <Spin size="default" />
        </div>
      </StyledRobotListGroupedBySite>
    );
  }

  return (
    <StyledRobotListGroupedBySite>
      {haveNoRobotsToDisplay ? (
        <Empty
          className="robot-list-grouped-by-site__empty-container"
          description={t('robotDashboard.noRobotsFoundMessage')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <>{isListLayout ? <RobotBoxList /> : <RobotRowList />}</>
      )}
      {isRobotAssignModalVisible && <RobotAssignSiteModal />}
    </StyledRobotListGroupedBySite>
  );
};
