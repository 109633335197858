import { singleton } from 'tsyringe';
import {
  CleaningTaskReportClient,
  CleaningTaskReportClientListOptions,
  CleaningTaskReportClientGetRobotRouteImageUrlOptions,
  CleaningTaskReportClientGetRobotRouteImageDataOptions,
} from './CleaningTaskReportClient';
import {
  CleaningTaskReportListResponse,
  CleaningTaskReportRouteImageResponse,
} from './interfaces/CleaningTaskReport.types';

export type CleaningTaskReportServiceListOptions = CleaningTaskReportClientListOptions;

export type CleaningTaskReportServiceRouteImageOptions = CleaningTaskReportClientGetRobotRouteImageUrlOptions;

export type CleaningTaskReportRestServiceDownloadOptions = CleaningTaskReportClientGetRobotRouteImageDataOptions;

@singleton()
export class CleaningTaskReportService {
  constructor(private cleaningTaskReportClient: CleaningTaskReportClient) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
  }: CleaningTaskReportServiceListOptions): Promise<CleaningTaskReportListResponse> => {
    const { data } = await this.cleaningTaskReportClient.list({ filter, sortOptions, paginationOptions });
    return data;
  };

  public getRobotRouteImageUrl = async ({
    machineId,
    finishedAt,
  }: CleaningTaskReportServiceRouteImageOptions): Promise<CleaningTaskReportRouteImageResponse> => {
    const { data } = await this.cleaningTaskReportClient.getRobotRouteImageUrl({ machineId, finishedAt });
    return data;
  };

  public getRobotRouteImageData = async ({
    robotRouteImageUrl,
  }: CleaningTaskReportRestServiceDownloadOptions): Promise<Blob> => {
    const { data } = await this.cleaningTaskReportClient.getRobotRouteImageData({ robotRouteImageUrl });
    return data;
  };
}
