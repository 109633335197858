import styled from 'styled-components';

export const StyledRobotBoxCV50 = styled.div`
  height: 100%;

  .ant-card {
    width: 280px;
    min-height: 325px;
    height: 100%;
    padding: 16px;
  }

  .ant-card-bordered {
    border: 2px solid ${(props): string => props.theme.colors.brightGrey};
  }

  .ant-card-body {
    padding: 0px;
    height: 100%;
  }

  .robot-box__header {
    display: flex;
    padding-bottom: 8px;
  }

  .robot-box__info {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .robot-box__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .robot-box__robot-info {
    margin-top: 7px;
  }

  .robot-box__title {
    ${(props): string => props.theme.fontStyles.p1.medium}
    text-transform: uppercase;
    padding-right: 0.5em;
  }

  .robot-box__sub-title {
    ${(props): string => props.theme.fontStyles.caption.news}
    text-transform: uppercase;
    padding-right: 0.5em;
  }

  .robot-box__assign-site-btn {
    padding: 0;
    width: inherit;
    height: 16px;
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    color: ${(props): string => props.theme.colors.semanticInfo};
    word-break: break-word;
    white-space: normal;
    margin-bottom: 8px;

    color: ${(props): string => props.theme.colors.semanticInfo};
    span {
      text-decoration: underline;
    }
  }

  span.robot-box__robot-site-name {
    margin: 8px 0;
    display: block;
    word-break: break-word;
    white-space: normal;
  }

  .robot-box__status {
    margin: 8px 0;
  }

  .robot-box__line {
    border: none;
    background-color: ${(props): string => props.theme.colors.brightGrey};
    margin: 8px 0;
    height: 1px;
    width: 100%;
  }

  .robot-box__cleaning-power {
    padding: 4px 0;
    justify-content: space-evenly;

    .ant-col {
      padding: 0 5px !important;
    }

    .robot-battery__label,
    .robot-water-level__label {
      margin-left: 16px;
    }
  }
`;
