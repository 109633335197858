import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  MutationCleaningReportSubscriptionUpdateBatchArgs,
  ResponseCleaningReportSubscriptions,
} from '../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';

export type CleaningClientReportSubscriptionOptions = MutationCleaningReportSubscriptionUpdateBatchArgs;

@injectable()
export class CleaningReportSubscriptionClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public get = async (): Promise<ApolloQueryResult<ResponseCleaningReportSubscriptions>> =>
    this.client.query({
      query: gql`
        query CleaningReportSubscriptions {
          cleaningReportSubscriptions {
            data {
              type
              interval
              formats
            }
          }
        }
      `,
    });

  public save = async ({
    lang,
    cleaningReportSubscriptions,
  }: CleaningClientReportSubscriptionOptions): Promise<FetchResult<ResponseCleaningReportSubscriptions>> =>
    this.client.mutate({
      mutation: gql`
        mutation CleaningReportSubscriptionUpdateBatch(
          $cleaningReportSubscriptions: [InputCleaningReportSubscription!]!
          $lang: SupportedLanguage!
        ) {
          cleaningReportSubscriptionUpdateBatch(
            lang: $lang
            cleaningReportSubscriptions: $cleaningReportSubscriptions
          ) {
            data {
              type
              interval
              formats
            }
          }
        }
      `,
      variables: {
        lang,
        cleaningReportSubscriptions,
      },
    });
}
