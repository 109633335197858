import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectRobotAssignSiteModalVisible = (state: IState): boolean =>
  state.modules.cleaning.widgets['robot-dashboard'].modals.robotAssignSiteModal.visible;

export const selectRobotAssignSiteModalIsLoading = (state: IState): boolean =>
  state.modules.cleaning.widgets['robot-dashboard'].modals.robotAssignSiteModal.isLoading;

export const selectRobotAssignSiteModalRobotId = (state: IState): string =>
  state.modules.cleaning.widgets['robot-dashboard'].modals.robotAssignSiteModal.robotId;

export const selectSitesListData = (state: IState): Optional<Site[]> =>
  state.modules.cleaning.widgets['robot-dashboard'].modals.site.data;

export const selectAreSitesListLoading = (state: IState): Optional<boolean> =>
  state.modules.cleaning.widgets['robot-dashboard'].modals.site.isLoading;
