import styled from 'styled-components';

export const StyledRobotDashboard = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('robot-dashboard')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('robot-dashboard')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('robot-dashboard')}

  min-height: inherit;

  .robot-dashboard__body-content--no-top-padding {
    padding-top: 0;
  }

  .robot-dashboard__header {
    background-color: ${(props): string => props.theme.colors.brightGrey};
  }

  .robot-dashboard__body {
    background-color: ${(props): string => props.theme.colors.brightGrey};
  }

  .robot-dashboard__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    text-transform: uppercase;
    margin: 0;
  }

  .robot-dashboard__kpi-bar {
    background-color: ${(props): string => props.theme.colors.white};
    margin: 24px 0;
    padding: 24px;
  }

  .robot-dashboard__kpi-bar-title {
    ${(props): string => props.theme.fontStyles.p1.medium};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    margin-bottom: 16px;
  }

  .robot-dashboard__short-info {
    display: inline-flex;
  }

  .robot-dashboard__view-list-icon-container {
    height: 32px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    :hover {
      .robot-dashboard__view-btn {
        background-color: transparent;
      }
    }
  }

  .robot-dashboard__view-btn {
    padding: 0;
    .ant-wave {
      box-shadow: none;
    }
  }
`;
