import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { RobotModalsActions } from './RobotModalsSlice';
import {
  RobotAssignSiteModalIsLoadingAction,
  RobotAssignSiteModalIsNotLoadingAction,
  RobotAssignToSiteErrorAction,
  RobotAssignToSiteRequestAction,
  RobotAssignToSiteSuccessAction,
  RobotHideAssignSiteModalAction,
  RobotShowAssignSiteModalAction,
  SitesListErrorAction,
  SitesListRequestAction,
  SitesListSuccessAction,
} from './RobotModalsActions.types';
import { SiteAssignMachineResponse, SiteList } from 'app/modules/site-management/interfaces/Site.types';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';

export function* robotAssignedToSite(
  action: RobotAssignToSiteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<SiteAssignMachineResponse>>
  | CallEffect<void>
  | SelectEffect
  | PutEffect<RobotAssignToSiteSuccessAction>
  | PutEffect<RobotAssignToSiteErrorAction>
  | PutEffect<RobotShowAssignSiteModalAction>
  | PutEffect<RobotHideAssignSiteModalAction>
  | PutEffect<RobotAssignSiteModalIsLoadingAction>
  | PutEffect<RobotAssignSiteModalIsNotLoadingAction>,
  void,
  IDependencies
> {
  const { siteId, machineIds } = action.payload;
  const { siteService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(RobotModalsActions.robotAssignSiteModalIsLoading());

    const response = yield* call(siteService.assignMachine, { siteId, machineIds });
    yield* put(RobotModalsActions.robotAssignToSiteSuccess(response));

    if (response?.siteAssignMachine.data) {
      yield* call(toastService.success, {
        message: t('robotDashboard.modals.assignSite.toast.success.message'),
        description: t('robotDashboard.modals.assignSite.toast.success.description'),
      });

      yield* put(RobotModalsActions.robotAssignSiteModalIsNotLoading());
      yield* put(RobotModalsActions.hideRobotAssignSiteModal());
    }
  } catch (error) {
    console.error(error);

    yield* call(toastService.error, {
      message: t('robotDashboard.modals.assignSite.toast.error.message'),
      description: (error as Error).message,
    });

    yield* put(
      RobotModalsActions.robotAssignToSiteError({
        error,
      })
    );

    yield* put(RobotModalsActions.robotAssignSiteModalIsNotLoading());
    yield* put(RobotModalsActions.hideRobotAssignSiteModal());
  }
}

export function* getSitesListSaga(
  action: SitesListRequestAction
): Generator<
  GetContextEffect | CallEffect<SiteList> | PutEffect<SitesListSuccessAction> | PutEffect<SitesListErrorAction>,
  void,
  IDependencies
> {
  const { siteService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(siteService.listForSelect, action.payload);

    yield* put(RobotModalsActions.sitesListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(RobotModalsActions.sitesListError({ error }));
  }
}

export function* robotModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(RobotModalsActions.robotAssignToSiteRequest, robotAssignedToSite);
  yield* takeLatest(RobotModalsActions.sitesListRequest, getSitesListSaga);
}
