import { useTranslation } from 'react-i18next';
import { RobotViewModel } from '../../../RobotViewModel';
import { StyledRobotCleaningProgress } from './RobotCleaningProgress.styles';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { BarProgress } from 'lib/components/Progress/BarProgress/BarProgress';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

export const RobotCleaningProgress = ({ robot }: { robot: Machine }): JSX.Element => {
  const { i18n } = useTranslation();
  const robotModelView = new RobotViewModel(robot);

  const formattedDate = DateTime.formatDateByLocale(
    i18n.language,
    robotModelView.getRobotCleaningRouteNameAndDate.date,
    DATE_FORMAT_PATTERN.DATE
  );
  const formattedTime = DateTime.formatDateByLocale(
    i18n.language,
    robotModelView.getRobotCleaningRouteNameAndDate.date,
    DATE_FORMAT_PATTERN.TIME_12_HOUR
  );

  const isRobotCleaning =
    robot.robotStatus === RobotStatus.Autonomous && robot.connectionStatus === MachineConnectionStatus.Online;

  return (
    <StyledRobotCleaningProgress>
      {isRobotCleaning ? (
        <BarProgress
          className="robot-cleaning-progress__progressing"
          percent={robotModelView.getRepeatPathProgressData.progress}
          size="small"
          status="active"
        />
      ) : (
        <div className="robot-cleaning-progress__next-progress">
          <span className="robot-cleaning-progress__date">{formattedDate}</span>
          <div>{formattedTime}</div>
        </div>
      )}
    </StyledRobotCleaningProgress>
  );
};
