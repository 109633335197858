import styled from 'styled-components';

export const StyledRobotKpiBar = styled.div`
  .robot-kpi-bar__title {
    ${(props): string => props.theme.fontStyles.heading3};
  }

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;

  .robot-kpi-bar__short-info {
    flex: 1;
  }

  @media screen and (max-width: 1056px) {
    .robot-kpi-bar__short-info {
      flex: 182px;
    }
  }
`;
