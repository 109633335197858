import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledFleetOperatingHoursChart } from './FleetOperatingHoursChart.styles';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import { FleetOperatingTime } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export type FleetOperatingHoursChartProps = FleetOperatingTime;

export const FleetOperatingHoursChart: React.FC<FleetOperatingHoursChartProps> = ({
  actualTotalOperatingTimeS = 0,
  plannedTotalOperatingTimeS = 0,
}): JSX.Element => {
  const { t } = useTranslation();
  const actualTotalOperatingTimeMs = actualTotalOperatingTimeS * 1000;
  const plannedTotalOperatingTimeMs = plannedTotalOperatingTimeS * 1000;

  let actualBarHeight = '100%';
  let plannedBarHeight = '100%';

  if (actualTotalOperatingTimeMs > plannedTotalOperatingTimeMs) {
    plannedBarHeight = [
      parseFloat(((plannedTotalOperatingTimeMs * 100) / actualTotalOperatingTimeMs).toFixed(0)),
      '%',
    ].join('');
  } else if (actualTotalOperatingTimeMs < plannedTotalOperatingTimeMs) {
    actualBarHeight = [
      parseFloat(((actualTotalOperatingTimeMs * 100) / plannedTotalOperatingTimeMs).toFixed(0)),
      '%',
    ].join('');
  }
  const isGcd = useSelector(authenticationSelectors.selectHasAccessToGCD);

  return (
    <StyledFleetOperatingHoursChart className="fleet-chart">
      <InfoTooltipGlobalStyles />
      <div className="fleet-chart__title">
        <h3>{t('overview.fleetDashboard.fleetOperatingHoursChart.title')}</h3>
        <Tooltip
          title={<InfoTooltip content="overview.fleetDashboard.fleetOperatingHoursChart.description" />}
          overlayClassName="tooltip-overlay"
          placement="bottomRight"
        >
          <div>
            <SvgIcon name="info" className="fleet-chart__info-icon" />
          </div>
        </Tooltip>
      </div>
      <div className="fleet-chart__wrapper">
        <div
          className="fleet-chart__actual"
          title={DateTime.formatDurationByMilliseconds({
            ms: actualTotalOperatingTimeMs,
            showMinutes: true,
            showSeconds: true,
            padWithZeros: true,
          })}
        >
          <div className="fleet-chart__col">
            <div className="fleet-chart__wrap-cols" style={{ height: actualBarHeight }}>
              <div className="fleet-chart__blank-col">
                <p className="fleet-chart__col-value">
                  {DateTime.formatDurationByMilliseconds({
                    ms: actualTotalOperatingTimeMs,
                    showMinutes: true,
                    showSeconds: false,
                    padWithZeros: true,
                  })}
                </p>
              </div>
              <div className="fleet-chart__actual-col" />
            </div>
          </div>
          <p className="fleet-chart__col-title">{t('overview.fleetDashboard.fleetOperatingHoursChart.actualHrs')}</p>
        </div>
        {isGcd && (
          <div
            className="fleet-chart__planned"
            title={DateTime.formatDurationByMilliseconds({
              ms: plannedTotalOperatingTimeMs,
              showMinutes: true,
              showSeconds: true,
              padWithZeros: true,
            })}
          >
            <div className="fleet-chart__col">
              <div className="fleet-chart__wrap-cols" style={{ height: plannedBarHeight }}>
                <div className="fleet-chart__planned-col" />
                <div className="fleet-chart__blank-col">
                  <p className="fleet-chart__col-value">
                    {DateTime.formatDurationByMilliseconds({
                      ms: plannedTotalOperatingTimeMs,
                      showMinutes: true,
                      showSeconds: false,
                      padWithZeros: true,
                    })}
                  </p>
                </div>
              </div>
            </div>
            <p className="fleet-chart__col-title">{t('overview.fleetDashboard.fleetOperatingHoursChart.plannedHrs')}</p>
          </div>
        )}
      </div>
    </StyledFleetOperatingHoursChart>
  );
};
