import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { TotalMachineOperatingHoursDatum } from '../interfaces/MachineOperatingHoursDashboardChart.types';
import { MachineOperatingHoursDashboardChartBar } from '../MachineOperatingHoursDashboardChartBar/MachineOperatingHoursDashboardChartBar';
import { StyledMachineOperatingHoursDashboardChartElement } from './MachineOperatingHoursDashboardChartElement.styles';
import { Maybe } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';

export interface MachineOperatingHoursDashboardChartElementProps {
  className: string;
  chartBarId: string;
  totalMachineOperatingHoursDatum: TotalMachineOperatingHoursDatum;
  operatingHoursMaximum: number;
}

export const MachineOperatingHoursDashboardChartElement = ({
  className,
  chartBarId,
  totalMachineOperatingHoursDatum,
  operatingHoursMaximum,
}: MachineOperatingHoursDashboardChartElementProps): Maybe<JSX.Element> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id, name } = totalMachineOperatingHoursDatum;
  const operatingHoursAsHours = DateTime.formatDurationByMilliseconds({
    ms: totalMachineOperatingHoursDatum.operatingTimeForPeriod?.actualTotalOperatingTimeMs,
    showMinutes: true,
    showSeconds: false,
    padWithZeros: true,
  });
  const operatingHoursWithMinutesAndSeconds = DateTime.formatDurationByMilliseconds({
    ms: totalMachineOperatingHoursDatum.operatingTimeForPeriod?.actualTotalOperatingTimeMs,
    showMinutes: true,
    showSeconds: true,
    padWithZeros: true,
  });

  const handleOpenMachineDetails = (): void => {
    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: id }));
  };

  useEffect(
    () => (): void => {
      dispatch(DrawersActions.hideMachineDetailsDrawer());
    },
    [dispatch]
  );

  if (
    !totalMachineOperatingHoursDatum.name ||
    isNil(totalMachineOperatingHoursDatum.operatingTimeForPeriod?.actualTotalOperatingTimeMs) ||
    isNil(operatingHoursMaximum)
  ) {
    return null;
  }

  return (
    <StyledMachineOperatingHoursDashboardChartElement
      className={classnames('machine-operating-hours-dashboard-chart-element', className)}
    >
      <div className="machine-operating-hours-dashboard-chart-element__name" title={name}>
        <TextButton
          onClick={handleOpenMachineDetails}
          className="machine-operating-hours-dashboard-chart-element__button--no-styling"
        >
          {name}
        </TextButton>
      </div>
      <div className="machine-operating-hours-dashboard-chart-element__bar">
        <MachineOperatingHoursDashboardChartBar
          key={chartBarId}
          totalMachineOperatingHoursDatum={totalMachineOperatingHoursDatum}
          operatingHoursMaximum={operatingHoursMaximum}
        />
      </div>
      <div
        className="machine-operating-hours-dashboard-chart-element__operating-hours"
        title={operatingHoursWithMinutesAndSeconds}
      >
        {operatingHoursAsHours}
        {t('cleaningReportList.exact.table.suffixes.duration')}
      </div>
    </StyledMachineOperatingHoursDashboardChartElement>
  );
};
