import styled from 'styled-components';

export const StyledRobotBoxList = styled.div`
  .robot-box-group__wrapper {
    background-color: ${(props): string => props.theme.colors.white};
    width: 100%;
    padding: 24px;
    margin: 8px 0;

    .robot-box-group__wrapper-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .robot-box-group__row {
      margin-top: 16px;

      .ant-col {
        height: auto;

        &.robot-box__faded-in {
          opacity: 0;
          animation: fadeIn 1000ms ease-out forwards;

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
        }

        &.robot-box__faded-out {
          animation: fadeOut 1000ms ease-in-out forwards;

          @keyframes fadeOut {
            0% {
              opacity: 1;
            }

            100% {
              opacity: 0;
              display: none;
            }
          }
        }

        .robot-box__wrapper {
          cursor: pointer;
        }
      }
    }
  }
`;
