import classnames from 'classnames';
import { Image } from 'antd';
import { StyledRobotImageConnectionStatus } from './RobotImageConnectionStatus.styles';
import { MachineConnectionStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export const RobotImageConnectionStatus = ({
  connectionStatus,
  imageUrl,
}: {
  connectionStatus: Optional<MachineConnectionStatus>;
  imageUrl: string;
}): JSX.Element => {
  const connectionStatusLowerCase = (connectionStatus || MachineConnectionStatus.Offline).toLowerCase();
  const className = 'robot-image-connection-status';
  const maskClassNames = classnames(`${className}__mask`, `${className}__mask--status-${connectionStatusLowerCase}`);

  return (
    <StyledRobotImageConnectionStatus className={className}>
      <Image src={imageUrl} preview={false} />
      <div className={maskClassNames} />
    </StyledRobotImageConnectionStatus>
  );
};
