import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

interface RobotBatteryPercentIconProps {
  batteryPercentageNumber: number;
}

export const RobotBatteryPercentIcon = ({ batteryPercentageNumber }: RobotBatteryPercentIconProps): JSX.Element => {
  if (batteryPercentageNumber >= 86) return <SvgIcon name="battery100Percent" />;

  if (batteryPercentageNumber >= 66) return <SvgIcon name="battery75Percent" />;

  if (batteryPercentageNumber >= 36) return <SvgIcon name="battery50Percent" />;

  if (batteryPercentageNumber >= 16) return <SvgIcon name="battery25Percent" />;

  if (batteryPercentageNumber <= 15) return <SvgIcon name="battery00Percent" />;

  return <SvgIcon name="battery00Percent" />;
};
