import { TotalMachineOperatingHoursDatum } from '../interfaces/MachineOperatingHoursDashboardChart.types';
import { StyledMachineOperatingHoursDashboardChartBar } from './MachineOperatingHoursDashboardChartBar.styles';
import { Maybe } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineOperatingHoursDashboardChartBarProps {
  key: string;
  totalMachineOperatingHoursDatum: TotalMachineOperatingHoursDatum;
  operatingHoursMaximum: number;
}

export const MachineOperatingHoursDashboardChartBar = ({
  totalMachineOperatingHoursDatum,
  operatingHoursMaximum,
}: MachineOperatingHoursDashboardChartBarProps): Maybe<JSX.Element> => {
  const operatingHours = totalMachineOperatingHoursDatum.operatingTimeForPeriod?.actualTotalOperatingTimeMs;
  let valueBarPercentage = 0;

  if (operatingHours !== 0 && operatingHoursMaximum !== 0) {
    const valueBarPercentageWithoutLimits: number = (100 / operatingHoursMaximum) * operatingHours;
    // Clamp the value to the range 0-100;
    valueBarPercentage = Math.min(Math.max(0, valueBarPercentageWithoutLimits), 100);
  }

  return (
    <StyledMachineOperatingHoursDashboardChartBar
      className="machine-operating-hours-dashboard-chart-bar"
      valueBarPercentage={valueBarPercentage}
      title={`${Math.round(valueBarPercentage)}%`}
    >
      <div
        className={`machine-operating-hours-dashboard-chart-bar__background-bar
        machine-operating-hours-dashboard-chart-bar__bar`}
      >
        <div
          className={`machine-operating-hours-dashboard-chart-bar__value-bar
          machine-operating-hours-dashboard-chart-bar__bar`}
        />
      </div>
    </StyledMachineOperatingHoursDashboardChartBar>
  );
};
