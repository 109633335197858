import styled from 'styled-components';

export const StyledRobotCleaningRoute = styled.div`
  .robot-cleaning-route__label,
  .robot-cleaning-route__details {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    margin: 0;
    width: auto;
    word-break: break-word;
    white-space: normal;
    display: inline-block;
  }

  .robot-cleaning-route__label {
    margin-bottom: 0px;
  }
`;
