import { singleton } from 'tsyringe';
import { Optional } from '../../../lib/types/Optional';
import {
  CleaningPlanClient,
  CleaningPlanClientCreateWorkIntervalOptions,
  CleaningPlanClientDeleteWorkIntervalOptions,
  CleaningPlanClientListWorkIntervalOptions,
  CleaningPlanClientUpdateWorkIntervalOptions,
} from './CleaningPlanClient';
import {
  WorkIntervalCreateResponse,
  CleaningPlanDeleteResponse,
  WorkIntervalUpdateResponse,
  WorkIntervalListResponse,
} from './interfaces/CleaningPlan.types';

export type CleaningPlanServiceListWorkIntervalOptions = CleaningPlanClientListWorkIntervalOptions;
export type CleaningPlanServiceCreateWorkIntervalOptions = CleaningPlanClientCreateWorkIntervalOptions;
export type CleaningPlanServiceDeleteWorkIntervalOptions = CleaningPlanClientDeleteWorkIntervalOptions;
export type CleaningPlanServiceUpdateWorkIntervalOptions = CleaningPlanClientUpdateWorkIntervalOptions;

@singleton()
export class CleaningPlanService {
  constructor(private cleaningPlanClient: CleaningPlanClient) {}

  public listWorkIntervals = async ({
    filter,
  }: CleaningPlanServiceListWorkIntervalOptions): Promise<WorkIntervalListResponse> => {
    const { data } = await this.cleaningPlanClient.listWorkIntervals({ filter });
    return data;
  };

  public createWorkInterval = async ({
    machineId,
    startHoursUtc,
    startMinutesUtc,
    durationMs,
    createdAt,
    weekdaysUtc,
    recurring,
  }: CleaningPlanServiceCreateWorkIntervalOptions): Promise<Optional<WorkIntervalCreateResponse>> => {
    const { data, errors } = await this.cleaningPlanClient.createWorkInterval({
      machineId,
      startHoursUtc,
      startMinutesUtc,
      durationMs,
      createdAt,
      weekdaysUtc,
      recurring,
    });

    if (errors?.length) throw errors;

    return data;
  };

  public deleteWorkInterval = async ({
    id,
  }: CleaningPlanServiceDeleteWorkIntervalOptions): Promise<Optional<CleaningPlanDeleteResponse>> => {
    const { data } = await this.cleaningPlanClient.deleteWorkInterval({
      id,
    });

    return data;
  };

  public updateWorkInterval = async ({
    id,
    input,
  }: CleaningPlanServiceUpdateWorkIntervalOptions): Promise<Optional<WorkIntervalUpdateResponse>> => {
    const { data, errors } = await this.cleaningPlanClient.updateWorkInterval({
      id,
      input,
    });

    if (errors?.length) throw errors;

    return data;
  };
}
