import styled from 'styled-components';
import { Drawer } from 'lib/components/Drawer/Drawer';

export const StyledReportSubscriptionDrawer = styled(Drawer)`
  height: 100%;
  ${(props): string => props.theme.fontStyles.redesign.base.normal};

  .report-subscription-drawer__header {
    flex-grow: 0;
  }

  .report-subscription-drawer__title {
    ${(props): string => props.theme.fontStyles.redesign.heading4};
    text-transform: uppercase;
  }

  .ant-drawer-header {
    border-bottom: none;
    padding-right: 32px;
    padding-left: 32px;
  }

  .ant-drawer-title {
    ${(props): string => props.theme.fontStyles.heading2}
    text-transform: uppercase;
    text-align: left;
  }

  .ant-drawer-body {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    gap: ${(props): string => props.theme.spacing.lg};
  }

  .report-subscription-drawer__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${(props): string => props.theme.spacing.xs};
    overflow: auto;
  }

  .report-subscription-drawer {
    ${(props): string => props.theme.fontStyles.p1.news};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .report-subscription-drawer__description {
    ${(props): string => props.theme.fontStyles.p1.news};
  }

  .report-subscription-drawer__event-report {
    display: flex;
    margin: 20px 0 0 0;
  }

  .report-subscription-drawer__robot-report {
    display: flex;
    margin: 20px 0 0 0;
  }

  .report-subscription-drawer__switch {
    margin: 0 20px 0 0;
  }

  .report-subscription-drawer__option {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
  }

  .report-subscription-drawer__input-checkbox {
    margin: 0;
  }

  .report-subscription-drawer__input-checkbox-wrapper {
    margin-bottom: 8px;
  }

  .report-subscription-drawer__input-group {
    gap: ${(props): string => props.theme.spacing.xs};
  }

  p.report-subscription-drawer__switch-title {
    font-family: ${(props): string => props.theme.fonts.p1Bold};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    margin-bottom: 0;
  }

  p.report-subscription-drawer__switch-description {
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    color: ${(props): string => props.theme.colors.darkGrey};
  }

  .report-subscription-drawer__option p {
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    margin-bottom: 0;
  }

  .report-subscription-drawer__option-checkbox-label {
    padding-left: ${(props): string => props.theme.spacing.xs};
  }

  .report-subscription-drawer__loading-overlay {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    justify-content: center;
    align-items: center;
  }

  .report-subscription-drawer__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;

    .submit-button,
    .cancel-button {
      font-family: ${(props): string => props.theme.fonts.redesign.lg.strong} !important;
      font-size: ${(props): string => props.theme.fontSizes.redesign.lg} !important;
      line-height: ${(props): string => props.theme.lineHeights.redesign.lg} !important;
      font-weight: ${(props): number => props.theme.fontWeights.redesign.lg} !important;
      padding: 8px 16px !important;
      height: 40px !important;
    }

    .cancel-button {
      border: 2px solid ${(props): string => props.theme.colors.gray100} !important;
    }

    .submit-button {
      border: none !important;
    }
  }
`;
