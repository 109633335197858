import styled from 'styled-components';
import { Row } from 'antd';

export const StyledRobotSummaryRowCV50 = styled(Row)`
  height: auto;
  border: 2px solid ${(props): string => props.theme.colors.brightGrey};
  background: ${(props): string => props.theme.colors.white};
  color: ${(props): string => props.theme.colors.black};
  margin: 16px 0px;
  padding: 16px;
  width: inherit;

  .ant-skeleton {
    width: 75%;

    .ant-skeleton-paragraph > li + li {
      margin-block-start: 4px;
    }
  }

  .robot-summary-row__robot-image--wrapper {
    margin-right: 8px;
    margin-left: -8px;
    margin-top: -4px;

    .offline-status-mask,
    .unknown-status-mask {
      top: 0px;
    }

    img {
      margin-top: 0px;
    }
  }

  .robot-connection-status-indicator__wrapper {
    width: 75px;
  }

  .robot-summary-row__robot-info--wrapper {
    vertical-align: baseline;
  }

  .robot-summary-row__robot-site-name {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    ${(props): string => props.theme.mixins.truncateText}
    width: auto;
    word-break: break-word;
    white-space: normal;
  }

  .robot-summary-row__robot-cleaning-route--wrapper {
    margin: auto;
  }

  .robot-summary-row__robot-progress--wrapper {
    justify-content: space-between;
  }

  .robot-summary-row__robot-progress--wrapper {
    .robot-cleaning-progress__progressing {
      float: right;
      width: 100%;
    }
  }

  .robot-image__status-text {
    display: flex;
    align-items: center;
    font-size: 8px;
    span {
      margin-top: 1px;
    }
  }
  .robot-summary-row__robot-cleaning-status--wrapper {
    margin: auto;

    .robot-summary-row__assign-site-btn {
      width: inherit;
      height: inherit;
      font-family: ${(props): string => props.theme.fonts.captionNews};
      font-size: ${(props): string => props.theme.fontSizes.p1};
      font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
      line-height: ${(props): string => props.theme.lineHeights.p1};
      color: ${(props): string => props.theme.colors.semanticInfo};
      word-break: break-word;
      white-space: normal;
      span {
        text-decoration: underline;
      }
    }

    .robot-summary-row__robot-site-name-container {
      width: inherit;
      height: inherit;
      margin: 0 14px;
      white-space: normal;
      text-align: left;
      word-break: break-word;
    }
  }

  .robot-summary-row__robot-battery--wrapper,
  .robot-summary-row__robot-water-level--wrapper {
    .robot-battery__wrapper,
    .robot-water-level__wrapper {
      justify-content: flex-end;
    }

    .robot-battery__data,
    .robot-water-level__data {
      align-items: center;
      width: 42px;

      .odometer-wrapper {
        font-family: ${(props): string => props.theme.fonts.captionMedium};
        font-weight: 500;
        font-size: 13px;
        height: 13px;
        line-height: 13px;
      }

      .robot-battery__unit,
      .robot-water-level__unit {
        font-family: ${(props): string => props.theme.fonts.captionMedium};
        font-weight: ${(props): number => props.theme.fontWeights.captionMedium};
        font-size: 12px;
      }
    }

    .robot-battery__label {
      float: right;
      margin-right: 16px;
    }

    .robot-water-level__label {
      float: right;
    }
  }
`;
