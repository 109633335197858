import { StyledRobotName } from './RobotName.styles';

export const RobotName = ({
  machineName,
  serialNumber,
}: {
  machineName: string;
  serialNumber: string;
}): JSX.Element => (
  <StyledRobotName>
    <p className="robot-info__name">{machineName}</p>
    <p title={serialNumber} className="robot-info__number">
      {serialNumber}
    </p>
  </StyledRobotName>
);
