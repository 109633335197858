import styled from 'styled-components';

export const StyledRobotImageConnectionStatus = styled.div`
  .robot-image-connection-status__mask--status-offline,
  .robot-image-connection-status__mask--status-unknown {
    position: absolute;
    mix-blend-mode: screen;
    background-color: ${(props): string => props.theme.colors.grey300};
    top: 12px;
  }

  img {
    margin-top: 12px;
  }
`;
