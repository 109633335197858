import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import classnames from 'classnames';
import { RobotTotalCleanedHourChartBar } from '../RobotTotalCleanedHourChartBar/RobotTotalCleanedHourChartBar';
import { StyledRobotTotalCleanedHourChartElement } from './RobotTotalCleanedHourChartElement.styles';
import { RoutePaths } from 'config/route-paths';
import {
  Maybe,
  RobotDashboardTotalCleanedHrsData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface IRobotTotalCleanedHourChartElementProps {
  className: string;
  keyIndex: string;
  totalCleanedHoursDatum: RobotDashboardTotalCleanedHrsData;
  cleanedHoursMaximum: number;
}

export const RobotTotalCleanedHourChartElement = ({
  className,
  keyIndex,
  totalCleanedHoursDatum,
  cleanedHoursMaximum,
}: IRobotTotalCleanedHourChartElementProps): Maybe<JSX.Element> => {
  const { t } = useTranslation();

  if (!totalCleanedHoursDatum.machineName || isNil(totalCleanedHoursDatum) || isNil(cleanedHoursMaximum)) {
    return null;
  }

  const { machineId, machineName } = totalCleanedHoursDatum;

  return (
    <StyledRobotTotalCleanedHourChartElement
      className={classnames('robot-total-cleaned-hour-chart-element', className)}
    >
      <div className="robot-total-cleaned-hour-chart-element__name" title={machineName}>
        <Link
          to={RoutePaths.MACHINE_DETAILS.replace(':id', machineId)}
          className={classnames(
            'robot-total-cleaned-hour-chart-element__link',
            'robot-total-cleaned-hour-chart-element__link--no-styling'
          )}
        >
          {machineName}
        </Link>
      </div>
      <div className="robot-total-cleaned-hour-chart-element__bar">
        <RobotTotalCleanedHourChartBar
          key={keyIndex}
          totalCleanedHoursDatum={totalCleanedHoursDatum}
          cleanedHoursMaximum={cleanedHoursMaximum}
        />
      </div>
      <div className="robot-total-cleaned-hour-chart-element__cleaned-area" title={totalCleanedHoursDatum.machineName}>
        {totalCleanedHoursDatum.cleanedHrs.toFixed(2)}&#160;{t('common.hUnit')}
      </div>
    </StyledRobotTotalCleanedHourChartElement>
  );
};
