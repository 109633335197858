import styled from 'styled-components';

export const StyledMachineOperatingHoursDashboardChartElement = styled.div`
  display: flex;
  font-family: ${(props): string => props.theme.fonts.captionSmall};
  font-size: ${(props): string => props.theme.fontSizes.captionSmall};
  line-height: ${(props): string => props.theme.lineHeights.captionSmall};
  font-weight: ${(props): number => props.theme.fontWeights.captionSmall};

  .machine-operating-hours-dashboard-chart-element__name {
    flex-basis: 22%;
    ${(props): string => props.theme.mixins.truncateText}
  }

  .machine-operating-hours-dashboard-chart-element__bar {
    display: flex;
    flex-basis: 65%;
    margin-left: 12.5px;
    margin-right: 12.5px;
    flex-direction: column;
    justify-content: center;
  }

  .machine-operating-hours-dashboard-chart-element__operating-hours {
    flex-basis: 13%;
    color: ${(props): string => props.theme.colors.darkGrey};
    ${(props): string => props.theme.mixins.truncateText}
  }

  .machine-operating-hours-dashboard-chart-element__button--no-styling {
    height: unset;
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    padding: unset;
  }
`;
