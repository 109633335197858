import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import classnames from 'classnames';
import { RobotTotalCleanedAreaChartBar } from '../RobotTotalCleanedAreaChartBar/RobotTotalCleanedAreaChartBar';
import { StyledRobotTotalCleanedAreaChartElement } from './RobotTotalCleanedAreaChartElement.styles';
import { RoutePaths } from 'config/route-paths';
import {
  Maybe,
  RobotDashboardTotalCleanedAreaData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';

export interface IRobotTotalCleanedAreaChartElementProps {
  className: string;
  keyIndex: string;
  totalCleanedAreasDatum: RobotDashboardTotalCleanedAreaData;
  cleanedAreasMaximum: number;
}

export const RobotTotalCleanedAreaChartElement = ({
  className,
  keyIndex,
  totalCleanedAreasDatum,
  cleanedAreasMaximum,
}: IRobotTotalCleanedAreaChartElementProps): Maybe<JSX.Element> => {
  const { t } = useTranslation();
  const isUSusers = false;

  if (!totalCleanedAreasDatum.machineName || isNil(totalCleanedAreasDatum) || isNil(cleanedAreasMaximum)) {
    return null;
  }

  const { machineId, machineName } = totalCleanedAreasDatum;

  return (
    <StyledRobotTotalCleanedAreaChartElement
      className={classnames('robot-total-cleaned-area-chart-element', className)}
    >
      <div className="robot-total-cleaned-area-chart-element__name" title={machineName}>
        <Link
          to={RoutePaths.MACHINE_DETAILS.replace(':id', machineId)}
          className={classnames(
            'robot-total-cleaned-area-chart-element__link',
            'robot-total-cleaned-area-chart-element__link--no-styling'
          )}
        >
          {machineName}
        </Link>
      </div>
      {isUSusers ? (
        <>
          <div className="robot-total-cleaned-area-chart-element__bar">
            <RobotTotalCleanedAreaChartBar
              key={keyIndex}
              totalCleanedAreasDatum={totalCleanedAreasDatum}
              cleanedAreasMaximum={ConvertUnitUtils.covertSquareMetersToSquareFeet(cleanedAreasMaximum)}
            />
          </div>
          <div
            className="robot-total-cleaned-area-chart-element__cleaned-area"
            title={totalCleanedAreasDatum.machineName}
          >
            {ConvertUnitUtils.covertSquareMetersToSquareFeet(totalCleanedAreasDatum.cleanedArea)}&#160;
            {t('common.areaUnitFeetSquare')}
          </div>
        </>
      ) : (
        <>
          <div className="robot-total-cleaned-area-chart-element__bar">
            <RobotTotalCleanedAreaChartBar
              key={keyIndex}
              totalCleanedAreasDatum={totalCleanedAreasDatum}
              cleanedAreasMaximum={cleanedAreasMaximum}
            />
          </div>
          <div
            className="robot-total-cleaned-area-chart-element__cleaned-area"
            title={totalCleanedAreasDatum.machineName}
          >
            {totalCleanedAreasDatum.cleanedArea}&#160;{t('robotDashboard.squareUnit')}
          </div>
        </>
      )}
    </StyledRobotTotalCleanedAreaChartElement>
  );
};
