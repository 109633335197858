import styled from 'styled-components';

export const StyledRobotConnectionStatusIndicator = styled.div`
  &.robot-connection-status-indicator__wrapper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;

    .robot-connection-status-indicator__indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
      background-color: ${(props): string => props.theme.colors.semanticSuccess};
      flex-grow: 1;

      &.offline-status,
      &.unknown-status {
        background-color: ${(props): string => props.theme.colors.darkGrey};
      }
    }

    .robot-connection-status-indicator__text {
      font-size: 10px;
      margin-top: 2px;
      flex-grow: 1;
      width: auto;
      word-break: break-word;
      white-space: normal;
    }
  }
`;
