import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  WorkIntervalCreateResponse,
  CleaningPlanDeleteResponse,
  WorkIntervalListResponse,
  IWorkIntervalUtc,
  InCreation,
  WorkIntervalUpdateResponse,
} from './interfaces/CleaningPlan.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  MutationWorkIntervalDeleteArgs,
  QueryWorkIntervalsArgs,
  MutationWorkIntervalUpdateArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type CleaningPlanClientListWorkIntervalOptions = QueryWorkIntervalsArgs;
export type CleaningPlanClientCreateWorkIntervalOptions = InCreation<IWorkIntervalUtc>;
export type CleaningPlanClientDeleteWorkIntervalOptions = MutationWorkIntervalDeleteArgs;
export type CleaningPlanClientUpdateWorkIntervalOptions = MutationWorkIntervalUpdateArgs;

@injectable()
export class CleaningPlanClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public listWorkIntervals = async ({
    filter,
  }: CleaningPlanClientListWorkIntervalOptions): Promise<ApolloQueryResult<WorkIntervalListResponse>> =>
    this.client.query({
      query: gql`
        query CleaningPlans($filter: InputFilterWorkIntervalsList!) {
          workIntervals(filter: $filter) {
            data {
              weekdaysUtc
              startMinutesUtc
              startHoursUtc
              recurring
              machine {
                id
                name
                type {
                  name
                }
              }
              id
              durationMs
              createdAt
            }
          }
        }
      `,
      variables: {
        filter,
      },
    });

  public createWorkInterval = async ({
    machineId,
    startHoursUtc,
    startMinutesUtc,
    durationMs,
    createdAt,
    weekdaysUtc,
    recurring,
  }: CleaningPlanClientCreateWorkIntervalOptions): Promise<FetchResult<WorkIntervalCreateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation WorkIntervalCreate($input: InputWorkIntervalCreate!) {
          workIntervalCreate(input: $input) {
            data {
              weekdaysUtc
              startMinutesUtc
              startHoursUtc
              recurring
              id
              durationMs
              customerId
              createdAt
            }
          }
        }
      `,
      variables: {
        input: {
          machineId,
          startHoursUtc,
          startMinutesUtc,
          durationMs,
          createdAt,
          weekdaysUtc,
          recurring,
        },
      },
    });

  public deleteWorkInterval = async ({
    id,
  }: CleaningPlanClientDeleteWorkIntervalOptions): Promise<FetchResult<CleaningPlanDeleteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation WorkIntervalDelete($id: ID!) {
          workIntervalDelete(id: $id) {
            data
          }
        }
      `,
      variables: {
        id,
      },
    });

  public updateWorkInterval = async ({
    id,
    input,
  }: CleaningPlanClientUpdateWorkIntervalOptions): Promise<FetchResult<WorkIntervalUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation WorkIntervalUpdate($id: ID!, $input: InputWorkIntervalUpdate!) {
          workIntervalUpdate(id: $id, input: $input) {
            data {
              id
              startHoursUtc
              startMinutesUtc
              durationMs
              createdAt
              weekdaysUtc
              recurring
            }
          }
        }
      `,
      variables: {
        id,
        input,
      },
    });
}
