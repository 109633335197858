import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { RobotUtils } from '../../../../../../../utils/robot/RobotUtils';
import { StyledRobotCleaningStatus } from './RobotCleaningStatus.styles';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export const RobotCleaningStatus = ({
  robotStatus,
  connectionStatus,
}: {
  robotStatus: Optional<RobotStatus>;
  connectionStatus: MachineConnectionStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const robotStatusDisplayName = RobotUtils.getRobotStatusDisplayName(robotStatus, connectionStatus);
  const className = classnames(robotStatusDisplayName.toLowerCase().concat('-status'), 'robot-cleaning-status__data');
  return (
    <StyledRobotCleaningStatus title={t(`robotDashboard.${robotStatusDisplayName}`)} className={className}>
      {t(`robotDashboard.${robotStatusDisplayName}`)}
    </StyledRobotCleaningStatus>
  );
};
