import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { MachinesListWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachineClientListMachinesWithOperatingTimeOptions } from 'app/modules/machine-inventory/MachineClient';

export type IGetCleaningListWithOperatingTimeRequestOptions = MachineClientListMachinesWithOperatingTimeOptions;

export type IGetCleaningListWithOperatingTimeRequestAction = Action<
  typeof CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST
> & {
  payload: MachineClientListMachinesWithOperatingTimeOptions;
};

export type IGetCleaningListWithOperatingTimeSuccessOptions = MachinesListWithCleaningReport;
export type IGetCleaningListWithOperatingTimeSuccessAction = Action<
  typeof CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_SUCCESS
> & {
  payload: MachinesListWithCleaningReport;
};

export type IGetCleaningListWithOperatingTimeErrorAction = IErrorAction<
  typeof CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_ERROR
>;

export class CleaningWidgetsFleetDashboardActions {
  public static GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST =
    'CLEANING_WIDGETS_FLEET_DASHBOARD::GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST';

  public static GET_CLEANING_LIST_WITH_OPERATING_TIME_SUCCESS =
    'CLEANING_WIDGETS_FLEET_DASHBOARD::GET_CLEANING_LIST_WITH_OPERATING_TIME_SUCCESS';

  public static GET_CLEANING_LIST_WITH_OPERATING_TIME_ERROR =
    'CLEANING_WIDGETS_FLEET_DASHBOARD::GET_CLEANING_LIST_WITH_OPERATING_TIME_ERROR';

  public static RESET_STATE = 'SITE_WIDGETS_FLEET_DASHBOARD::RESET_STATE';

  public static getCleaningListWithOperatingTimeRequest({
    filter,
    paginationOptions,
    sortOptions,
    period,
  }: IGetCleaningListWithOperatingTimeRequestOptions): IGetCleaningListWithOperatingTimeRequestAction {
    return {
      type: CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST,
      payload: {
        filter,
        paginationOptions,
        sortOptions,
        period,
      },
    };
  }

  public static getCleaningListWithOperatingTimeSuccess(
    data: IGetCleaningListWithOperatingTimeSuccessOptions
  ): IGetCleaningListWithOperatingTimeSuccessAction {
    return {
      type: CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_SUCCESS,
      payload: data,
    };
  }

  public static getCleaningListWithOperatingTimeError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningListWithOperatingTimeErrorAction {
    return {
      type: CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_ERROR,
      error,
    };
  }

  public static resetState(): Action<typeof CleaningWidgetsFleetDashboardActions.RESET_STATE> {
    return {
      type: CleaningWidgetsFleetDashboardActions.RESET_STATE,
    };
  }
}
