import styled from 'styled-components';

export const StyledRobotTotalCleanedHourChartElement = styled.div`
  display: flex;
  font-family: ${(props): string => props.theme.fonts.captionSmall};
  font-size: ${(props): string => props.theme.fontSizes.captionSmall};
  line-height: ${(props): string => props.theme.lineHeights.captionSmall};
  font-weight: ${(props): number => props.theme.fontWeights.captionSmall};

  .robot-total-cleaned-hour-chart-element__name {
    flex-basis: 15%;
    ${(props): string => props.theme.mixins.truncateText}
  }

  .robot-total-cleaned-hour-chart-element__bar {
    display: flex;
    flex-basis: 72%;
    margin-left: 12.5px;
    margin-right: 12.5px;
    flex-direction: column;
    justify-content: center;
  }

  .robot-total-cleaned-hour-chart-element__cleaned-hour {
    flex-basis: 13%;
    color: ${(props): string => props.theme.colors.darkGrey};
    ${(props): string => props.theme.mixins.truncateText}
  }

  .robot-total-cleaned-hour-chart-element__link--no-styling {
    color: ${(props): string => props.theme.colors.black};
  }
`;
