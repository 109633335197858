import {
  CleaningReportSubscription,
  CleaningReportSubscriptionReportFormat,
  CleaningReportSubscriptionType,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface CleaningReportSubscriptionForm {
  machinesCheckboxReport: boolean;
  eventXLS: boolean;
  robotCheckboxReport: boolean;
  robotXLS: boolean;
  reportInterval: string;
}

/**
 * This function converts the data returned from the API to the format required by the form.
 *
 * @param data - data returned from the API
 * @returns converted data for the form
 */
export const convertResData = (data: CleaningReportSubscription[]): CleaningReportSubscriptionForm => {
  const converted: CleaningReportSubscriptionForm = {
    machinesCheckboxReport: false,
    eventXLS: false,
    robotCheckboxReport: false,
    robotXLS: false,
    reportInterval: '',
  };

  data.forEach(({ interval, type, formats }) => {
    const isEvent = type === CleaningReportSubscriptionType.Events;
    const isRobot = type === CleaningReportSubscriptionType.Robot;
    const isXLS = !!formats.find((format: string) => format === CleaningReportSubscriptionReportFormat.Excel);
    if (isEvent) {
      converted.machinesCheckboxReport = isEvent;
      converted.reportInterval = interval;
      converted.eventXLS = isXLS;
    } else {
      converted.robotCheckboxReport = isRobot;
      converted.reportInterval = interval;
      converted.robotXLS = isXLS;
    }
  });
  return converted;
};

// This function converts data from the form to a format that can be sent to the backend
// to save the user's subscription. The function takes the data from the form (input)
// and returns an array of CleaningReportSubscription objects.
// The input object represents the data from the form.
// The CleaningReportSubscription object represents the data that needs to be sent
// to the backend to save the user's subscription.

export const convertDataToSave = (input: CleaningReportSubscriptionForm): CleaningReportSubscription[] => {
  const allSubscriptions = [];

  if (input.machinesCheckboxReport) {
    allSubscriptions.push({
      interval: input.reportInterval,
      type: CleaningReportSubscriptionType.Events,
      formats: [input.eventXLS && CleaningReportSubscriptionReportFormat.Excel].filter(Boolean),
    });
  }

  if (input.robotCheckboxReport) {
    allSubscriptions.push({
      interval: input.reportInterval,
      type: CleaningReportSubscriptionType.Robot,
      formats: [input.robotXLS && CleaningReportSubscriptionReportFormat.Excel].filter(Boolean),
    });
  }

  return allSubscriptions as CleaningReportSubscription[];
};
