export const orderMap = {
  Autonomous: 0,
  Exploration: 1,
  Docking: 2,
  Idle: 3,
  ManualCleaning: 4,
  Charging: 5,
  null: 6,
};

export const REPEAT_PROGRESS_DEFAULT = {
  progress: 0,
  repeatPathId: '0',
  routeName: 'N/A',
};

export const SCHEDULED_TIME_DEFAULT = [
  {
    routineId: 'routineId',
    taskId: 'taskId',
    routeName: 'N/A',
    startDate: '',
    taskName: 'N/A',
  },
];

export const BATTERY_DATA_DEFAULT = {
  batteryPack1: {
    stateOfCharge: 0,
  },
  batteryPack2: {
    stateOfCharge: 0,
  },
};

// To prevent when testing developers add CV50 or CV 50
export const KIRA_CV50 = 'KIRA CV50';
export const KIRA_CV_50 = 'KIRA CV 50';

export const ROBOT_WATER_TANK_CAPACITY: { [key: string]: number } = {
  'kira b50': 60,
  'kira b 50': 60,
  'kira b200': 200,
  'kira b 200': 200,
  'kira bd20': 20,
  'kira bd 20': 20,
};
