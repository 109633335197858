import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import {
  CleaningPlanClientDeleteWorkIntervalOptions,
  CleaningPlanClientUpdateWorkIntervalOptions,
} from '../CleaningPlanClient';
import { CleaningPlanServiceListWorkIntervalOptions } from '../CleaningPlanService';
import {
  WorkIntervalCreateResponse,
  CleaningPlanDeleteResponse,
  WorkIntervalListResponse,
  InCreation,
  IWorkIntervalUtc,
  WorkIntervalUpdateResponse,
} from '../interfaces/CleaningPlan.types';
import { Optional } from 'lib/types/Optional';

// List work intervals
export type IGetWorkIntervalsRequestOptions = CleaningPlanServiceListWorkIntervalOptions;
export type IGetWorkIntervalsRequestAction = Action<typeof CleaningActions.GET_WORK_INTERVALS_REQUEST> & {
  payload: IGetWorkIntervalsRequestOptions;
};

export type IGetWorkIntervalsSuccessOptions = WorkIntervalListResponse;
export type IGetWorkIntervalsSuccessAction = Action<typeof CleaningActions.GET_WORK_INTERVALS_SUCCESS> & {
  payload: WorkIntervalListResponse;
};

export type IGetWorkIntervalsErrorAction = IErrorAction<typeof CleaningActions.GET_WORK_INTERVALS_ERROR>;

export type IWorkIntervalsIsLoadingAction = Action<typeof CleaningActions.WORK_INTERVALS_IS_LOADING>;

// Create work interval
export interface ICreateWorkIntervalRequestOptions {
  siteId: string;
  workIntervalUtc: InCreation<IWorkIntervalUtc>;
}
export type ICreateWorkIntervalRequestAction = Action<typeof CleaningActions.CREATE_WORK_INTERVAL_REQUEST> & {
  payload: ICreateWorkIntervalRequestOptions;
};

export type ICreateWorkIntervalSuccessOptions = Optional<WorkIntervalCreateResponse>;
export type ICreateWorkIntervalSuccessAction = Action<typeof CleaningActions.CREATE_WORK_INTERVAL_REQUEST> & {
  payload: Optional<WorkIntervalCreateResponse>;
};

export type ICreateWorkIntervalErrorAction = IErrorAction<typeof CleaningActions.CREATE_WORK_INTERVAL_ERROR>;

// Update Work Interval
export interface IUpdateWorkIntervalRequestOptions extends CleaningPlanClientUpdateWorkIntervalOptions {
  siteId: string;
}
export type IUpdateWorkIntervalRequestAction = Action<typeof CleaningActions.UPDATE_WORK_INTERVAL_REQUEST> & {
  payload: IUpdateWorkIntervalRequestOptions;
};

export type IUpdateWorkIntervalSuccessOptions = Optional<WorkIntervalUpdateResponse>;
export type IUpdateWorkIntervalSuccessAction = Action<typeof CleaningActions.UPDATE_WORK_INTERVAL_REQUEST> & {
  payload: Optional<WorkIntervalUpdateResponse>;
};

export type IUpdateWorkIntervalErrorAction = IErrorAction<typeof CleaningActions.UPDATE_WORK_INTERVAL_ERROR>;

// Delete Work Interval
export interface IDeleteWorkIntervalRequestOptions extends CleaningPlanClientDeleteWorkIntervalOptions {
  siteId: string;
}
export type IDeleteWorkIntervalRequestAction = Action<typeof CleaningActions.DELETE_WORK_INTERVAL_REQUEST> & {
  payload: IDeleteWorkIntervalRequestOptions;
};

export type IDeleteWorkIntervalSuccessOptions = Optional<CleaningPlanDeleteResponse>;
export type IDeleteWorkIntervalSuccessAction = Action<typeof CleaningActions.DELETE_WORK_INTERVAL_REQUEST> & {
  payload: Optional<CleaningPlanDeleteResponse>;
};

export type IDeleteWorkIntervalErrorAction = IErrorAction<typeof CleaningActions.DELETE_WORK_INTERVAL_ERROR>;

// Poll work intervals
export enum IPollWorkIntervalOperation {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
}

export interface IPollWorkIntervalsRequestOptions {
  siteId: string;
  workIntervalId: string;
  operation: IPollWorkIntervalOperation;
}

export type IPollWorkIntervalsRequestAction = Action<typeof CleaningActions.GET_WORK_INTERVALS_REQUEST> & {
  payload: IPollWorkIntervalsRequestOptions;
};

export class CleaningActions {
  public static GET_WORK_INTERVALS_REQUEST = 'CLEANING::GET_WORK_INTERVALS_REQUEST';
  public static GET_WORK_INTERVALS_SUCCESS = 'CLEANING::GET_WORK_INTERVALS_SUCCESS';
  public static GET_WORK_INTERVALS_ERROR = 'CLEANING::GET_WORK_INTERVALS_ERROR';
  public static WORK_INTERVALS_IS_LOADING = 'CLEANING::WORK_INTERVALS_IS_LOADING';

  public static CREATE_WORK_INTERVAL_REQUEST = 'CLEANING::CREATE_WORK_INTERVAL_REQUEST';
  public static CREATE_WORK_INTERVAL_SUCCESS = 'CLEANING::CREATE_WORK_INTERVAL_SUCCESS';
  public static CREATE_WORK_INTERVAL_ERROR = 'CLEANING::CREATE_WORK_INTERVAL_ERROR';
  public static RESET_CREATE_WORK_INTERVAL_ERROR = 'CLEANING::RESET_CREATE_WORK_INTERVAL_ERROR';

  public static DELETE_WORK_INTERVAL_REQUEST = 'CLEANING::DELETE_WORK_INTERVAL_REQUEST';
  public static DELETE_WORK_INTERVAL_SUCCESS = 'CLEANING::DELETE_WORK_INTERVAL_SUCCESS';
  public static DELETE_WORK_INTERVAL_ERROR = 'CLEANING::DELETE_WORK_INTERVAL_ERROR';

  public static UPDATE_WORK_INTERVAL_REQUEST = 'CLEANING::UPDATE_WORK_INTERVAL_REQUEST';
  public static UPDATE_WORK_INTERVAL_SUCCESS = 'CLEANING::UPDATE_WORK_INTERVAL_SUCCESS';
  public static UPDATE_WORK_INTERVAL_ERROR = 'CLEANING::UPDATE_WORK_INTERVAL_ERROR';
  public static RESET_UPDATE_WORK_INTERVAL_ERROR = 'CLEANING::RESET_UPDATE_WORK_INTERVAL_ERROR';

  public static POLL_WORK_INTERVALS_REQUEST = 'CLEANING::POLL_WORK_INTERVALS_REQUEST';

  public static createWorkIntervalRequest({
    siteId,
    workIntervalUtc,
  }: ICreateWorkIntervalRequestOptions): ICreateWorkIntervalRequestAction {
    return {
      type: CleaningActions.CREATE_WORK_INTERVAL_REQUEST,
      payload: {
        siteId,
        workIntervalUtc,
      },
    };
  }

  public static getWorkIntervalsRequest({ filter }: IGetWorkIntervalsRequestOptions): IGetWorkIntervalsRequestAction {
    return {
      type: CleaningActions.GET_WORK_INTERVALS_REQUEST,
      payload: { filter },
    };
  }

  public static getWorkIntervalsSuccess(data: IGetWorkIntervalsSuccessOptions): IGetWorkIntervalsSuccessAction {
    return {
      type: CleaningActions.GET_WORK_INTERVALS_SUCCESS,
      payload: data,
    };
  }

  public static getWorkIntervalsError({ error }: IActionCreatorErrorOptions): IGetWorkIntervalsErrorAction {
    return {
      type: CleaningActions.GET_WORK_INTERVALS_ERROR,
      error,
    };
  }

  public static workIntervalsIsLoading(): IWorkIntervalsIsLoadingAction {
    return {
      type: CleaningActions.WORK_INTERVALS_IS_LOADING,
    };
  }

  public static createWorkIntervalSuccess(data: ICreateWorkIntervalSuccessOptions): ICreateWorkIntervalSuccessAction {
    return {
      type: CleaningActions.CREATE_WORK_INTERVAL_SUCCESS,
      payload: data,
    };
  }

  public static createWorkIntervalError({ error }: IActionCreatorErrorOptions): ICreateWorkIntervalErrorAction {
    return {
      type: CleaningActions.CREATE_WORK_INTERVAL_ERROR,
      error,
    };
  }

  public static deleteWorkIntervalRequest({
    id,
    siteId,
  }: IDeleteWorkIntervalRequestOptions): IDeleteWorkIntervalRequestAction {
    return {
      type: CleaningActions.DELETE_WORK_INTERVAL_REQUEST,
      payload: {
        id,
        siteId,
      },
    };
  }

  public static deleteWorkIntervalSuccess(data: IDeleteWorkIntervalSuccessOptions): IDeleteWorkIntervalSuccessAction {
    return {
      type: CleaningActions.DELETE_WORK_INTERVAL_SUCCESS,
      payload: data,
    };
  }

  public static deleteWorkIntervalError({ error }: IActionCreatorErrorOptions): IDeleteWorkIntervalErrorAction {
    return {
      type: CleaningActions.DELETE_WORK_INTERVAL_ERROR,
      error,
    };
  }

  public static updateWorkIntervalRequest({
    id,
    siteId,
    input,
  }: IUpdateWorkIntervalRequestOptions): IUpdateWorkIntervalRequestAction {
    return {
      type: CleaningActions.UPDATE_WORK_INTERVAL_REQUEST,
      payload: {
        id,
        siteId,
        input,
      },
    };
  }

  public static updateWorkIntervalSuccess(data: IUpdateWorkIntervalSuccessOptions): IUpdateWorkIntervalSuccessAction {
    return {
      type: CleaningActions.UPDATE_WORK_INTERVAL_SUCCESS,
      payload: data,
    };
  }

  public static updateWorkIntervalError({ error }: IActionCreatorErrorOptions): IUpdateWorkIntervalErrorAction {
    return {
      type: CleaningActions.UPDATE_WORK_INTERVAL_ERROR,
      error,
    };
  }

  public static pollWorkIntervalsRequest({
    siteId,
    workIntervalId,
    operation,
  }: IPollWorkIntervalsRequestOptions): IPollWorkIntervalsRequestAction {
    return {
      type: CleaningActions.POLL_WORK_INTERVALS_REQUEST,
      payload: {
        siteId,
        workIntervalId,
        operation,
      },
    };
  }
}
