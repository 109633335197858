import styled from 'styled-components';
import { Card } from 'lib/components/Card/Card';

export const StyledMachineOperatingHoursDashboardChart = styled(Card)`
  height: 450px;

  .machine-operating-hours-dashboard-chart__title-bar {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.captionSmall};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  }

  .machine-operating-hours-dashboard-chart__title {
    cursor: pointer;
  }

  .machine-operating-hours-dashboard-chart__elements {
    min-height: 317px;
  }

  .machine-operating-hours-dashboard-chart__element {
    margin-top: 12.5px;
    margin-bottom: 12.5px;
  }

  .machine-operating-hours-dashboard-chart__sort-order-button {
    display: inline-block;
    margin: 0 4px;
    user-select: none;
  }

  .machine-operating-hours-dashboard-chart__info-icon {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .machine-operating-hours-dashboard-chart__pagination-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    user-select: none;
  }

  .machine-operating-hours-dashboard-chart__pagination-button--disabled {
    color: ${(props): string => props.theme.colors.brightGrey};
  }

  .machine-operating-hours-dashboard-chart__next-button-icon {
    transform: rotate(180deg);
  }
`;
