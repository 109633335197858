import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledRobotDocking } from './RobotDocking.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export const RobotDocking = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledRobotDocking className="robot-docking">
      <div className="robot-docking__wrapper">
        <div className="robot-docking__icon">
          <SvgIcon name="house" />
        </div>
      </div>
      <span className="robot-docking__label">{t('robotDashboard.Docking')}</span>
    </StyledRobotDocking>
  );
};
