import styled from 'styled-components';

export const StyledRobotTotalCleanedHourChartBar = styled.div<{
  valueBarPercentage: number;
}>`
  height: 8px;

  .robot-total-cleaned-hour-chart-bar__bar {
    height: 100%;
    border-radius: ${(props): string => props.theme.borderRadiuses.chartBar};
  }

  .robot-total-cleaned-hour-chart-bar__background-bar {
    width: 100%;
    background-color: ${(props): string => props.theme.colors.brightGrey};
  }

  .robot-total-cleaned-hour-chart-bar__value-bar {
    width: ${(props): string => Number(props.valueBarPercentage).toString()}%;
    will-change: width;
    transition: all 0.3s;
    background-color: ${(props): string => props.theme.colors.celadonGreen};
  }
`;
