import styled from 'styled-components';

const maxColHeight = 350;
export const StyledFleetOperatingHoursChart = styled.div`
  height: 450px;
  border: 1px solid ${(props): string => props.theme.colors.brightGrey};
  padding: 20px;

  .fleet-chart__title {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.captionSmall};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  }

  .fleet-chart__info-icon {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .fleet-chart__wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
  }

  .fleet-chart__actual {
    margin: 0 32px 0 0;

    .fleet-chart__col-value {
      display: flex;
      justify-content: flex-end;
    }

    .fleet-chart__col-title {
      align-self: end;
    }
  }

  .fleet-chart__planned {
    .fleet-chart__col-value {
      display: flex;
      justify-content: flex-start;
    }
  }

  .fleet-chart__actual-col {
    width: 80px;
    height: 100%;
    background-color: ${(props): string => props.theme.colors.celadonGreen};
  }

  .fleet-chart__planned-col {
    width: 80px;
    height: 100%;
    background-color: ${(props): string => props.theme.colors.lightCeladonGreen};
  }

  .fleet-chart__col {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: ${maxColHeight}px;
  }

  .fleet-chart__blank-col {
    width: 80px;
    height: 100%;
    margin: 0 8px;
    border-top: 1px solid ${(props): string => props.theme.colors.black};
  }

  .fleet-chart__col-value {
    font-family: ${(props): string => props.theme.fonts.heading1};
    font-size: ${(props): string => props.theme.fontSizes.heading1};
    line-height: ${(props): string => props.theme.lineHeights.heading1};
    font-weight: ${(props): number => props.theme.fontWeights.heading1};
    margin-bottom: 0;
  }

  .fleet-chart__actual,
  .fleet-chart__planned {
    display: flex;
    flex-direction: column;

    // animation
    opacity: 1;
    transition: opacity ease-in-out 0.25s;

    .fleet-chart__col-title {
      font-family: ${(props): string => props.theme.fonts.captionNews};
      font-size: ${(props): string => props.theme.fontSizes.caption};
      line-height: ${(props): string => props.theme.lineHeights.caption};
      font-weight: ${(props): number => props.theme.fontWeights.captionNews};
      text-align: center;
      margin-bottom: 0;
      margin-top: 10px;
      width: 50%;
    }
  }

  .fleet-chart__wrap-cols {
    display: flex;
    align-items: flex-end;
    min-height: 1%;
  }
`;
