import { i18n as i18next } from 'i18next';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import { CleaningTaskReport } from '../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';

export const getCleaningReportListExactColumns = ({
  t,
  i18n,
  handleClickMachineName,
  handleClickSiteName,
}: {
  t: TFunction;
  i18n: i18next;
  handleClickMachineName: (ctr: CleaningTaskReport) => void;
  handleClickSiteName: (ctr: CleaningTaskReport) => void;
}): ColumnsType<CleaningTaskReport> => [
  {
    title: t('cleaningReportList.exact.table.columns.date'),
    dataIndex: 'startedAt',
    width: 185,
    key: 'startedAt',
    render: (startedAt): JSX.Element => (
      <>
        {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.DATE)}
        {', '}
        {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.WEEKDAY)}
      </>
    ),
    sorter: true,
    defaultSortOrder: 'descend',
    fixed: 'left',
  },
  {
    title: t('cleaningReportList.exact.table.columns.machineName'),
    dataIndex: ['machine', 'name'],
    width: 180,
    key: 'machineName',
    render: (machineName: string, cleaningTaskReport): JSX.Element => (
      <div className="table__link--no-styling" onClick={(): void => handleClickMachineName(cleaningTaskReport)}>
        {machineName}
      </div>
    ),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('cleaningReportList.exact.table.columns.inventoryNo'),
    dataIndex: ['machine', 'metadata', 'inventoryNumber'],
    width: 134,
    key: 'machine.metadata.inventoryNumber',
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('cleaningReportList.exact.table.columns.serialNo'),
    dataIndex: 'serialNumber',
    width: 105,
    key: 'serialNumber',
    sorter: true,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('cleaningReportList.exact.table.columns.materialNo'),
    dataIndex: 'materialNumber',
    width: 123,
    key: 'materialNumber',
    sorter: true,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('cleaningReportList.exact.table.columns.assignedSite'),
    dataIndex: ['machine', 'site', 'name'],
    width: 258,
    key: 'site',
    render: (_siteName: string, cleaningTaskReport): JSX.Element => {
      if (!cleaningTaskReport.machine?.site?.id) {
        return <>{t('cleaningReportList.unassignedLabel')}</>;
      }

      return (
        <div className="table__link--no-styling" onClick={(): void => handleClickSiteName(cleaningTaskReport)}>
          {cleaningTaskReport.machine.site?.name}
        </div>
      );
    },
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: (): ReactNode => <Flex>{t('cleaningReportList.exact.table.columns.startTime')}</Flex>,
    dataIndex: 'startedAt',
    width: 101,
    key: 'startTime',
    render: startedAt =>
      DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND),
    ellipsis: {
      showTitle: true,
    },
    align: 'right',
  },
  {
    title: (): ReactNode => <Flex>{t('cleaningReportList.exact.table.columns.stopTime')}</Flex>,
    dataIndex: 'finishedAt',
    width: 101,
    key: 'endTime',
    render: finishedAt =>
      DateTime.formatDateByLocale(i18n.language, finishedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND),
    align: 'right',
  },
  {
    title: (): ReactNode => <Flex>{t('cleaningReportList.exact.table.columns.duration')}</Flex>,
    dataIndex: ['actualOperatingTimeInSeconds'],
    width: 101,
    render: actualOperatingTimeInSeconds =>
      DateTime.formatDurationByMilliseconds({ ms: actualOperatingTimeInSeconds * 1000 }),
    key: 'actualTotalOperatingHrs',
    ellipsis: {
      showTitle: true,
    },
    align: 'right',
  },
];
