import styled from 'styled-components';

export const StyledRobotListGroupedByStatus = styled.div`
  .robot-list-grouped-by-status__empty-container,
  .robot-list-grouped-by-status__load-indicator {
    margin: auto;
    margin-bottom: 24px;
    margin-top: 24px;
    min-height: 316px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${(props): string => props.theme.colors.white};
  }

  .robot-list-grouped-by-status__switcher {
    margin-top: 10px;
    margin-bottom: 8px;
    background-color: ${(props): string => props.theme.colors.grey200};
    border-color: ${(props): string => props.theme.colors.grey200};

    &.ant-switch-checked {
      border-color: ${(props): string => props.theme.colors.black};
    }

    :hover {
      background-color: ${(props): string => props.theme.colors.grey200};
    }

    .ant-switch-handle {
      ::before {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }
  }
`;
