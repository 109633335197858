import styled from 'styled-components';

export const StyledRobotCleaningStatusResult = styled.div`
  &.robot-cleaning-status-result {
    width: inherit;
    height: 284px;
    background-color: ${(props): string => props.theme.colors.white};
  }

  .robot-cleaning-status-result__header {
    width: auto;
    margin: 24px 24px 14px 24px;
  }

  .robot-cleaning-status-result__title {
    ${(props): string => props.theme.fontStyles.caption.medium};
    text-transform: uppercase;
  }

  .robot-cleaning-status-result__info-icon {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
