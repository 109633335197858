import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { gte, isNil, isEmpty } from 'lodash-es';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from '../../../utils/OperatingHoursChartUtils';
import { RobotUtils } from '../../../../../utils/robot/RobotUtils';
import {
  RobotDashboardListRobotsWithTotalCleanedAreaRequestAction,
  RobotDashboardListRobotsWithTotalCleanedAreaSuccessAction,
  RobotDashboardListRobotsWithTotalCleanedAreaErrorAction,
  RobotDashboardListRobotsWithTotalCleanedHourRequestAction,
  RobotDashboardListRobotsWithTotalCleanedHourSuccessAction,
  RobotDashboardListRobotsWithTotalCleanedHourErrorAction,
  RobotDashboardKPIsRequestAction,
  RobotDashboardKPIsSuccessAction,
  RobotDashboardKPIsErrorAction,
  RobotDashboardGetTasksCompletionRequestAction,
  RobotDashboardGetTasksCompletionSuccessAction,
  RobotDashboardGetTasksCompletionErrorAction,
  RobotDashboardListSitesSuccessAction,
  RobotDashboardListSitesErrorAction,
  RobotDashboardActiveStatusAction,
  RobotDashboardActiveGroupByAction,
  RobotDashboardFilterActiveSitesAction,
  RobotDashboardFilterActivePeriodAction,
  RobotListErrorAction,
  RobotListPicturesSuccessAction,
  RobotListRequestAction,
  RobotListSuccessAction,
  RobotListGroupedBySiteListSitesRequestAction,
  RobotListGroupedBySiteListSitesSuccessAction,
  RobotListGroupedBySiteListSitesWithRobotPicturesSuccessAction,
  RobotListGroupedBySiteListSitesWithRobotSuccessAction,
  RobotListGroupedBySiteListSitesErrorAction,
  RobotUnassignedListErrorAction,
  RobotUnassignedListPicturesSuccessAction,
  RobotUnassignedListRequestAction,
  RobotUnassignedListSuccessAction,
  RobotDashboardListSitesRequestAction,
  RobotDashboardRealTimeDataChangedAction,
  RobotDashboardRealTimeSiteDataChangedAction,
  RobotDashboardFilterActiveRobotIdsAction,
  RobotDashboardByStatusRealTimePropertyChangedAction,
  RobotListLatestCtrsSuccessAction,
  RobotListTelemetriesSuccessAction,
  RobotDashboardFilterActiveHidingOfflineRobotsAction,
  RobotListLatestRoutinesSuccessAction,
} from './RobotDashboardActions.types';
import {
  RobotDashboardKpIsData,
  RobotDashboardTotalCleanedAreaData,
  RobotDashboardTotalCleanedHrsData,
  RobotTaskCompletionStatisticData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { DEFAULT_PAGE_VALUE, DEFAULT_PAGE_SIZE_VALUE, ROBOT_LIST_GROUP_BY } from 'config/constants';
import { Optional } from 'lib/types/Optional';
import { RobotAvailableFilters } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachineUtils } from 'app/modules/machine-inventory/utils/MachineUtils';
import { SiteUtils } from 'app/modules/site-management/utils/SiteUtils';
import { SiteData } from 'app/modules/site-management/interfaces/Site.types';
import { SubscriptionMachineEvent } from 'app/modules/machine-inventory/interfaces/MachineSubscription.types';

interface RobotsWithTotalCleanedArea {
  isLoading: Optional<boolean>;
  data: RobotDashboardTotalCleanedAreaData[];
  metadata: {
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    totalCount: Optional<number>;
  };
}

interface RobotsWithTotalCleanedHour {
  isLoading: Optional<boolean>;
  data: RobotDashboardTotalCleanedHrsData[];
  metadata: {
    paginationTokens: PaginationTokens;
    page: number;
    pageSize: number;
    totalCount: Optional<number>;
  };
}

interface RobotDashboardFilter {
  isLoading: boolean;
  available: RobotAvailableFilters;
  active: {
    sites: string[];
    status: string[];
    groupBy: Optional<string>;
    period: {
      startDate: string;
      endDate: string;
    };
    robotIds: string[];
    isHidingOfflineRobots: boolean;
  };
}

interface RobotDashboardKPIs {
  isLoading: Optional<boolean>;
  data: Optional<RobotDashboardKpIsData>;
}

interface RobotDashboardTasksCompletion {
  isLoading: Optional<boolean>;
  data: Optional<RobotTaskCompletionStatisticData>;
}

interface RobotList {
  data: Optional<Machine[]>;
  isLoading: Optional<boolean>;
  arePicturesLoading: Optional<boolean>;
  areTelemetriesLoading: Optional<boolean>;
  areLatestCtrLoading: Optional<boolean>;
  areLatestRoutineLoading: Optional<boolean>;
}

type RobotUnassignedList = RobotList;

interface RobotListGroupedBySite {
  data: SiteData[];
  robotIds: string[];
  isLoading: Optional<boolean>;
  isCleaningStatisticLoading: Optional<boolean>;
  areMachinesLoading: Optional<boolean>;
  areMachinesPicturesLoading: Optional<boolean>;
  areTelemetriesLoading: Optional<boolean>;
  areLatestCtrLoading: Optional<boolean>;
  areLatestRoutineLoading: Optional<boolean>;
}

export interface RobotDashboardState {
  robotsWithTotalCleanedArea: RobotsWithTotalCleanedArea;
  robotsWithTotalCleanedHour: RobotsWithTotalCleanedHour;
  robotDashboardKPIs: RobotDashboardKPIs;
  robotsTasksCompletion: RobotDashboardTasksCompletion;
  robotDashboardFilter: RobotDashboardFilter;
  robotList: RobotList;
  robotUnassignedList: RobotUnassignedList;
  robotListGroupedBySite: RobotListGroupedBySite;
  unassignedRobotKPIs: RobotDashboardKPIs;
  isRedirected: boolean;
  robotRealTimeUpdatedEvent: Optional<SubscriptionMachineEvent>;
  robotRealTimeRobotId: Optional<string>;
  isComponentFadedOut: Optional<boolean>;
}

export const initialState: RobotDashboardState = {
  robotsWithTotalCleanedArea: {
    isLoading: null,
    data: [],
    metadata: {
      paginationTokens: {},
      page: DEFAULT_PAGE_VALUE,
      pageSize: DEFAULT_PAGE_SIZE_VALUE,
      totalCount: null,
    },
  },
  robotsWithTotalCleanedHour: {
    isLoading: null,
    data: [],
    metadata: {
      paginationTokens: {},
      page: DEFAULT_PAGE_VALUE,
      pageSize: DEFAULT_PAGE_SIZE_VALUE,
      totalCount: null,
    },
  },
  robotDashboardKPIs: {
    isLoading: null,
    data: null,
  },
  robotsTasksCompletion: {
    isLoading: null,
    data: null,
  },
  robotDashboardFilter: {
    available: {
      sites: [],
    },
    active: {
      status: [],
      groupBy: ROBOT_LIST_GROUP_BY.status,
      sites: [],
      period: {
        startDate: OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
        endDate: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
      },
      robotIds: [''],
      isHidingOfflineRobots: false,
    },
    isLoading: false,
  },
  robotList: {
    data: null,
    isLoading: null,
    arePicturesLoading: null,
    areTelemetriesLoading: null,
    areLatestCtrLoading: null,
    areLatestRoutineLoading: null,
  },
  robotUnassignedList: {
    data: null,
    isLoading: null,
    arePicturesLoading: null,
    areTelemetriesLoading: null,
    areLatestCtrLoading: null,
    areLatestRoutineLoading: null,
  },
  robotListGroupedBySite: {
    data: [],
    robotIds: [],
    isLoading: null,
    isCleaningStatisticLoading: null,
    areMachinesLoading: null,
    areMachinesPicturesLoading: null,
    areTelemetriesLoading: null,
    areLatestCtrLoading: null,
    areLatestRoutineLoading: null,
  },
  unassignedRobotKPIs: {
    isLoading: null,
    data: null,
  },
  isRedirected: false,
  robotRealTimeUpdatedEvent: null,
  robotRealTimeRobotId: null,
  isComponentFadedOut: null,
};

const robotDashboardSlice = createSlice({
  name: 'robot-dashboard',
  initialState,
  reducers: {
    // ListRobotsWithTotalCleanedArea
    robotDashboardListRobotsWithTotalCleanedAreaRequest: (
      state,
      _action: RobotDashboardListRobotsWithTotalCleanedAreaRequestAction
    ) => {
      state.robotsWithTotalCleanedArea.isLoading = true;
      return state;
    },
    robotDashboardListRobotsWithTotalCleanedAreaSuccess: (
      state,
      action: RobotDashboardListRobotsWithTotalCleanedAreaSuccessAction
    ) => {
      const {
        robotDashboardTotalCleanedArea: { data, metadata },
      } = action.payload;

      state.robotsWithTotalCleanedArea.isLoading = false;
      state.robotsWithTotalCleanedArea.data = data || [];

      state.robotsWithTotalCleanedArea.metadata.totalCount = metadata?.totalCount;

      if (metadata?.paginationToken) {
        state.robotsWithTotalCleanedArea.metadata.paginationTokens[state.robotsWithTotalCleanedArea.metadata.page + 1] =
          metadata?.paginationToken;
      }

      return state;
    },
    robotDashboardListRobotsWithTotalCleanedAreaError: (
      state,
      _action: RobotDashboardListRobotsWithTotalCleanedAreaErrorAction
    ) => {
      state.robotsWithTotalCleanedArea.isLoading = false;
      return state;
    },

    // ListRobotsWithTotalCleanedHour
    robotDashboardTotalCleanedHourRequest: (
      state,
      _action: RobotDashboardListRobotsWithTotalCleanedHourRequestAction
    ) => {
      state.robotsWithTotalCleanedHour.isLoading = true;
      return state;
    },
    robotDashboardTotalCleanedHourSuccess: (
      state,
      action: RobotDashboardListRobotsWithTotalCleanedHourSuccessAction
    ) => {
      const {
        robotDashboardTotalCleanedHrs: { data, metadata },
      } = action.payload;

      state.robotsWithTotalCleanedHour.isLoading = false;
      state.robotsWithTotalCleanedHour.data = data || [];

      state.robotsWithTotalCleanedHour.metadata.totalCount = metadata?.totalCount;

      if (metadata?.paginationToken) {
        state.robotsWithTotalCleanedHour.metadata.paginationTokens[state.robotsWithTotalCleanedHour.metadata.page + 1] =
          metadata?.paginationToken;
      }

      return state;
    },
    robotDashboardTotalCleanedHourError: (state, _action: RobotDashboardListRobotsWithTotalCleanedHourErrorAction) => {
      state.robotsWithTotalCleanedHour.isLoading = false;
      return state;
    },

    // GetTasksCompletion
    robotDashboardGetTasksCompletionRequest: (state, _action: RobotDashboardGetTasksCompletionRequestAction) => {
      state.robotsTasksCompletion.isLoading = true;
      return state;
    },
    robotDashboardGetTasksCompletionSuccess: (state, action: RobotDashboardGetTasksCompletionSuccessAction) => {
      const {
        robotTaskCompletionStatistic: { data },
      } = action.payload;

      state.robotsTasksCompletion.isLoading = false;
      state.robotsTasksCompletion.data = data || {};

      return state;
    },
    robotDashboardGetTasksCompletionError: (state, _action: RobotDashboardGetTasksCompletionErrorAction) => {
      state.robotsTasksCompletion.isLoading = false;
      return state;
    },
    robotDashboardGetTasksCompletionResetState: state => {
      state.robotsTasksCompletion = initialState.robotsTasksCompletion;
      return state;
    },

    // total cleaned area
    robotDashboardCleanedAreaResetState: state => {
      state.robotsWithTotalCleanedArea = initialState.robotsWithTotalCleanedArea;
      return state;
    },
    robotDashboardCleanedHourResetState: state => {
      state.robotsWithTotalCleanedHour = initialState.robotsWithTotalCleanedHour;
      return state;
    },

    // RobotSiteList
    robotDashboardGetSitesListRequest: (state, _action: RobotDashboardListSitesRequestAction) => {
      state.robotDashboardFilter.isLoading = true;
    },
    robotDashboardGetSitesListSuccess: (state, action: RobotDashboardListSitesSuccessAction) => {
      const {
        sites: { data },
      } = action.payload;
      state.robotDashboardFilter.isLoading = false;
      state.robotDashboardFilter.available.sites = data;
    },
    robotDashboardGetSitesListError: (state, _action: RobotDashboardListSitesErrorAction) => {
      state.robotDashboardFilter.isLoading = false;
    },

    robotDashboardFilterResetState: state => {
      state.robotDashboardFilter.active = {
        ...initialState.robotDashboardFilter.active,
        period: {
          startDate: OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
          endDate: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
        },
      };

      return state;
    },

    // RobotDashboardFilter
    robotDashboardFilterSetStatus: (state, action: RobotDashboardActiveStatusAction) => {
      state.robotDashboardFilter.active.status = action.payload;
      return state;
    },
    robotDashboardFilterSetGroupBy: (state, action: RobotDashboardActiveGroupByAction) => {
      state.robotDashboardFilter.active.groupBy = action.payload;
      return state;
    },
    robotDashboardFilterSetSites: (state, action: RobotDashboardFilterActiveSitesAction) => {
      state.robotDashboardFilter.active.sites = action.payload;
      return state;
    },
    robotDashboardFilterSetPeriod: (state, action: RobotDashboardFilterActivePeriodAction) => {
      state.robotDashboardFilter.active.period = action.payload;
    },
    robotDashboardSetActiveRobotIdsFilter: (state, action: RobotDashboardFilterActiveRobotIdsAction) => {
      state.robotDashboardFilter.active.robotIds = action.payload.robotIds;
      return state;
    },
    robotDashboardSetActiveHidingOfflineRobotsFilter: (
      state,
      action: RobotDashboardFilterActiveHidingOfflineRobotsAction
    ) => {
      state.robotDashboardFilter.active.isHidingOfflineRobots = action.payload.isHidingOfflineRobots;
      return state;
    },

    // RobotDashboardKPIs
    robotDashboardKPIsRequest: (state, _action: RobotDashboardKPIsRequestAction) => {
      state.robotDashboardKPIs.isLoading = true;
      return state;
    },
    robotDashboardKPIsSuccess: (state, action: RobotDashboardKPIsSuccessAction) => {
      state.robotDashboardKPIs.isLoading = false;
      state.robotDashboardKPIs.data = action.payload?.robotDashboardKPIs?.data || null;

      return state;
    },
    robotDashboardKPIsError: (state, _action: RobotDashboardKPIsErrorAction) => {
      state.robotDashboardKPIs.isLoading = false;
      return state;
    },
    robotDashboardKPIsResetState: state => {
      state.robotDashboardKPIs = initialState.robotDashboardKPIs;
      return state;
    },

    // RobotList
    robotListRequest: (state, _action: RobotListRequestAction) => {
      state.robotList.isLoading = true;
      state.robotList.arePicturesLoading = true;
      state.robotList.areTelemetriesLoading = true;
      state.robotList.areLatestCtrLoading = true;
      state.robotList.areLatestRoutineLoading = true;

      return state;
    },
    robotListSuccess: (state, action: RobotListSuccessAction) => {
      const {
        machines: { data },
      } = action.payload;

      state.robotList.isLoading = false;
      state.robotList.data = data;

      return state;
    },
    robotListPicturesSuccess: (state, action: RobotListPicturesSuccessAction) => {
      const {
        machines: { data: machineVariantData },
      } = action.payload;

      state.robotList.arePicturesLoading = false;

      state.robotList.data = state.robotList.data?.map(machineWithoutVariantData =>
        MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
      );

      return state;
    },
    robotListTelemetriesSuccess: (state, action: RobotListTelemetriesSuccessAction) => {
      const {
        machines: { data: machineTelemetryData },
      } = action.payload;

      state.robotList.areTelemetriesLoading = false;

      state.robotList.data = state.robotList.data?.map(machineWithoutTelemetryData =>
        MachineUtils.mergeMachineWithTelemetryData(machineWithoutTelemetryData, machineTelemetryData)
      );

      return state;
    },
    robotListLatesCtrsSuccess: (state, action: RobotListLatestCtrsSuccessAction) => {
      const {
        machines: { data: machineLatestCtrData },
      } = action.payload;

      state.robotList.areLatestCtrLoading = false;

      state.robotList.data = state.robotList.data?.map(machineWithoutLatestCtrData =>
        MachineUtils.mergeMachineWithLatestCtrData(machineWithoutLatestCtrData, machineLatestCtrData)
      );

      return state;
    },
    robotListLatesRoutinesSuccess: (state, action: RobotListLatestRoutinesSuccessAction) => {
      const {
        machines: { data: machineLatestRoutineData },
      } = action.payload;

      state.robotList.areLatestRoutineLoading = false;

      state.robotList.data = state.robotList.data?.map(machineWithoutLatestRoutineData =>
        MachineUtils.mergeMachineWithLatestRoutineData(machineWithoutLatestRoutineData, machineLatestRoutineData)
      );

      return state;
    },
    robotListError: (state, _action: RobotListErrorAction) => {
      state.robotList.isLoading = false;
      state.robotList.arePicturesLoading = false;
      state.robotList.areTelemetriesLoading = false;
      state.robotList.areLatestCtrLoading = false;
      state.robotList.areLatestRoutineLoading = false;

      return state;
    },
    robotListResetState: state => {
      state.robotList = initialState.robotList;

      return state;
    },

    robotUnassignedListRequest: (state, _action: RobotUnassignedListRequestAction) => {
      state.robotUnassignedList.isLoading = true;
      state.robotUnassignedList.arePicturesLoading = true;
      state.robotUnassignedList.areTelemetriesLoading = true;
      state.robotUnassignedList.areLatestCtrLoading = true;
      state.robotUnassignedList.areLatestRoutineLoading = true;

      return state;
    },
    robotUnassignedListSuccess: (state, action: RobotUnassignedListSuccessAction) => {
      const {
        machines: { data },
      } = action.payload;

      state.robotUnassignedList.isLoading = false;
      state.robotUnassignedList.data = data.filter(machine => machine.site === null) || [];

      return state;
    },
    robotUnassignedListPicturesSuccess: (state, action: RobotUnassignedListPicturesSuccessAction) => {
      const {
        machines: { data: machineVariantData },
      } = action.payload;

      state.robotUnassignedList.arePicturesLoading = false;

      state.robotUnassignedList.data = state.robotUnassignedList.data?.map(machineWithoutVariantData =>
        MachineUtils.mergeMachineWithVariantData(machineWithoutVariantData, machineVariantData)
      );

      return state;
    },
    robotUnassignedListTelemetriesSuccess: (state, action: RobotListTelemetriesSuccessAction) => {
      const {
        machines: { data: machineTelemetryData },
      } = action.payload;

      state.robotUnassignedList.areTelemetriesLoading = false;

      state.robotUnassignedList.data = state.robotUnassignedList.data?.map(machineWithoutTelemetryData =>
        MachineUtils.mergeMachineWithTelemetryData(machineWithoutTelemetryData, machineTelemetryData)
      );

      return state;
    },
    robotUnassignedListLatestCtrsSuccess: (state, action: RobotListLatestCtrsSuccessAction) => {
      const {
        machines: { data: machineLatestCtrData },
      } = action.payload;

      state.robotUnassignedList.areLatestCtrLoading = false;

      state.robotUnassignedList.data = state.robotUnassignedList.data?.map(machineWithoutLatestCtrData =>
        MachineUtils.mergeMachineWithLatestCtrData(machineWithoutLatestCtrData, machineLatestCtrData)
      );

      return state;
    },
    robotUnassignedListLatestRoutinesSuccess: (state, action: RobotListLatestRoutinesSuccessAction) => {
      const {
        machines: { data: machineLatestRoutineData },
      } = action.payload;

      state.robotUnassignedList.areLatestRoutineLoading = false;

      state.robotUnassignedList.data = state.robotUnassignedList.data?.map(machineWithoutLatestRoutineData =>
        MachineUtils.mergeMachineWithLatestRoutineData(machineWithoutLatestRoutineData, machineLatestRoutineData)
      );

      return state;
    },
    robotUnassignedListError: (state, _action: RobotUnassignedListErrorAction) => {
      state.robotUnassignedList.isLoading = false;
      state.robotUnassignedList.arePicturesLoading = false;
      state.robotUnassignedList.areTelemetriesLoading = false;
      state.robotUnassignedList.areLatestCtrLoading = false;
      state.robotUnassignedList.areLatestRoutineLoading = false;

      return state;
    },
    robotUnassignedListResetState: state => {
      state.robotUnassignedList = initialState.robotUnassignedList;

      return state;
    },

    // RobotListGroupedBySite
    robotListGroupedBySiteListSitesRequest: (state, _action: RobotListGroupedBySiteListSitesRequestAction) => {
      state.robotListGroupedBySite.isCleaningStatisticLoading = true;

      return state;
    },
    robotListGroupedBySiteListSitesMachinesRequest: (state, _action: RobotListGroupedBySiteListSitesRequestAction) => {
      state.robotListGroupedBySite.isLoading = true;
      state.robotListGroupedBySite.areMachinesLoading = true;
      state.robotListGroupedBySite.areMachinesPicturesLoading = true;
      state.robotListGroupedBySite.areTelemetriesLoading = true;
      state.robotListGroupedBySite.areLatestCtrLoading = true;
      state.robotListGroupedBySite.areLatestRoutineLoading = true;

      return state;
    },
    robotListGroupedBySiteListSitesSuccess: (state, action: RobotListGroupedBySiteListSitesSuccessAction) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.isLoading = false;
      state.robotListGroupedBySite.data = data;

      return state;
    },
    robotListGroupedBySiteListSitesWithCleaningStatisticSuccess: (
      state,
      action: RobotListGroupedBySiteListSitesSuccessAction
    ) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.isCleaningStatisticLoading = false;

      if (data.length > 0) {
        state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(siteWithoutCleaningStatisticData =>
          SiteUtils.mergeSiteWithCleaningStatisticData(siteWithoutCleaningStatisticData, data)
        );
      }

      return state;
    },
    robotListGroupedBySiteListSitesWithRobotSuccess: (
      state,
      action: RobotListGroupedBySiteListSitesWithRobotSuccessAction
    ) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.areMachinesLoading = false;

      if (data.length > 0) {
        state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(siteWithoutMachinesData =>
          SiteUtils.mergeSiteWithMachinesData(siteWithoutMachinesData, data)
        );
      }

      return state;
    },
    robotListGroupedBySiteListSitesWithRobotPicturesSuccess: (
      state,
      action: RobotListGroupedBySiteListSitesWithRobotPicturesSuccessAction
    ) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.areMachinesPicturesLoading = false;

      if (data.length > 0) {
        state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(siteWithMachinesWithoutVariantData =>
          SiteUtils.mergeSiteWithMachinesPicturesData(siteWithMachinesWithoutVariantData, data)
        );
        state.robotListGroupedBySite.robotIds = data.map(site => site.machines.data.map(machine => machine.id)).flat();
      }

      return state;
    },
    robotListGroupedBySiteListSitesWithRobotTelemetriesSuccess: (
      state,
      action: RobotListGroupedBySiteListSitesWithRobotSuccessAction
    ) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.areTelemetriesLoading = false;

      if (data.length > 0) {
        state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(siteWithoutMachinesTelemetriesData =>
          SiteUtils.mergeSiteWithMachinesTelemetriesData(siteWithoutMachinesTelemetriesData, data)
        );
      }

      return state;
    },
    robotListGroupedBySiteListSitesWithRobotLatestCtrSuccess: (
      state,
      action: RobotListGroupedBySiteListSitesWithRobotSuccessAction
    ) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.areLatestCtrLoading = false;

      if (data.length > 0) {
        state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(siteWithoutMachinesLatestCtrData =>
          SiteUtils.mergeSiteWithMachinesLatestCtrData(siteWithoutMachinesLatestCtrData, data)
        );
      }

      return state;
    },
    robotListGroupedBySiteListSitesWithRobotLatestRoutineSuccess: (
      state,
      action: RobotListGroupedBySiteListSitesWithRobotSuccessAction
    ) => {
      const {
        sites: { data },
      } = action.payload;

      state.robotListGroupedBySite.areLatestRoutineLoading = false;

      if (data.length > 0) {
        state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(
          siteWithoutMachinesLatestRoutineData =>
            SiteUtils.mergeSiteWithMachinesLatestRoutineData(siteWithoutMachinesLatestRoutineData, data)
        );
      }

      return state;
    },
    robotListGroupedBySiteListSitesError: (state, _action: RobotListGroupedBySiteListSitesErrorAction) => {
      state.robotListGroupedBySite.isLoading = false;
      state.robotListGroupedBySite.isCleaningStatisticLoading = false;
      state.robotListGroupedBySite.areMachinesLoading = false;
      state.robotListGroupedBySite.areMachinesPicturesLoading = false;
      state.robotListGroupedBySite.areTelemetriesLoading = false;
      state.robotListGroupedBySite.areLatestCtrLoading = false;
      state.robotListGroupedBySite.areLatestRoutineLoading = false;

      return state;
    },
    robotListGroupedBySiteListSitesResetState: state => {
      state.robotListGroupedBySite = initialState.robotListGroupedBySite;
      return state;
    },

    // RobotDashboardKPIs
    robotDashboardUnassignedRobotsKPIsRequest: (state, _action: RobotDashboardKPIsRequestAction) => {
      state.unassignedRobotKPIs.isLoading = true;
      return state;
    },
    robotDashboardUnassignedRobotsKPIsSuccess: (state, action: RobotDashboardKPIsSuccessAction) => {
      state.unassignedRobotKPIs.isLoading = false;
      state.unassignedRobotKPIs.data = action.payload?.robotDashboardKPIs?.data || null;

      return state;
    },
    robotDashboardUnassignedRobotsKPIsError: (state, _action: RobotDashboardKPIsErrorAction) => {
      state.unassignedRobotKPIs.isLoading = false;
      return state;
    },
    robotDashboardUnassignedRobotsKPIsResetState: state => {
      state.unassignedRobotKPIs = initialState.unassignedRobotKPIs;
      return state;
    },

    robotDashboardIsRedirectedActive: state => {
      state.isRedirected = true;
      return state;
    },
    robotDashboardIsNotRedirectedActive: state => {
      state.isRedirected = false;
      return state;
    },
    robotDashboardResetState: () => initialState,

    // REAL TIME BASED EVENT UPDATE DATA
    // PROPERTY CHANGED
    robotByStatusRealTimePropertyChanged: (state, action: RobotDashboardByStatusRealTimePropertyChangedAction) => {
      const { updatedData } = action.payload;

      const robotIndexInRobotList = RobotUtils.getRobotIndexInList(state.robotList.data, updatedData.machineId);
      if (robotIndexInRobotList !== -1 && state.robotList.data) {
        state.robotList.data[robotIndexInRobotList] = RobotUtils.getRobotPropertiesUpdated(
          state.robotList.data[robotIndexInRobotList],
          updatedData
        );

        return state;
      }

      const robotIndexInUnassignedRobotList = RobotUtils.getRobotIndexInList(
        state.robotUnassignedList.data,
        updatedData.machineId
      );
      if (robotIndexInUnassignedRobotList !== -1 && state.robotUnassignedList.data) {
        state.robotUnassignedList.data[robotIndexInUnassignedRobotList] = RobotUtils.getRobotPropertiesUpdated(
          state.robotUnassignedList.data[robotIndexInUnassignedRobotList],
          updatedData
        );

        return state;
      }

      const { siteIndex, robotIndex } = RobotUtils.getRobotAndSiteIndexInRobotBySiteList(
        state.robotListGroupedBySite.data,
        updatedData.machineId
      );
      if (siteIndex !== -1 && robotIndex !== -1 && state.robotListGroupedBySite.data) {
        const listRobotsInSite = state.robotListGroupedBySite.data[siteIndex].machines.data;
        state.robotListGroupedBySite.data[siteIndex].machines.data[robotIndex] = RobotUtils.getRobotPropertiesUpdated(
          listRobotsInSite[robotIndex],
          updatedData
        );

        return state;
      }

      return state;
    },

    // SITE CHANGED
    robotByStatusRealTimeSiteChanged: (state, action: RobotDashboardRealTimeDataChangedAction) => {
      const { machineUpdated } = action.payload;

      const machineUpdatedId = machineUpdated.machineId;
      const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data);
      const robotIndexInRobotList = RobotUtils.getRobotIndexInList(state.robotList.data, machineUpdatedId);

      if (
        !isNil(robotIndexInRobotList) &&
        gte(robotIndexInRobotList, 0) &&
        !isNil(state.robotList.data) &&
        !isEmpty(state.robotList.data)
      ) {
        state.robotList.data[robotIndexInRobotList] = RobotUtils.getRobotSiteChangedUpdated(
          machineUpdatedData,
          state.robotList.data[robotIndexInRobotList]
        );
        return state;
      }

      return state;
    },
    robotBySiteRealTimeUnassignedSiteChanged: (state, action: RobotDashboardRealTimeSiteDataChangedAction) => {
      const { machineUpdated, robotsSiteCleaningStatisitic } = action.payload;

      const machineUpdatedId = machineUpdated.machineId;
      const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data);
      const { siteIndex, robotIndex } = RobotUtils.getRobotAndSiteIndexInRobotBySiteList(
        state.robotListGroupedBySite.data,
        machineUpdatedId
      );
      state.robotRealTimeUpdatedEvent = machineUpdated?.event as SubscriptionMachineEvent;

      if (
        !isNil(state.robotListGroupedBySite.data) &&
        !isEmpty(state.robotListGroupedBySite.data) &&
        !isNil(siteIndex) &&
        gte(siteIndex, 0) &&
        !isNil(robotIndex) &&
        gte(robotIndex, 0) &&
        !isNil(machineUpdatedData)
      ) {
        const listRobotsInSite = state.robotListGroupedBySite.data[siteIndex].machines.data;
        listRobotsInSite[robotIndex] = RobotUtils.getRobotSiteChangedUpdated(
          machineUpdatedData,
          listRobotsInSite[robotIndex]
        );

        const unAssignedRobot = state.robotListGroupedBySite.data
          .map(site => site.machines.data)
          .flat()
          .find(robot => robot.id === machineUpdatedId);

        state.robotListGroupedBySite.data[siteIndex].machines.data = listRobotsInSite.filter(
          robot => robot.id !== machineUpdatedId
        );

        if (!isNil(unAssignedRobot) && !isNil(robotsSiteCleaningStatisitic)) {
          state.robotUnassignedList.data?.push(unAssignedRobot);
          state.robotListGroupedBySite.data[siteIndex].cleaningStatistic.data = robotsSiteCleaningStatisitic.data;
        }
        return state;
      }

      return state;
    },
    robotBySiteRealTimeAssignedSiteChanged: (state, action: RobotDashboardRealTimeSiteDataChangedAction) => {
      const { machineUpdated, robotsSiteCleaningStatisitic } = action.payload;

      const machineUpdatedId = machineUpdated.machineId;
      const machineUpdatedData = RobotUtils.getMachineUpdatedData(machineUpdated?.data);
      const robotIndexInRobotList = RobotUtils.getRobotIndexInList(state.robotUnassignedList.data, machineUpdatedId);
      state.robotRealTimeUpdatedEvent = machineUpdated?.event as SubscriptionMachineEvent;

      if (
        !isNil(robotIndexInRobotList) &&
        gte(robotIndexInRobotList, 0) &&
        !isNil(state.robotUnassignedList.data) &&
        !isEmpty(state.robotUnassignedList.data)
      ) {
        state.robotUnassignedList.data[robotIndexInRobotList] = RobotUtils.getRobotSiteChangedUpdated(
          machineUpdatedData,
          state.robotUnassignedList.data[robotIndexInRobotList]
        );

        const assignedRobot = state.robotUnassignedList.data?.find(robot => robot.id === machineUpdatedId);
        state.robotUnassignedList.data = state.robotUnassignedList.data?.filter(robot => robot.id !== machineUpdatedId);

        if (!isNil(assignedRobot)) {
          state.robotListGroupedBySite.data = state.robotListGroupedBySite.data?.map(site =>
            site.id === machineUpdatedData?.siteId
              ? {
                  ...site,
                  cleaningStatistic: {
                    data: robotsSiteCleaningStatisitic?.data,
                  },
                  machines: {
                    data: [...site.machines.data, assignedRobot],
                  },
                }
              : site
          );
        }

        return state;
      }

      return state;
    },

    // CLEANING DATA CHANGED
    robotBySiteRealTimeCleaningDataChangedInSite: (state, action: RobotDashboardRealTimeSiteDataChangedAction) => {
      const { machineUpdated, robotsSiteCleaningStatisitic } = action.payload;
      const machineUpdatedId = machineUpdated.machineId;
      const { siteIndex, robotIndex } = RobotUtils.getRobotAndSiteIndexInRobotBySiteList(
        state.robotListGroupedBySite.data,
        machineUpdatedId
      );

      if (
        !isNil(state.robotListGroupedBySite.data) &&
        !isEmpty(state.robotListGroupedBySite.data) &&
        !isNil(siteIndex) &&
        gte(siteIndex, 0) &&
        !isNil(robotIndex) &&
        gte(robotIndex, 0) &&
        !isNil(robotsSiteCleaningStatisitic)
      ) {
        state.robotListGroupedBySite.data[siteIndex].cleaningStatistic.data = robotsSiteCleaningStatisitic.data;

        return state;
      }

      return state;
    },
    robotListRobotIdsInSpecificSite: (state, action: RobotDashboardRealTimeDataChangedAction) => {
      const { machineUpdated } = action.payload;
      const machineUpdatedId = machineUpdated.machineId;

      const { siteIndex } = RobotUtils.getRobotAndSiteIndexInRobotBySiteList(
        state.robotListGroupedBySite.data,
        machineUpdatedId
      );
      state.robotListGroupedBySite.robotIds = state.robotListGroupedBySite.data[siteIndex]?.machines.data.map(
        machine => machine.id
      );
      return state;
    },

    robotDashboardRealTimeUpdateGetEvent: (state, action: RobotDashboardRealTimeDataChangedAction) => {
      state.robotRealTimeUpdatedEvent =
        (action.payload.machineUpdated?.event as SubscriptionMachineEvent) || initialState.robotRealTimeUpdatedEvent;
      return state;
    },

    robotDashboardIsComponentFadedOutActive: (state, action: RobotDashboardRealTimeDataChangedAction) => {
      state.robotRealTimeRobotId = action.payload.machineUpdated.machineId;
      state.isComponentFadedOut = true;
      return state;
    },

    robotDashboardIsComponentFadedOutDeactive: state => {
      state.robotRealTimeRobotId = initialState.robotRealTimeRobotId;
      state.isComponentFadedOut = initialState.isComponentFadedOut;
      return state;
    },

    robotDashboardRoutineRealTimeUpdate: (state, action: RobotDashboardRealTimeDataChangedAction) => {
      const { machineUpdated } = action.payload;

      const machineUpdatedId = machineUpdated.machineId;

      const robotIndexInRobotList = RobotUtils.getRobotIndexInList(state.robotList.data, machineUpdatedId);
      const robotInUnassignedRobotList = RobotUtils.getRobotIndexInList(
        state.robotUnassignedList.data,
        machineUpdatedId
      );
      const robotInRobotListGroupedBySite = RobotUtils.getRobotAndSiteIndexInRobotBySiteList(
        state.robotListGroupedBySite.data,
        machineUpdatedId
      );

      if (robotIndexInRobotList && state.robotList.data) {
        state.robotList.data[robotIndexInRobotList] = RobotUtils.getRobotRoutineUpdated(
          state.robotList.data[robotIndexInRobotList],
          machineUpdated.data
        );
      }

      if (robotInUnassignedRobotList && state.robotUnassignedList.data) {
        state.robotUnassignedList.data[robotInUnassignedRobotList] = RobotUtils.getRobotRoutineUpdated(
          state.robotUnassignedList.data[robotInUnassignedRobotList],
          machineUpdated.data
        );
      }

      if (robotInRobotListGroupedBySite.siteIndex !== -1 && robotInRobotListGroupedBySite.robotIndex !== -1) {
        const listRobotsInSite =
          state.robotListGroupedBySite.data[robotInRobotListGroupedBySite.siteIndex].machines.data;
        listRobotsInSite[robotInRobotListGroupedBySite.robotIndex] = RobotUtils.getRobotRoutineUpdated(
          listRobotsInSite[robotInRobotListGroupedBySite.robotIndex],
          machineUpdated.data
        );
      }

      return state;
    },
  },
});

// ACTIONS WITHOUT REDUCER CODE // START

export interface SubscribeToMachineUpdateActionPayload {
  customerId: string;
  machineId: string;
}
export type SubscribeToMachineUpdateAction = PayloadAction<SubscribeToMachineUpdateActionPayload>;
const subscribeToMachineUpdate = createAction<SubscribeToMachineUpdateActionPayload>(
  'robot-dashboard/subscribeToMachineUpdate'
);
export interface UnsubscribeFromMachineUpdateActionPayload {
  customerId: string;
  machineId: string;
}
export type UnsubscribeFromMachineUpdateAction = PayloadAction<UnsubscribeFromMachineUpdateActionPayload>;
const unsubscribeFromMachineUpdate = createAction<UnsubscribeFromMachineUpdateActionPayload>(
  'robot-dashboard/unsubscribeFromMachineUpdate'
);

// ACTIONS WITHOUT REDUCER CODE // END

export const RobotDashboardActions = {
  ...robotDashboardSlice.actions,
  subscribeToMachineUpdate,
  unsubscribeFromMachineUpdate,
};
export const robotDashboardReducer = robotDashboardSlice.reducer;
