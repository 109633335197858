import { useMemo } from 'react';
import { CleaningReportListAvailableFilters } from '../interfaces/CleaningReport.types';
import { Optional } from 'lib/types/Optional';
import { ALL_VALUE_SELECT_OPTION } from 'config/constants';
import { MachineClassification } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { MachineAvailableToFilter } from 'app/modules/machine-inventory/interfaces/Machine.types';

export function useGetMachineIdsFilter(
  activeMachinesFilter: Optional<string[]>,
  activeSiteFilter: Optional<string[]>,
  availableFilters: Optional<CleaningReportListAvailableFilters>,
  machineClassification: Optional<MachineClassification>
): string[] | undefined {
  const machineIdsFilter = useMemo(() => {
    // Selection machines
    const isClassificationMatched = (machine: MachineAvailableToFilter): boolean =>
      machineClassification === machine.classification;

    if (activeMachinesFilter?.filter(id => id !== ALL_VALUE_SELECT_OPTION).length) {
      return activeMachinesFilter;
    }

    // Site's machines
    if (activeSiteFilter) {
      const machineIds: string[] = [];
      const standAloneMachineIds: string[] = [];

      availableFilters?.machines?.forEach(machine => {
        if (isClassificationMatched(machine)) machineIds.push(machine.id);
      });

      availableFilters?.standAloneMachines?.forEach(machine => {
        if (isClassificationMatched(machine)) standAloneMachineIds.push(machine.id);
      });

      const machineIdsSet = new Set([...machineIds, ...standAloneMachineIds]);

      return Array.from(machineIdsSet);
    }

    // All machines
    return undefined;
  }, [
    activeMachinesFilter,
    activeSiteFilter,
    availableFilters?.machines,
    machineClassification,
    availableFilters?.standAloneMachines,
  ]);

  return machineIdsFilter;
}
