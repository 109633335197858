import has from 'lodash-es/has';
// Importing from lodash-es without destructuring to resolve TS type detection issue
import isArray from 'lodash-es/isArray';
import dayjs, { Dayjs } from 'dayjs';
import { DateTime } from '../../../../lib/utils/date-handling/DateTime';
import { TimezoneType, Weekday } from '../../../../lib/utils/date-handling/DateTime.types';
import { Machine } from '../../machine-inventory/interfaces/Machine.types';
import {
  ICleaningPlanEvent,
  InCreation,
  IWorkInterval,
  IWorkIntervalLocal,
  IWorkIntervalUtc,
} from '../interfaces/CleaningPlan.types';
import { RecurrenceInterval } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export class CleaningPlanUtils {
  public static isLocalWorkInterval = (
    workInterval: IWorkInterval | InCreation<IWorkInterval>
  ): workInterval is IWorkIntervalLocal | InCreation<IWorkIntervalLocal> => {
    if (
      has(workInterval, 'startHoursLocal') &&
      has(workInterval, 'startMinutesLocal') &&
      has(workInterval, 'durationMs') &&
      has(workInterval, 'createdAt') &&
      has(workInterval, 'weekdaysLocal') &&
      has(workInterval, 'recurring')
    ) {
      return true;
    }

    return false;
  };

  public static isUtcWorkInterval = (
    workInterval: IWorkInterval | InCreation<IWorkInterval>
  ): workInterval is IWorkIntervalUtc | InCreation<IWorkIntervalUtc> => {
    if (
      has(workInterval, 'startHoursUtc') &&
      has(workInterval, 'startMinutesUtc') &&
      has(workInterval, 'durationMs') &&
      has(workInterval, 'createdAt') &&
      has(workInterval, 'weekdaysUtc') &&
      has(workInterval, 'recurring')
    ) {
      return true;
    }

    return false;
  };

  public static isWorkIntervalInCreation = (
    workInterval: IWorkInterval | InCreation<IWorkInterval>
  ): workInterval is InCreation<IWorkInterval> => !has(workInterval, 'id');

  public static convertWorkIntervalToCleaningPlanEvents(
    workInterval: IWorkIntervalLocal,
    workIntervalType: TimezoneType.LOCAL
  ): ICleaningPlanEvent[];

  public static convertWorkIntervalToCleaningPlanEvents(
    workInterval: IWorkIntervalUtc,
    workIntervalType: TimezoneType.UTC
  ): ICleaningPlanEvent[];

  public static convertWorkIntervalToCleaningPlanEvents(
    workInterval: IWorkIntervalLocal | IWorkIntervalUtc,
    timezoneType: TimezoneType
  ): ICleaningPlanEvent[] {
    let startHours: number;
    let startMinutes: number;
    let weekdays: Weekday[];
    let startDate: Dayjs;

    if (timezoneType === TimezoneType.LOCAL) {
      if (!CleaningPlanUtils.isLocalWorkInterval(workInterval)) {
        throw new Error('Argument "workInterval" needs to be a work interval in local format');
      }

      if (workInterval.weekdaysLocal.length < 1) {
        throw new Error(
          'Property "weekdaysLocal" of provided work interval needs to be an array with at least one ISO weekday number'
        );
      }

      startHours = workInterval.startHoursLocal;
      startMinutes = workInterval.startMinutesLocal;
      weekdays = workInterval.weekdaysLocal;

      startDate = dayjs().local().hour(startHours).minute(startMinutes).second(0).millisecond(0);
    } else {
      // timezoneType === WorkIntervalType.UTC
      if (!CleaningPlanUtils.isUtcWorkInterval(workInterval)) {
        throw new Error('Argument "workInterval" needs to be a work interval in UTC format');
      }

      if (workInterval.weekdaysUtc.length < 1) {
        throw new Error(
          'Property "weekdaysUtc" of provided work interval needs to be an array with at least one ISO weekday number'
        );
      }

      startHours = workInterval.startHoursUtc;
      startMinutes = workInterval.startMinutesUtc;
      weekdays = workInterval.weekdaysUtc;

      startDate = dayjs().utc().hour(startHours).minute(startMinutes).second(0).millisecond(0);
    }

    const cleaningPlanEvents: ICleaningPlanEvent[] = weekdays.map((weekday: Weekday): ICleaningPlanEvent => {
      const startAt = DateTime.getDateByDayOfWeek({
        weekday,
        hours: startDate.hour(),
        minutes: startDate.minute(),
        timezoneType,
      }).toISOString();

      const endAt = dayjs(startAt).add(workInterval.durationMs, 'ms').toISOString();

      return {
        machine: workInterval.machine,
        workIntervalId: workInterval.id,
        startAt,
        endAt,
        durationMs: workInterval.durationMs,
      };
    });

    return cleaningPlanEvents;
  }

  public static createLocalWorkInterval(
    id: string,
    machine: Machine,
    timeSpanLocal: [Dayjs, Dayjs],
    weekdaysLocal: number[]
  ): IWorkIntervalLocal {
    const startTimeLocal = dayjs(timeSpanLocal[0]);
    const endTimeLocal = dayjs(timeSpanLocal[1]);

    const startHoursLocal = startTimeLocal.hour();
    const startMinutesLocal = startTimeLocal.minute();

    const endHoursLocal = endTimeLocal.hour();
    const endMinutesLocal = endTimeLocal.minute();

    const startDateTimeLocal = dayjs().hour(startHoursLocal).minute(startMinutesLocal).second(0).millisecond(0);
    const endDateTimeLocal = dayjs().hour(endHoursLocal).minute(endMinutesLocal).second(0).millisecond(0);

    // Comparing the later date to the earlier date results in a positive duration value
    const durationMs = endDateTimeLocal.diff(startDateTimeLocal);

    return {
      id,
      machine,
      startHoursLocal,
      startMinutesLocal,
      durationMs,
      createdAt: new Date().toISOString(),
      weekdaysLocal,
      recurring: RecurrenceInterval.EveryWeek,
    };
  }

  public static createLocalWorkIntervalInCreation(
    machineId: string,
    timeSpanLocal: [Dayjs, Dayjs],
    weekdaysLocal: number[]
  ): InCreation<IWorkIntervalLocal> {
    const startTimeLocal = dayjs(timeSpanLocal[0]);
    const endTimeLocal = dayjs(timeSpanLocal[1]);

    const startHoursLocal = startTimeLocal.hour();
    const startMinutesLocal = startTimeLocal.minute();

    const endHoursLocal = endTimeLocal.hour();
    const endMinutesLocal = endTimeLocal.minute();

    const startDateTimeLocal = dayjs().hour(startHoursLocal).minute(startMinutesLocal).second(0).millisecond(0);
    const endDateTimeLocal = dayjs().hour(endHoursLocal).minute(endMinutesLocal).second(0).millisecond(0);

    // Comparing the later date to the earlier date results in a positive duration value
    const durationMs = endDateTimeLocal.diff(startDateTimeLocal);

    return {
      machineId,
      startHoursLocal,
      startMinutesLocal,
      durationMs,
      createdAt: new Date().toISOString(),
      weekdaysLocal,
      recurring: RecurrenceInterval.EveryWeek,
    };
  }

  public static createUtcWorkInterval(
    id: string,
    machine: Machine,
    timeSpanLocal: [Dayjs, Dayjs],
    weekdaysLocal: number[]
  ): IWorkIntervalUtc {
    const startTimeLocal = dayjs(timeSpanLocal[0]);
    const endTimeLocal = dayjs(timeSpanLocal[1]);
    const startHoursLocal = startTimeLocal.hour();
    const startMinutesLocal = startTimeLocal.minute();

    const startTimeUtc = startTimeLocal.utc();
    const endTimeUtc = endTimeLocal.utc();
    const startHoursUtc = startTimeUtc.hour();
    const startMinutesUtc = startTimeUtc.minute();

    const endHoursUtc = endTimeUtc.hour();
    const endMinutesUtc = endTimeUtc.minute();

    const startDateTimeUtc = dayjs().utc().hour(startHoursUtc).minute(startMinutesUtc).second(0).millisecond(0);
    const endDateTimeUtc = dayjs().utc().hour(endHoursUtc).minute(endMinutesUtc).second(0).millisecond(0);

    // Comparing the later date to the earlier date results in a positive duration value
    const durationMs = endDateTimeUtc.diff(startDateTimeUtc);

    const weekdaysUtc = CleaningPlanUtils.convertLocalWeekdaysToUtc({
      weekdaysLocal,
      startHoursLocal,
      startMinutesLocal,
    });

    return {
      id,
      machine,
      startHoursUtc,
      startMinutesUtc,
      durationMs,
      createdAt: new Date().toISOString(),
      weekdaysUtc,
      recurring: RecurrenceInterval.EveryWeek,
    };
  }

  public static createUtcWorkIntervalInCreation(
    machineId: string,
    timeSpanLocal: [Dayjs, Dayjs],
    weekdaysLocal: number[]
  ): InCreation<IWorkIntervalUtc> {
    const startTimeLocal = dayjs(timeSpanLocal[0]);
    const endTimeLocal = dayjs(timeSpanLocal[1]);
    const startHoursLocal = startTimeLocal.hour();
    const startMinutesLocal = startTimeLocal.minute();

    const startTimeUtc = startTimeLocal.utc();
    const endTimeUtc = endTimeLocal.utc();
    const startHoursUtc = startTimeUtc.hour();
    const startMinutesUtc = startTimeUtc.minute();

    const endHoursUtc = endTimeUtc.hour();
    const endMinutesUtc = endTimeUtc.minute();

    const startDateTimeUtc = dayjs().utc().hour(startHoursUtc).minute(startMinutesUtc).second(0).millisecond(0);
    const endDateTimeUtc = dayjs().utc().hour(endHoursUtc).minute(endMinutesUtc).second(0).millisecond(0);

    // Comparing the later date to the earlier date results in a positive duration value
    const durationMs = endDateTimeUtc.diff(startDateTimeUtc);

    const weekdaysUtc = CleaningPlanUtils.convertLocalWeekdaysToUtc({
      weekdaysLocal,
      startHoursLocal,
      startMinutesLocal,
    });

    return {
      machineId,
      startHoursUtc,
      startMinutesUtc,
      durationMs,
      createdAt: new Date().toISOString(),
      weekdaysUtc,
      recurring: RecurrenceInterval.EveryWeek,
    };
  }

  public static convertLocalWorkIntervalToUtc(workIntervalLocal: IWorkIntervalLocal): IWorkIntervalUtc;

  public static convertLocalWorkIntervalToUtc(
    workIntervalLocal: InCreation<IWorkIntervalLocal>
  ): InCreation<IWorkIntervalUtc>;

  public static convertLocalWorkIntervalToUtc(
    workIntervalLocal: IWorkIntervalLocal | InCreation<IWorkIntervalLocal>
  ): IWorkIntervalUtc | InCreation<IWorkIntervalUtc> {
    if (!CleaningPlanUtils.isLocalWorkInterval(workIntervalLocal)) {
      throw new Error('Argument "workIntervalLocal" needs to be a work interval in local format');
    }

    const startDateTimeUtc = dayjs()
      .hour(workIntervalLocal.startHoursLocal)
      .minute(workIntervalLocal.startMinutesLocal)
      .second(0)
      .millisecond(0)
      .utc();

    const startHoursUtc = startDateTimeUtc.hour();
    const startMinutesUtc = startDateTimeUtc.minute();

    const weekdaysUtc = CleaningPlanUtils.convertLocalWeekdaysToUtc({
      weekdaysLocal: workIntervalLocal.weekdaysLocal,
      startHoursLocal: workIntervalLocal.startHoursLocal,
      startMinutesLocal: workIntervalLocal.startMinutesLocal,
    });

    if (CleaningPlanUtils.isWorkIntervalInCreation(workIntervalLocal)) {
      const workIntervalUtcInCreation: InCreation<IWorkIntervalUtc> = {
        machineId: workIntervalLocal.machineId,
        startHoursUtc,
        startMinutesUtc,
        durationMs: workIntervalLocal.durationMs,
        createdAt: dayjs(workIntervalLocal.createdAt).utc().toDate().toISOString(),
        weekdaysUtc,
        recurring: workIntervalLocal.recurring,
      };

      return workIntervalUtcInCreation;
    }

    const workIntervalUtc: IWorkIntervalUtc = {
      id: workIntervalLocal.id,
      machine: workIntervalLocal.machine,
      startHoursUtc,
      startMinutesUtc,
      durationMs: workIntervalLocal.durationMs,
      createdAt: dayjs(workIntervalLocal.createdAt).utc().toDate().toISOString(),
      weekdaysUtc,
      recurring: workIntervalLocal.recurring,
    };

    return workIntervalUtc;
  }

  public static convertUtcWorkIntervalToLocal(workIntervalUtc: IWorkIntervalUtc): IWorkIntervalLocal;

  public static convertUtcWorkIntervalToLocal(
    workIntervalUtc: InCreation<IWorkIntervalUtc>
  ): InCreation<IWorkIntervalLocal>;

  public static convertUtcWorkIntervalToLocal(
    workIntervalUtc: IWorkIntervalUtc | InCreation<IWorkIntervalUtc>
  ): IWorkIntervalLocal | InCreation<IWorkIntervalLocal> {
    if (!CleaningPlanUtils.isUtcWorkInterval(workIntervalUtc)) {
      throw new Error('Argument "workIntervalUtc" needs to be a work interval in UTC format');
    }

    const startDateTimeLocal = dayjs
      .utc()
      .hour(workIntervalUtc.startHoursUtc)
      .minute(workIntervalUtc.startMinutesUtc)
      .second(0)
      .millisecond(0)
      .local();

    const startHoursLocal = startDateTimeLocal.hour();
    const startMinutesLocal = startDateTimeLocal.minute();

    const weekdaysLocal = CleaningPlanUtils.convertUtcWeekdaysToLocal({
      weekdaysUtc: workIntervalUtc.weekdaysUtc,
      startHoursUtc: workIntervalUtc.startHoursUtc,
      startMinutesUtc: workIntervalUtc.startMinutesUtc,
    });

    if (CleaningPlanUtils.isWorkIntervalInCreation(workIntervalUtc)) {
      const workIntervalLocalInCreation: InCreation<IWorkIntervalLocal> = {
        machineId: workIntervalUtc.machineId,
        startHoursLocal,
        startMinutesLocal,
        durationMs: workIntervalUtc.durationMs,
        createdAt: dayjs(workIntervalUtc.createdAt).utc().toDate().toISOString(),
        weekdaysLocal,
        recurring: workIntervalUtc.recurring,
      };

      return workIntervalLocalInCreation;
    }

    const workIntervalLocal: IWorkIntervalLocal = {
      id: workIntervalUtc.id,
      machine: workIntervalUtc.machine,
      startHoursLocal,
      startMinutesLocal,
      durationMs: workIntervalUtc.durationMs,
      createdAt: dayjs(workIntervalUtc.createdAt).utc().toDate().toISOString(),
      weekdaysLocal,
      recurring: workIntervalUtc.recurring,
    };

    return workIntervalLocal;
  }

  public static convertUtcWeekdaysToLocal = ({
    weekdaysUtc,
    startHoursUtc,
    startMinutesUtc,
  }: {
    weekdaysUtc: Weekday[];
    startHoursUtc: number;
    startMinutesUtc: number;
  }): Weekday[] => {
    if (!isArray(weekdaysUtc)) throw new Error('Argument "weekdaysUtc" needs to be an array of integers');
    if (!Number.isInteger(startHoursUtc)) throw new Error('Argument "startHoursUtc" needs to be an integer');
    if (!Number.isInteger(startMinutesUtc)) throw new Error('Argument "startMinutesUtc" needs to be an integer');

    const weekdaysLocal = weekdaysUtc.map(
      (weekday: Weekday): Weekday =>
        dayjs
          .utc()
          .isoWeekday(weekday)
          .hour(startHoursUtc)
          .minute(startMinutesUtc)
          .second(0)
          .millisecond(0)
          .local()
          .isoWeekday()
    );

    return weekdaysLocal;
  };

  public static convertLocalWeekdaysToUtc = ({
    weekdaysLocal,
    startHoursLocal,
    startMinutesLocal,
  }: {
    weekdaysLocal: Weekday[];
    startHoursLocal: number;
    startMinutesLocal: number;
  }): Weekday[] => {
    if (!isArray(weekdaysLocal)) throw new Error('Argument "weekdaysLocal" needs to be an array of integers');
    if (!Number.isInteger(startHoursLocal)) throw new Error('Argument "startHoursLocal" needs to be an integer');
    if (!Number.isInteger(startMinutesLocal)) throw new Error('Argument "startMinutesLocal" needs to be an integer');

    const weekdaysDateTimesLocal = weekdaysLocal.map(
      (weekday: Weekday): Dayjs =>
        dayjs().local().isoWeekday(weekday).hour(startHoursLocal).minute(startMinutesLocal).second(0).millisecond(0)
    );

    const formattedWeekdaysDateTimeStringsUtc = weekdaysDateTimesLocal.map((date: Dayjs): string =>
      dayjs(date).utc().format()
    );

    // NOTE: call utc() again to isoWeekday get weekday in UTC mode
    const weekdaysUtc = formattedWeekdaysDateTimeStringsUtc.map((dateTimeString: string): number =>
      dayjs(dateTimeString).utc().isoWeekday()
    );

    return weekdaysUtc;
  };
}
