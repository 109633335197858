import React from 'react';
import { StatusPill, StatusPillProps } from '../../../../../lib/components/StatusPill/StatusPill';
import { StyledDeviationStatusPill } from './DeviationStatusPill.styles';

export interface DeviationStatusPillProps {
  deviationPercent: number;
  size?: StatusPillProps['size'];
}

export const DeviationStatusPill = ({
  deviationPercent,
  size = 'large',
}: DeviationStatusPillProps): React.JSX.Element => {
  const deviationString = [deviationPercent, '%'].join('');

  const getColor = (): string => {
    if (deviationPercent < -25 && deviationPercent >= -50) return 'yellow';
    if (deviationPercent < -50) return 'red';

    return 'green';
  };

  return (
    <StyledDeviationStatusPill className="deviation-status-pill">
      <StatusPill color={getColor()} size={size}>
        {deviationString}
      </StatusPill>
    </StyledDeviationStatusPill>
  );
};
