import styled from 'styled-components';

export const StyledRobotName = styled.div`
  margin-top: 7px;

  .robot-info__name {
    font-family: ${(props): string => props.theme.fonts.p1Medium};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
    line-height: ${(props): string => props.theme.lineHeights.p0};
    margin-bottom: 0px;
    text-transform: uppercase;
    width: auto;
    word-break: break-word;
    white-space: normal;
  }

  .robot-info__number {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    margin-bottom: 0px;
    width: auto;
    word-break: break-word;
    white-space: normal;
  }
`;
