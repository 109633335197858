import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import {
  selectAreSitesListLoading,
  selectRobotAssignSiteModalIsLoading,
  selectRobotAssignSiteModalRobotId,
  selectRobotAssignSiteModalVisible,
  selectSitesListData,
} from '../state/RobotModalsSelectors';
import { RobotModalsActions } from '../state/RobotModalsSlice';
import { StyledRobotAssignSiteModal } from './RobotAssignSiteModal.styles';
import { OpenSearch } from 'config/constants';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { LegacySelect } from 'lib/components/LegacySelect/LegacySelect';

export const RobotAssignSiteModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [robotAssignedSiteFormInstance] = Form.useForm();
  const [hasRobotId, setHasRobotId] = useState(true);

  const isVisible = useSelector(selectRobotAssignSiteModalVisible);
  const isLoading = useSelector(selectRobotAssignSiteModalIsLoading);

  const robotId = useSelector(selectRobotAssignSiteModalRobotId);

  const sites = useSelector(selectSitesListData);
  const areSitesLoading = useSelector(selectAreSitesListLoading);

  const onFinish = useCallback(
    (values: { siteId: string }) => {
      if (!robotId) {
        setHasRobotId(false);
        return;
      }

      dispatch(
        RobotModalsActions.robotAssignToSiteRequest({
          siteId: values.siteId,
          machineIds: [robotId],
        })
      );
    },
    [dispatch, robotId]
  );

  const handleAssign = useCallback(() => {
    robotAssignedSiteFormInstance.submit();
  }, [robotAssignedSiteFormInstance]);

  const handleCancel = useCallback(() => {
    dispatch(RobotModalsActions.hideRobotAssignSiteModal());
    robotAssignedSiteFormInstance.resetFields();
  }, [dispatch, robotAssignedSiteFormInstance]);

  useEffect(() => {
    dispatch(
      RobotModalsActions.sitesListRequest({
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => (): void => robotAssignedSiteFormInstance.resetFields(), [robotAssignedSiteFormInstance]);

  return (
    <StyledRobotAssignSiteModal
      className="robot-assign-site-modal"
      title={t('robotDashboard.modals.assignSite.title')}
      centered
      open={isVisible}
      closable={false}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleAssign}
          loading={isLoading}
        >
          {t('robotDashboard.modals.assignSite.button.assign')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={robotAssignedSiteFormInstance}
        name="robot-assigned-site"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="siteId"
              className="subscription-modal__input-group"
              label={t('robotDashboard.filter.site')}
              required
              rules={[
                {
                  required: true,
                  message: t('robotDashboard.modals.assignSite.errors.siteIsRequired'),
                },
              ]}
            >
              <LegacySelect
                loading={!!areSitesLoading}
                className="robot-assign-site__select"
                placeholder={t('robotDashboard.modals.assignSite.placeholderNull')}
                options={sites?.map(site => ({
                  label: site.name,
                  value: site.id,
                }))}
              />
            </Form.Item>
            {!hasRobotId && (
              <p className="robot-assign-site__error-message">
                {t('robotDashboard.modals.assignSite.errors.notHavingRobotId')}
              </p>
            )}
          </Col>
        </Row>
      </Form>
    </StyledRobotAssignSiteModal>
  );
};
