import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space, Spin } from 'antd';
import { DateTime } from '../../../../../../../lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from '../../../../../../../lib/utils/date-handling/DateTime.types';
import { CleaningTaskReport } from '../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { CleaningReportListActions } from '../../../state/cleaningReportListActions';
import * as cleaningReportListSelectors from '../../../state/cleaningReportListSelectors';
import { RobotRouteImagePreviewGlobalStyles } from './RobotRouteImagePreview.global.styles';
import { ROBOT_CTR_ROUTE_IMAGE_FALLBACK } from 'app/modules/machine-inventory/utils/constants';

interface RobotRouteImagePreviewProps {
  cleaningTaskReport: CleaningTaskReport;
  onVisibilityChange: (value: boolean) => void;
  visible: boolean;
}

export const RobotRouteImagePreview = ({
  cleaningTaskReport,
  onVisibilityChange,
  visible,
}: RobotRouteImagePreviewProps): JSX.Element => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const imageAsDataUrl = useSelector(cleaningReportListSelectors.selectRobotRouteImageAsDataUrl);
  const robotRouteImageLoading = useSelector(cleaningReportListSelectors.selectRobotRouteImageIsLoading);
  const cleaningRouteDate = DateTime.formatDateByLocale(
    i18n.language,
    cleaningTaskReport.finishedAt,
    DATE_FORMAT_PATTERN.DATE
  );
  const imageTitle = `${cleaningTaskReport.machine.name} - Cleaning Route ${cleaningRouteDate}`;

  useEffect(() => {
    if (visible) {
      dispatch(
        CleaningReportListActions.getCleaningReportRobotRouteImageRequest({
          machineId: cleaningTaskReport.machine.id,
          finishedAt: cleaningTaskReport.finishedAt,
        })
      );
    }

    return () => {
      dispatch(CleaningReportListActions.resetCleaningReportRobotRouteImage());
      if (imageAsDataUrl) URL.revokeObjectURL(imageAsDataUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleaningTaskReport.finishedAt, cleaningTaskReport.machine.id, dispatch, visible]);

  const handleDownload = useCallback(() => {
    if (imageAsDataUrl) {
      dispatch(
        CleaningReportListActions.downloadCleaningReportRobotRouteImage({
          imageAsDataUrl,
          imageTitle,
        })
      );
    }
  }, [dispatch, imageAsDataUrl, imageTitle]);

  return (
    <>
      <RobotRouteImagePreviewGlobalStyles />
      <Image
        width={200}
        className="cleaning-task-report-robot__preview-route-image"
        wrapperClassName="cleaning-task-report-robot__preview-route-image-wrapper"
        fallback={ROBOT_CTR_ROUTE_IMAGE_FALLBACK}
        preview={{
          visible,
          src: imageAsDataUrl || '',
          onVisibleChange: (value): void => onVisibilityChange(value),
          imageRender: originalNode => (
            <div className="cleaning-task-report-robot__preview-route-image-render">
              {robotRouteImageLoading ? (
                <div className="cleaning-task-report-robot__preview-route-image-loading-indicator">
                  <Spin size="default" />
                </div>
              ) : (
                <>
                  <div className="cleaning-task-report-robot__preview-route-image-title">{imageTitle}</div>
                  {originalNode}
                </>
              )}
            </div>
          ),
          toolbarRender: (
            _,
            { transform: { scale }, actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
          ) => (
            <Space size={12} className="toolbar-wrapper">
              {imageAsDataUrl && <DownloadOutlined onClick={handleDownload} />}
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      />
    </>
  );
};
