import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { MachineClientFleetOperatingTimeOptions } from 'app/modules/machine-inventory/MachineClient';
import { FleetOperatingTimeResponse } from 'app/modules/machine-inventory/interfaces/Machine.types';

export type IGetFleetOperatingTimeRequestOptions = MachineClientFleetOperatingTimeOptions;

export type IGetFleetOperatingTimeRequestAction = Action<
  typeof FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_REQUEST
> & {
  payload: MachineClientFleetOperatingTimeOptions;
};

export type IGetFleetOperatingTimeSuccessOptions = FleetOperatingTimeResponse;
export type IGetFleetOperatingTimeSuccessAction = Action<
  typeof FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_SUCCESS
> & {
  payload: FleetOperatingTimeResponse;
};

export type IGetFleetOperatingTimeErrorAction = IErrorAction<
  typeof FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_ERROR
>;

export class FleetOperatingTimeActions {
  public static GET_FLEET_OPERATING_TIME_REQUEST = 'FLEET_OPERATING_TIME::GET_FLEET_OPERATING_TIME_REQUEST';

  public static GET_FLEET_OPERATING_TIME_SUCCESS = 'FLEET_OPERATING_TIME::GET_FLEET_OPERATING_TIME_SUCCESS';

  public static GET_FLEET_OPERATING_TIME_ERROR = 'FLEET_OPERATING_TIME::GET_FLEET_OPERATING_TIME_ERROR';

  public static RESET_STATE = 'FLEET_OPERATING_TIME::RESET_STATE';

  public static getFleetOperatingTimeRequest({
    filter,
  }: IGetFleetOperatingTimeRequestOptions): IGetFleetOperatingTimeRequestAction {
    return {
      type: FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getFleetOperatingTimeSuccess(
    data: IGetFleetOperatingTimeSuccessOptions
  ): IGetFleetOperatingTimeSuccessAction {
    return {
      type: FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_SUCCESS,
      payload: data,
    };
  }

  public static getFleetOperatingTimeError({ error }: IActionCreatorErrorOptions): IGetFleetOperatingTimeErrorAction {
    return {
      type: FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_ERROR,
      error,
    };
  }

  public static resetState(): Action<typeof FleetOperatingTimeActions.RESET_STATE> {
    return {
      type: FleetOperatingTimeActions.RESET_STATE,
    };
  }
}
