import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { StyledRobotConnectionStatusIndicator } from './RobotConnectionStatusIndicator.styles';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { MachineConnectionStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

interface RobotConnectionStatusIndicatorProps {
  lastActivityAt: Optional<string>;
  connectionStatus: MachineConnectionStatus;
}

export const RobotConnectionStatusIndicator = ({
  lastActivityAt,
  connectionStatus,
}: RobotConnectionStatusIndicatorProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const formatLastConnectedDateTime = (dateFormatPattern: DATE_FORMAT_PATTERN): string =>
    DateTime.formatDateByLocale(i18n.language, lastActivityAt, dateFormatPattern);

  const className = classnames(
    (connectionStatus || MachineConnectionStatus.Offline).toLowerCase().concat('-status'),
    'robot-connection-status-indicator__indicator'
  );

  const getEffectiveConnectionStatus = (rawConnectionStatus: MachineConnectionStatus): string => {
    if (rawConnectionStatus === MachineConnectionStatus.Online) {
      return t('robotDashboard.online');
    }

    const formattedLastConnectedDate = formatLastConnectedDateTime(DATE_FORMAT_PATTERN.DATE);
    const formattedLastConnectedTime = formatLastConnectedDateTime(DATE_FORMAT_PATTERN.TIME_12_HOUR);

    if (!formattedLastConnectedDate) {
      return t('robotDashboard.offline');
    }

    return t('robotDashboard.offlineSince', {
      date: formattedLastConnectedDate,
      time: formattedLastConnectedTime,
    });
  };

  return (
    <StyledRobotConnectionStatusIndicator className="robot-connection-status-indicator__wrapper">
      <div className="robot-connection-status-indicator__indicator-container">
        <div className={className} />
      </div>
      <span className="robot-connection-status-indicator__text">{getEffectiveConnectionStatus(connectionStatus)}</span>
      <div className="robot-connection-status-indicator__text"></div>
    </StyledRobotConnectionStatusIndicator>
  );
};
