import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

interface WaterLevelIconProps {
  waterPercent: number;
}
export const RobotWaterLevelIcon = ({ waterPercent }: WaterLevelIconProps): JSX.Element => {
  switch (true) {
    case waterPercent === 100:
      return <SvgIcon name="water100Percent" />;
    case waterPercent >= 75:
      return <SvgIcon name="water75Percent" />;
    case waterPercent >= 50:
      return <SvgIcon name="water50Percent" />;
    case waterPercent >= 25:
      return <SvgIcon name="water25Percent" />;
    case waterPercent === 0:
      return <SvgIcon name="water00Percent" />;
    default:
      return <SvgIcon name="water00Percent" />;
  }
};
