/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import { singleton } from 'tsyringe';
import { MachineClient } from '../machine-inventory/MachineClient';
import { SiteService } from '../site-management/SiteService';
import {
  ILegacyMachineStatusItemData,
  LegacyMachineStatus,
  LegacyMachineStatusItemData,
  MachinesAvailableToFilter,
} from '../machine-inventory/interfaces/Machine.types';
import {
  CleaningReportListAvailableFilters,
  CleaningReportsExportRequest,
  CleaningReportsExportGetFile,
  CleaningReportsExportRobotDetailsRequest,
  ICleaningReportTaskCompletion,
} from './interfaces/CleaningReport.types';
import {
  CleaningReportClient,
  CleaningReportClientRequestExportCleaningReportsOptions,
  CleaningReportClientGetExportCleaningReportsFileOptions,
  CleaningReportClientRequestRobotDetailsExportOptions,
} from './CleaningReportClient';
import { OpenSearch } from 'config/constants';
import {
  InputFilterMachinesList,
  MachineClassification,
  SortOrders,
  TaskCompletion,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface CleaningReportServiceMachineListFilterOptions {
  filter: InputFilterMachinesList;
}

export type CleaningReportServiceRequestExportCleaningReportsOptions =
  CleaningReportClientRequestExportCleaningReportsOptions;

export type CleaningReportServiceGetExportCleaningReportsFileOptions =
  CleaningReportClientGetExportCleaningReportsFileOptions;

@singleton()
export class CleaningReportService {
  constructor(
    private machineClient: MachineClient,
    private siteService: SiteService,
    private cleaningReportClient: CleaningReportClient
  ) {}

  public machineListFilter = async ({
    filter,
  }: CleaningReportServiceMachineListFilterOptions): Promise<MachinesAvailableToFilter> => {
    const { data } = await this.machineClient.listAvailableToFilter({
      filter,
      paginationOptions: {
        limit: OpenSearch.MAX_RESULT_WINDOW,
      },
      sortOptions: {
        field: 'name',
        order: SortOrders.Asc,
      },
    });
    return data;
  };

  public availableFilters = async (classifications: MachineClassification[]): Promise<CleaningReportListAvailableFilters> => {
    const machinesPromise = this.machineListFilter({
      filter: {
        siteIds: [],
        classifications,
      },
    });

    const sitesPromise = this.siteService.listForSelect({
      paginationOptions: {
        limit: OpenSearch.MAX_RESULT_WINDOW,
        paginationToken: '',
      },
    });

    return Promise.all([machinesPromise, sitesPromise])
      .then(([machinesResponse, sitesResponse]) => {
        const {
          machines: { data: machines },
        } = machinesResponse;

        const {
          sites: { data: sites },
        } = sitesResponse;

        const machineStatuses: ILegacyMachineStatusItemData[] = Object.values(LegacyMachineStatusItemData).filter(machineStatus =>
          [
            LegacyMachineStatus.DATA_AGE_ONE_TO_THREE_DAYS,
            LegacyMachineStatus.DATA_AGE_OVER_THREE_DAYS,
            LegacyMachineStatus.DATA_AGE_LESS_THAN_ONE_DAY,
          ].includes(machineStatus.status)
        );

        const taskCompletionStatuses: ICleaningReportTaskCompletion[] = Object.values(TaskCompletion).map(status => ({
          label: status,
          value: status,
        }));

        return {
          machines,
          standAloneMachines: machines,
          sites,
          machineStatus: machineStatuses,
          taskCompletion: taskCompletionStatuses,
        };
      })
      .catch(error => {
        throw error;
      });
  };

  public requestExportCleaningReports = async ({
    filter,
    timezone,
    sortOptions,
    lang,
  }: CleaningReportServiceRequestExportCleaningReportsOptions): Promise<Optional<CleaningReportsExportRequest>> => {
    const { data, errors } = await this.cleaningReportClient.requestExportCleaningReports({
      filter,
      timezone,
      sortOptions,
      lang,
    });

    if (errors?.length) throw errors;

    return data;
  };

  public getExportCleaningReportsFile = async ({
    requestId,
  }: CleaningReportServiceGetExportCleaningReportsFileOptions): Promise<CleaningReportsExportGetFile> => {
    const { data, errors } = await this.cleaningReportClient.getExportCleaningReportsFile({ requestId });

    if (errors?.length) throw errors;

    return data;
  };

  public requestRobotDetailsReportsExport = async ({
    id,
    timezone,
    machineId,
    lang,
  }: CleaningReportClientRequestRobotDetailsExportOptions): Promise<
    Optional<CleaningReportsExportRobotDetailsRequest>
  > => {
    const { data, errors } = await this.cleaningReportClient.requestRobotDetailsReportsExport({
      id,
      timezone,
      machineId,
      lang,
    });

    if (errors?.length) throw errors;

    return data;
  };
}
