import { StyledRobotTotalCleanedHourChartBar } from './RobotTotalCleanedHourChartBar.styles';
import {
  Maybe,
  RobotDashboardTotalCleanedHrsData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface IRobotTotalCleanedHourChartBarProps {
  key: string;
  totalCleanedHoursDatum: RobotDashboardTotalCleanedHrsData;
  cleanedHoursMaximum: number;
}

export const RobotTotalCleanedHourChartBar = ({
  totalCleanedHoursDatum,
  cleanedHoursMaximum,
}: IRobotTotalCleanedHourChartBarProps): Maybe<JSX.Element> => {
  const cleanedHour = totalCleanedHoursDatum.cleanedHrs;
  let valueBarPercentage = 0;

  if (cleanedHour !== 0 && cleanedHoursMaximum !== 0) {
    const valueBarPercentageWithoutLimits: number = (100 / cleanedHoursMaximum) * cleanedHour;
    // Clamp the value to the range 0-100;
    valueBarPercentage = Math.min(Math.max(0, valueBarPercentageWithoutLimits), 100);
  }

  return (
    <StyledRobotTotalCleanedHourChartBar
      className="robot-total-cleaned-hour-chart-bar"
      valueBarPercentage={valueBarPercentage}
      title={`${Math.round(valueBarPercentage)}%`}
    >
      <div
        className={`robot-total-cleaned-hour-chart-bar__background-bar
          robot-total-cleaned-hour-chart-bar__bar`}
      >
        <div
          className={`robot-total-cleaned-hour-chart-bar__value-bar
            robot-total-cleaned-hour-chart-bar__bar`}
        />
      </div>
    </StyledRobotTotalCleanedHourChartBar>
  );
};
