import styled from 'styled-components';

export const StyledRobotBoxList = styled.div`
  .robot-box-group__wrapper {
    background-color: ${(props): string => props.theme.colors.white};
    width: 100%;
    padding: 24px;
    margin: 8px 0;

    .robot-box-group__site-name {
      ${(props): string => props.theme.fontStyles.caption.medium};
      font-weight: ${(props): number => props.theme.fontWeights.p0};
      text-transform: uppercase;
    }

    .robot-box-group__row {
      min-height: 255px;

      .ant-col {
        height: auto;

        &.robot-box__faded-in {
          opacity: 0;
          animation: fadeIn 1000ms ease-out forwards;

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
        }

        &.robot-box__faded-out {
          animation: fadeOut 1000ms ease-in-out forwards;

          @keyframes fadeOut {
            0% {
              opacity: 1;
            }

            100% {
              opacity: 0;
              display: none;
            }
          }
        }
      }
    }

    .robot-kpi-bar {
      margin: 16px 0;
    }
  }
`;
