import { createSlice } from '@reduxjs/toolkit';
import {
  RobotAssignSiteModalIsLoadingAction,
  RobotAssignSiteModalIsNotLoadingAction,
  RobotAssignToSiteErrorAction,
  RobotAssignToSiteRequestAction,
  RobotAssignToSiteSuccessAction,
  RobotHideAssignSiteModalAction,
  RobotShowAssignSiteModalAction,
  SitesListErrorAction,
  SitesListRequestAction,
  SitesListSuccessAction,
} from './RobotModalsActions.types';
import { Optional } from 'lib/types/Optional';
import { SiteAssignMachineResponse } from 'app/modules/site-management/interfaces/Site.types';
import { Site } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface RobotModalsState {
  robotAssignSiteModal: {
    visible: boolean;
    isLoading: boolean;
    robotId: string;
  };
  assignToSite: {
    isLoading: boolean;
    data: Optional<SiteAssignMachineResponse>;
  };
  site: {
    data: Optional<Site[]>;
    isLoading: Optional<boolean>;
  };
}

export const initialState: RobotModalsState = {
  robotAssignSiteModal: {
    visible: false,
    isLoading: false,
    robotId: '',
  },
  assignToSite: {
    isLoading: false,
    data: null,
  },
  site: {
    data: null,
    isLoading: false,
  },
};

const robotModalsSlice = createSlice({
  name: 'robotModals',
  initialState,
  reducers: {
    showRobotAssignSiteModal: (state, action: RobotShowAssignSiteModalAction) => {
      state.robotAssignSiteModal.visible = true;
      state.robotAssignSiteModal.robotId = action.payload.robotId;
      return state;
    },
    hideRobotAssignSiteModal: (state, _action: RobotHideAssignSiteModalAction) => {
      state.robotAssignSiteModal.visible = false;
      return state;
    },
    robotAssignSiteModalIsLoading: (state, _action: RobotAssignSiteModalIsLoadingAction) => {
      state.robotAssignSiteModal.isLoading = true;
      return state;
    },
    robotAssignSiteModalIsNotLoading: (state, _action: RobotAssignSiteModalIsNotLoadingAction) => {
      state.robotAssignSiteModal.isLoading = false;
      return state;
    },

    robotAssignToSiteRequest: (state, _action: RobotAssignToSiteRequestAction) => {
      state.assignToSite.isLoading = true;
      return state;
    },
    robotAssignToSiteSuccess: (state, action: RobotAssignToSiteSuccessAction) => {
      state.assignToSite.isLoading = false;
      state.assignToSite.data = action.payload;
      return state;
    },
    robotAssignToSiteError: (state, _action: RobotAssignToSiteErrorAction) => {
      state.assignToSite.isLoading = false;
      return state;
    },

    sitesListRequest: (state, _action: SitesListRequestAction) => {
      state.site.isLoading = true;
      return state;
    },
    sitesListSuccess: (state, action: SitesListSuccessAction) => {
      state.site.isLoading = false;
      state.site.data = action.payload.sites.data || [];
      return state;
    },
    sitesListError: (state, _action: SitesListErrorAction) => {
      state.site.isLoading = false;
      return state;
    },
  },
});

export const RobotModalsActions = robotModalsSlice.actions;
export const robotModalsReducer = robotModalsSlice.reducer;
