import styled from 'styled-components';

// TODO: Split styles to components CleaningReportListExact and CleaningReportListTotals
export const StyledCleaningReportList = styled.div`
  ${(props): string => props.theme.mixins.layout.addHeaderContentClass('cleaning-report-list')}
  ${(props): string => props.theme.mixins.layout.addBodyContentClass('cleaning-report-list')}
  ${(props): string => props.theme.mixins.layout.addContainerClasses('cleaning-report-list')}

  min-height: inherit;

  .cleaning-report-list__header {
    background-color: transparent;
  }

  .cleaning-report-list__title {
    ${(props): string => props.theme.fontStyles.redesign.heading1};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .cleaning-report-list__submenu {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;

    .cleaning-report-list__tabs {
      width: 100%;
      margin-top: 20px;
    }
  }

  .cleaning-report-list__filter-label {
    ${(props): string => props.theme.fontStyles.caption.bold}
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
  }

  .cleaning-report-list__select-wrapper {
    display: inline-block;
    min-width: 260px;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .cleaning-report-list__machines-filter {
    width: 174px;

    .ant-select-selection-overflow-item > * {
      ${(props): string => props.theme.mixins.truncateText}
    }
  }

  .cleaning-report-list__filter-button-wrapper-container {
    flex-direction: row;
    display: flex;
    gap: 16px;
  }

  .cleaning-report-list__filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.xl};

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }

    .ant-form-item .ant-form-item-control-input {
      min-height: 32px;
    }
  }

  .cleaning-report-list__filter-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.xs};
    ${(props): string => props.theme.fontStyles.redesign.base.strong};
  }

  .cleaning-report-list__filter-button-icon {
    width: 14px;
    svg {
      margin-bottom: -2px;
    }
  }

  .cleaning-report-list__filter-reset-button-icon {
    width: 16px;
    svg {
      margin-bottom: -3px;
    }
  }

  .cleaning-report-list__filter-button-counter {
    line-height: 20px;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;

    color: ${(props): string => props.theme.colors.black};
    background-color: ${(props): string => props.theme.colors.white};
  }

  .cleaning-report-list__search-bar {
    min-width: 270px;
    margin-left: auto;
  }

  .cleaning-report-list__input {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px;
    padding-left: 14px !important;
    padding: 0px;

    .ant-input-prefix {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      border-left: 1px solid ${(props): string => props.theme.colors.darkGrey};
      padding: 14px;
    }

    input {
      top: 0px !important;
    }
  }

  .cleaning-report-list__search-bar-icon {
    width: 16px;
    height: 16px;

    i,
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .cleaning-report-list__filter-wrapper-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: ${(props): string => props.theme.spacing.base};
  }

  .cleaning-report-list__filter-wrapper-container--hidden {
    display: none;
  }

  .cleaning-report-list__select {
    width: 100%;
  }

  .cleaning-report-list__machines-filter {
    .ant-select-selection-placeholder {
      color: ${(props): string => props.theme.colors.black};
    }
  }

  .cleaning-report-list__device-type {
    .ant-tabs-content-holder {
      display: none;
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  .cleaning-report-list__report-type-tabs {
    .ant-tabs-content-holder {
      display: none;
    }
  }

  .cleaning-report-list__export-button {
    width: 172px;
  }

  .cleaning-report-list__export-icon {
    path {
      fill: ${(props): string => props.theme.colors.black};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }
  }

  .cleaning-report-list__table_export-icon {
    path {
      fill: ${(props): string => props.theme.colors.black};
    }
    svg {
      width: 24px;
      height: 24px;
      margin-bottom: -2px;
    }
  }

  .cleaning-report-list__deviation {
    display: flex;
    align-items: center;
  }

  .cleaning-report-list__deviation-tooltip-icon {
    margin-left: 2px;
    font-size: ${(props): string => props.theme.fontSizes.heading3};
    color: ${(props): string => props.theme.colors.darkGrey};

    &:before {
      vertical-align: 2px;
    }
  }
  .cleaning-report-list__page-info {
    display: flex;
    justify-content: space-between;
  }
  .cleaning-report-list__report-sub {
    display: inline-flex;
  }
  .ant-btn.button-base.cleaning-report-list__report-subscription-button {
    height: 40px;
    padding: 6px 12px 5px;
    border: 1px solid;
    border-color: ${(props): string => props.theme.colors.grey};
    margin-right: ${(props): string => props.theme.spacing.sm};

    span {
      ${(props): string => props.theme.fontStyles.redesign.lg.strong};
    }
  }

  .select-wrapper-connection-status {
    .machine-connection-status__icon {
      color: ${(props): string => props.theme.colors.darkGrey};
    }

    .machine-connection-status__text {
      color: ${(props): string => props.theme.colors.black};
    }

    .ant-select-item-option-selected {
      .machine-connection-status__icon,
      .machine-connection-status__text {
        color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .cleaning-report-list__select--task-completion {
    .ant-select-selector {
      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          span {
            ${(props): string => props.theme.mixins.truncateText}
          }
        }
      }
    }
  }

  .cleaning-report-list__status-pill {
    text-transform: capitalize;
  }

  td.ant-table-cell.cleaning-report-list__export-icon-column.ant-table-cell-ellipsis {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .ant-btn.button-base.button-active {
    background-color: ${(props): string => props.theme.colors.black};
    color: white;
    path {
      fill: ${(props): string => props.theme.colors.white};
    }

    &:hover {
      background-color: ${(props): string => props.theme.colors.black};
      color: white;
      path {
        fill: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .cleaning-report-list__select-wrapper-sites {
    min-width: 145px;
  }

  .cleaning-report-list__select-wrapper-machines {
    min-width: 120px;
  }

  .cleaning-report-list__select-wrapper-task-completion {
    min-width: 220px;
  }
`;
