import { useTranslation } from 'react-i18next';
import { RobotViewModel } from '../../../RobotViewModel';
import { StyledRobotCleaningRoute } from './RobotCleaningRoute.styles';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export const RobotCleaningRoute = ({ robot }: { robot: Machine }): JSX.Element => {
  const { t } = useTranslation();
  const robotModelView = new RobotViewModel(robot);

  const isRobotCleaning =
    robot.robotStatus === RobotStatus.Autonomous && robot.connectionStatus === MachineConnectionStatus.Online;

  return (
    <StyledRobotCleaningRoute>
      {!isRobotCleaning && (
        <p className="robot-cleaning-route__label">{t(robotModelView.getCleaningRouteLabel)}&nbsp;</p>
      )}
      <span className="robot-cleaning-route__details">
        {robotModelView.getRobotCleaningRouteNameAndDate.routeName || t('common.NA')}
      </span>
    </StyledRobotCleaningRoute>
  );
};
