import styled from 'styled-components';

export const StyledRobotDocking = styled.div`
  .robot-docking__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .robot-docking__icon {
      width: 24px;
      height: 24px;
      text-align: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .robot-docking__label {
    ${(props): string => props.theme.fontStyles.caption.news}
    color: ${(props): string => props.theme.colors.darkGrey};
    width: auto;
    word-break: break-word;
    white-space: normal;
  }
`;
