import styled from 'styled-components';

export const StyledCleaningReportListRobot = styled.div`
  .cleaning-task-report-robot__expandable-header {
    ${(props): string => props.theme.fontStyles.caption.bold};
    text-transform: uppercase;
  }

  .ant-table {
    .cleaning-report-list__export-icon {
      cursor: pointer;
    }
  }

  .cleaning-task-report-robot__image-route-icon {
    cursor: pointer;

    .device-map-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .image-icon {
      svg {
        width: 32px;
        height: 32px;
        display: block;
      }
    }
  }
  .cleaning-task-report-robot__preview-route-image-wrapper {
    .cleaning-task-report-robot__preview-route-image {
      display: none;
    }

    .ant-image-mask {
      display: none;
    }
  }

  .ant-table-container {
    table {
      .ant-table-tbody {
        tr > td.cleaning-report-list-robot__task-column {
          padding: 4px ${(props): string => props.theme.spacing.sm};
        }
        tr > td.cleaning-task-report-robot__image-icon-column {
          padding-top: 0px;
          padding-bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;
