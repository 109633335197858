import { singleton } from 'tsyringe';
import {
  CleaningClientReportSubscriptionOptions,
  CleaningReportSubscriptionClient,
} from './CleaningReportSubscriptionClient';
import { ResponseCleaningReportSubscriptions } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export type CleaningServiceReportSubscriptionOptions = CleaningClientReportSubscriptionOptions;

@singleton()
export class CleaningReportSubscriptionService {
  constructor(private reportSubscriptionsClient: CleaningReportSubscriptionClient) {}

  public get = async (): Promise<ResponseCleaningReportSubscriptions> => {
    const { data } = await this.reportSubscriptionsClient.get();
    return data;
  };

  public save = async ({
    lang,
    cleaningReportSubscriptions,
  }: CleaningServiceReportSubscriptionOptions): Promise<Optional<ResponseCleaningReportSubscriptions>> => {
    const { data } = await this.reportSubscriptionsClient.save({ lang, cleaningReportSubscriptions });
    return data;
  };
}
