import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { MachineWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';

export class CleaningWidgetsFleetDashboardSelectors {
  public static selectData = (state: IState): Optional<MachineWithCleaningReport[]> =>
    state.modules.cleaning.widgets['fleet-dashboard'].data;

  public static selectIsLoading = (state: IState): Optional<boolean> =>
    state.modules.cleaning.widgets['fleet-dashboard'].isLoading;
}
