import { StyledRobotTotalCleanedAreaChartBar } from './RobotTotalCleanedAreaChartBar.styles';
import {
  Maybe,
  RobotDashboardTotalCleanedAreaData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface IRobotTotalCleanedAreaChartBarProps {
  key: string;
  totalCleanedAreasDatum: RobotDashboardTotalCleanedAreaData;
  cleanedAreasMaximum: number;
}

export const RobotTotalCleanedAreaChartBar = ({
  totalCleanedAreasDatum,
  cleanedAreasMaximum,
}: IRobotTotalCleanedAreaChartBarProps): Maybe<JSX.Element> => {
  const cleanedAreas = totalCleanedAreasDatum.cleanedArea;
  let valueBarPercentage = 0;

  if (cleanedAreas !== 0 && cleanedAreasMaximum !== 0) {
    const valueBarPercentageWithoutLimits: number = (100 / cleanedAreasMaximum) * cleanedAreas;
    // Clamp the value to the range 0-100;
    valueBarPercentage = Math.min(Math.max(0, valueBarPercentageWithoutLimits), 100);
  }

  return (
    <StyledRobotTotalCleanedAreaChartBar
      className="robot-total-cleaned-area-chart-bar"
      valueBarPercentage={valueBarPercentage}
      title={`${Math.round(valueBarPercentage)}%`}
    >
      <div
        className={`robot-total-cleaned-area-chart-bar__background-bar
          robot-total-cleaned-area-chart-bar__bar`}
      >
        <div
          className={`robot-total-cleaned-area-chart-bar__value-bar
            robot-total-cleaned-area-chart-bar__bar`}
        />
      </div>
    </StyledRobotTotalCleanedAreaChartBar>
  );
};
