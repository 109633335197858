import styled from 'styled-components';

export const StyledRobotBattery = styled.div`
  .robot-battery__wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    .robot-battery__icon {
      width: 24px;
      height: 24px;

      text-align: center;

      svg {
        width: 12px;
        height: 20px;
      }
    }

    .robot-battery__data {
      display: flex;

      .odometer-wrapper {
        font-family: ${(props): string => props.theme.fonts.captionBold};
        font-weight: ${(props): number => props.theme.fontWeights.captionBold};
        color: ${(props): string => props.theme.colors.black};
        font-size: 18px;
        height: 17px;
        line-height: 18px;
        word-break: break-word;
        white-space: normal;
      }

      .robot-battery__unit {
        font-family: ${(props): string => props.theme.fonts.captionBold};
        font-weight: ${(props): number => props.theme.fontWeights.captionBold};
        font-size: 18px;
        line-height: 18px;
        height: 18px;
        color: ${(props): string => props.theme.colors.black};
        margin: 0 4px;
      }
    }
  }

  .robot-battery__label {
    ${(props): string => props.theme.fontStyles.caption.news}
    color: ${(props): string => props.theme.colors.darkGrey};
    width: auto;
    word-break: break-word;
    white-space: normal;
  }
`;
