import { ICalendarEvent, ICleaningPlanEvent } from '../interfaces/CleaningPlan.types';
import { DateTime } from 'lib/utils/date-handling/DateTime';

interface ICalendarEventWithStartTime extends ICalendarEvent {
  start: Date;
}

export const CALENDAR_EVENT_TIME_FORMAT = 'HH:mm';

export const DEFAULT_SCROLL_HOUR = 6;

/**
 * Utility functions related to react-big-calendar
 */
export class CalendarUtils {
  public static convertCleaningPlanEventToCalendarEvent(cleaningPlanEvent: ICleaningPlanEvent): ICalendarEvent {
    return {
      title: cleaningPlanEvent.machine.name,
      start: new Date(cleaningPlanEvent.startAt),
      end: new Date(cleaningPlanEvent.endAt),
      resource: {
        cleaningPlanEvent,
      },
    };
  }

  /**
   * Retrieves the earliest start time from an array of calendar events.
   *
   * @param calendarEvents - The array of calendar events to retrieve the earliest start time from.
   * @return - earliest start time
   */
  public static getInitialTimeFromCalendarEvents(calendarEvents: ICalendarEvent[]): Date {
    try {
      const eventsWithStartTimes: ICalendarEventWithStartTime[] = calendarEvents.reduce(
        (acc: ICalendarEventWithStartTime[], event: ICalendarEvent): ICalendarEventWithStartTime[] => {
          if (CalendarUtils.hasStartTime(event)) {
            acc.push(event);
          }

          return acc;
        },
        [] as ICalendarEventWithStartTime[]
      );

      const eventsStartTimes = eventsWithStartTimes.map(event => {
        const startTime = event.start;

        const startTimeToday = DateTime.today();
        startTimeToday.setHours(startTime.getHours());
        startTimeToday.setMinutes(startTime.getMinutes());
        startTimeToday.setSeconds(startTime.getSeconds());
        startTimeToday.setMilliseconds(startTime.getMilliseconds());

        return startTimeToday;
      });

      if (eventsStartTimes.length <= 0) {
        throw new Error('No calendar event with a valid start time found.');
      }

      const earliestStartTime = eventsStartTimes.sort((a: Date, b: Date): number => a.getTime() - b.getTime())[0];

      if (earliestStartTime.getHours() < 0 || earliestStartTime.getHours() > 23) {
        throw new Error('Start time of earliest calendar event outside of valid range.');
      }

      return earliestStartTime;
    } catch (err) {
      const earliestStartTime = DateTime.today();
      earliestStartTime.setHours(DEFAULT_SCROLL_HOUR);
      earliestStartTime.setMinutes(0);
      earliestStartTime.setSeconds(0);
      earliestStartTime.setMilliseconds(0);

      return earliestStartTime;
    }
  }

  /**
   * Checks if a calendar event has a start time.
   *
   * @param calendarEvent - The calendar event object to check.
   * @returns - True if the calendar event has a start time, false otherwise.
   */
  private static hasStartTime(calendarEvent: ICalendarEvent): calendarEvent is ICalendarEventWithStartTime {
    return calendarEvent.start !== undefined;
  }
}
