import { produce } from 'immer';
import { AnyAction } from 'redux';
import { CleaningWidgetsFleetDashboardActions } from './cleaningWidgetsFleetDashboardActions';
import { Optional } from 'lib/types/Optional';
import { MachineWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';

export interface CleaningWidgetsFleetDashboardState {
  data: Optional<MachineWithCleaningReport[]>;
  isLoading: Optional<boolean>;
}

export const initialState: CleaningWidgetsFleetDashboardState = {
  data: null,
  isLoading: null,
};

export const cleaningWidgetsFleetDashboardReducer = (
  state = initialState,
  action: AnyAction
): CleaningWidgetsFleetDashboardState =>
  produce(state, draft => {
    switch (action.type) {
      case CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_SUCCESS: {
        const {
          machines: { data },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;

        return draft;
      }

      case CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case CleaningWidgetsFleetDashboardActions.RESET_STATE: {
        return initialState;
      }

      default:
        return draft;
    }
  });
