import isArray from 'lodash-es/isArray';
import noop from 'lodash-es/noop';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import { useDeepCompareEffect, useDeepCompareMemo } from 'use-deep-compare';
import { selectCustomerId } from '../../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { ThemeConstants } from '../../../../../../../config/theme';
import { RobotTotalCleanedAreaChart } from '../RobotTotalCleanedAreaChart/RobotTotalCleanedAreaChart';
import { RobotCleaningStatusResult } from '../RobotCleaningStatusResult/RobotCleaningStatusResult';
import { StyledRobotDashboard } from './RobotDashboard.styles';
import { RobotKpiBar } from './RobotKpiBar/RobotKpiBar';
import { RobotDashboardFilter } from './RobotDashboardFilter/RobotDashboardFilter';
import { RobotListGroupedByStatus } from './RobotList/RobotListGroupedByStatus/RobotListGroupedByStatus';
import { RobotListGroupedBySite } from './RobotList/RobotListGroupedBySite/RobotListGroupedBySite';
import { RobotDashboardActions } from 'app/modules/cleaning/widgets/robot-dashboard/state/RobotDashboardSlice';
import * as robotDashboardSelectors from 'app/modules/cleaning/widgets/robot-dashboard/state/RobotDashboardSelectors';
import { OpenSearch, ROBOT_LIST_GROUP_BY } from 'config/constants';
import { RobotTotalCleanedHourChart } from 'app/modules/cleaning/widgets/robot-dashboard/components/RobotTotalCleanedHourChart/RobotTotalCleanedHourChart';
import {
  MachineConnectionStatus,
  TaskCompletion,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { LegacyTextButton } from 'lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { SubscriptionMachineEvent } from 'app/modules/machine-inventory/interfaces/MachineSubscription.types';

export const RobotDashboard = (): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customerId = useSelector(selectCustomerId);

  const robotsWithTotalCleanedAreaData = useSelector(robotDashboardSelectors.selectRobotsWithTotalCleanedAreaData);
  const isRobotsWithTotalCleanedAreaLoading = useSelector(
    robotDashboardSelectors.selectIsRobotsWithTotalCleanedAreaLoading
  );

  const robotsWithTotalCleanedHourData = useSelector(robotDashboardSelectors.selectRobotsWithTotalCleanedHourData);
  const isRobotsWithTotalCleanedHourLoading = useSelector(
    robotDashboardSelectors.selectIsRobotsWithTotalCleanedHourLoading
  );

  const robotsKPIsData = useSelector(robotDashboardSelectors.selectRobotDashboardKPIsData);
  const isRobotsKPIsLoading = useSelector(robotDashboardSelectors.selectIsRobotDashboardKPIsLoading);

  const tasksCompletionData = useSelector(robotDashboardSelectors.selectRobotsTasksCompletionData);
  const isTasksCompletionLoading = useSelector(robotDashboardSelectors.selectIsRobotsTasksCompletionLoading);

  const groupBy = useSelector(robotDashboardSelectors.selectGroupBy);
  const startDate = useSelector(robotDashboardSelectors.selectPeriodStartDate);
  const endDate = useSelector(robotDashboardSelectors.selectPeriodEndDate);
  const robots = useSelector(robotDashboardSelectors.selectRobotListData);
  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData);
  const sitesRobots = useSelector(robotDashboardSelectors.selectRobotListGroupedBySiteSiteList);

  const areSitesRobotsLoading = useSelector(robotDashboardSelectors.selectIsRobotListGroupedBySiteWithRobotsLoading);
  const robotsLoading = useSelector(robotDashboardSelectors.selectIsRobotListLoading);
  const areUnassignedRobotsLoading = useSelector(robotDashboardSelectors.selectIsRobotUnassignedListLoading);

  const robotRealTimeUpdatedEvent = useSelector(robotDashboardSelectors.selectRobotDashboardRealTimeUpdatedEvent);

  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  const [isListLayout, setIsListLayout] = useState(true);

  const handleSwitchLayout = (): void => {
    setIsListLayout(prev => !prev);
  };

  const machineIdsFilter = useDeepCompareMemo(() => {
    const machineIds: string[] = [];

    switch (groupBy) {
      case ROBOT_LIST_GROUP_BY.site:
        // unassigned robots
        if (robots && robots?.length > 0) {
          robots
            ?.filter(robot =>
              isHidingOfflineRobots
                ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                  robot.connectionStatus !== MachineConnectionStatus.Unknown
                : robot
            )
            .forEach(robot => {
              machineIds.push(robot.id);
            });
        }

        sitesRobots?.forEach(siteRobots => {
          if (siteRobots.machines?.data && siteRobots.machines?.data.length > 0) {
            siteRobots.machines?.data
              ?.filter(robot =>
                isHidingOfflineRobots
                  ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                    robot.connectionStatus !== MachineConnectionStatus.Unknown
                  : robot
              )
              .forEach(robot => {
                machineIds.push(robot.id);
              });
          }
        });

        unassignedRobots
          ?.filter(robot =>
            isHidingOfflineRobots
              ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                robot.connectionStatus !== MachineConnectionStatus.Unknown
              : robot
          )
          .forEach(robot => {
            machineIds.push(robot.id);
          });
        break;

      default:
        robots
          ?.filter(robot =>
            isHidingOfflineRobots
              ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                robot.connectionStatus !== MachineConnectionStatus.Unknown
              : robot
          )
          .forEach(robot => {
            machineIds.push(robot.id);
          });

        unassignedRobots
          ?.filter(robot =>
            isHidingOfflineRobots
              ? robot.connectionStatus !== MachineConnectionStatus.Offline &&
                robot.connectionStatus !== MachineConnectionStatus.Unknown
              : robot
          )
          .forEach(robot => {
            machineIds.push(robot.id);
          });
        break;
    }

    dispatch(
      RobotDashboardActions.robotDashboardSetActiveRobotIdsFilter({
        robotIds: machineIds,
      })
    );

    return machineIds.length !== 0 ? machineIds : undefined;
  }, [dispatch, groupBy, robots, sitesRobots, unassignedRobots, isHidingOfflineRobots]);

  const areRobotsFinishedLoading = useDeepCompareMemo((): boolean => {
    if (groupBy === ROBOT_LIST_GROUP_BY.site) {
      return (
        robotsLoading === null &&
        areSitesRobotsLoading === false &&
        areUnassignedRobotsLoading === false &&
        isArray(machineIdsFilter) &&
        machineIdsFilter.length > 0
      );
    }

    return (
      robotsLoading === false &&
      areSitesRobotsLoading === null &&
      areUnassignedRobotsLoading === null &&
      isArray(machineIdsFilter) &&
      machineIdsFilter.length > 0
    );
  }, [groupBy, areUnassignedRobotsLoading, machineIdsFilter, robotsLoading, areSitesRobotsLoading]);

  useDeepCompareEffect(() => {
    if (areRobotsFinishedLoading && robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.SITE_CHANGED) {
      dispatch(
        RobotDashboardActions.robotDashboardListRobotsWithTotalCleanedAreaRequest({
          filter: {
            period: {
              endAt: endDate,
              startAt: startDate,
            },
            machineIds: machineIdsFilter,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, startDate, endDate, areRobotsFinishedLoading, machineIdsFilter, robotRealTimeUpdatedEvent]);

  useDeepCompareEffect(() => {
    if (areRobotsFinishedLoading && robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.SITE_CHANGED) {
      dispatch(
        RobotDashboardActions.robotDashboardTotalCleanedHourRequest({
          filter: {
            period: {
              endAt: endDate,
              startAt: startDate,
            },
            machineIds: machineIdsFilter,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, startDate, endDate, areRobotsFinishedLoading, machineIdsFilter, robotRealTimeUpdatedEvent]);

  useDeepCompareEffect(() => {
    if (areRobotsFinishedLoading && robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.SITE_CHANGED) {
      dispatch(
        RobotDashboardActions.robotDashboardKPIsRequest({
          filter: {
            period: {
              endAt: endDate,
              startAt: startDate,
            },
            machineIds: machineIdsFilter,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
        })
      );
    }
  }, [dispatch, startDate, endDate, areRobotsFinishedLoading, machineIdsFilter, robotRealTimeUpdatedEvent]);

  useDeepCompareEffect(() => {
    if (areRobotsFinishedLoading && robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.SITE_CHANGED) {
      dispatch(
        RobotDashboardActions.robotDashboardGetTasksCompletionRequest({
          filter: {
            period: {
              endAt: endDate,
              startAt: startDate,
            },
            machineIds: machineIdsFilter,
          },
        })
      );
    }
  }, [dispatch, startDate, endDate, areRobotsFinishedLoading, machineIdsFilter, robotRealTimeUpdatedEvent]);

  const machineIdsSubscription = useDeepCompareMemo(() => {
    const machineIds: string[] = [];

    switch (groupBy) {
      case ROBOT_LIST_GROUP_BY.site:
        // unassigned robots
        if (robots && robots?.length > 0) {
          robots.forEach(robot => {
            machineIds.push(robot.id);
          });
        }

        sitesRobots?.forEach(siteRobots => {
          if (siteRobots.machines?.data && siteRobots.machines?.data.length > 0) {
            siteRobots.machines?.data.forEach(robot => {
              machineIds.push(robot.id);
            });
          }
        });

        unassignedRobots?.forEach(robot => {
          machineIds.push(robot.id);
        });
        break;

      default:
        robots?.forEach(robot => {
          machineIds.push(robot.id);
        });

        unassignedRobots?.forEach(robot => {
          machineIds.push(robot.id);
        });
        break;
    }

    return machineIds.length !== 0 ? machineIds : undefined;
  }, [dispatch, groupBy, robots, sitesRobots, unassignedRobots, isHidingOfflineRobots]);

  useDeepCompareEffect(() => {
    if (customerId === null) {
      return noop;
    }

    if (areRobotsFinishedLoading) {
      machineIdsSubscription?.forEach((machineId: string) => {
        dispatch(
          RobotDashboardActions.subscribeToMachineUpdate({
            customerId,
            machineId,
          })
        );
      });

      return () => {
        machineIdsSubscription?.forEach((machineId: string) => {
          dispatch(
            RobotDashboardActions.unsubscribeFromMachineUpdate({
              customerId,
              machineId,
            })
          );
        });
      };
    }

    // No cleanup necessary as long as no subscriptions have been registered
    return noop;
  }, [areRobotsFinishedLoading, dispatch, machineIdsSubscription]);

  useEffect(() => {
    if ((!areSitesRobotsLoading || !robotsLoading || !areUnassignedRobotsLoading) && !machineIdsFilter) {
      dispatch(RobotDashboardActions.robotDashboardCleanedAreaResetState());
      dispatch(RobotDashboardActions.robotDashboardCleanedHourResetState());
      dispatch(RobotDashboardActions.robotDashboardKPIsResetState());
      dispatch(RobotDashboardActions.robotDashboardGetTasksCompletionResetState());
    }
  }, [dispatch, areSitesRobotsLoading, robotsLoading, areUnassignedRobotsLoading, machineIdsFilter]);

  useEffect(
    () => (): void => {
      dispatch(RobotDashboardActions.robotDashboardCleanedAreaResetState());
      dispatch(RobotDashboardActions.robotDashboardCleanedHourResetState());
      dispatch(RobotDashboardActions.robotDashboardKPIsResetState());
      dispatch(RobotDashboardActions.robotDashboardGetTasksCompletionResetState());
    },
    [dispatch, endDate, startDate]
  );

  return (
    <StyledRobotDashboard>
      <div className="robot-dashboard__header">
        <div className="robot-dashboard__header-content">
          <div className="robot-dashboard__container--wide">
            <h1 className="robot-dashboard__title">{t('common.robotDashboard')}</h1>
            <RobotDashboardFilter />
          </div>
        </div>
      </div>
      <div className="robot-dashboard__body">
        <div className="robot-dashboard__body-content robot-dashboard__body-content--no-top-padding">
          <div className="robot-dashboard__container--wide">
            <div className="robot-dashboard__list">
              <div className="robot-dashboard__view-list-icon-container">
                {isListLayout ? (
                  <LegacyTextButton onClick={handleSwitchLayout} className="robot-dashboard__view-btn">
                    <SvgIcon name="viewList" className="robot-dashboard__view-list-icon" />
                  </LegacyTextButton>
                ) : (
                  <LegacyTextButton onClick={handleSwitchLayout} className="robot-dashboard__view-btn">
                    <SvgIcon name="viewModule" className="robot-dashboard__view-module-icon" />
                  </LegacyTextButton>
                )}
              </div>
              {groupBy === ROBOT_LIST_GROUP_BY.status ? (
                <RobotListGroupedByStatus isListLayout={isListLayout} />
              ) : (
                <RobotListGroupedBySite isListLayout={isListLayout} />
              )}
            </div>

            <div className="robot-dashboard__kpi-bar">
              <Spin
                spinning={
                  !!isRobotsKPIsLoading && robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.CLEANING_DATA_CHANGED
                }
              >
                <h1 className="robot-dashboard__kpi-bar-title">{t('robotDashboard.robotKpis.title')}</h1>
                <RobotKpiBar
                  totalCleaningHours={robotsKPIsData?.totalCleaningHrs}
                  totalCleanedArea={robotsKPIsData?.totalCleanedArea}
                  totalDistance={robotsKPIsData?.distanceDriven}
                  taskCoverage={robotsKPIsData?.taskCoverage}
                  taskCompletionRate={robotsKPIsData?.tasksCompleted}
                />
              </Spin>
            </div>

            <Row gutter={[ThemeConstants.BASE_GAP, ThemeConstants.BASE_GAP]} className="robot-dashboard__info">
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Spin
                  spinning={
                    !!isTasksCompletionLoading &&
                    robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.CLEANING_DATA_CHANGED
                  }
                >
                  <RobotCleaningStatusResult
                    percentage={tasksCompletionData?.completed || 0}
                    robotTasksCompletionStatus={TaskCompletion.Completed}
                  />
                </Spin>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Spin
                  spinning={
                    !!isTasksCompletionLoading &&
                    robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.CLEANING_DATA_CHANGED
                  }
                >
                  <RobotCleaningStatusResult
                    percentage={tasksCompletionData?.interrupted || 0}
                    robotTasksCompletionStatus={TaskCompletion.Interrupted}
                  />
                </Spin>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <Spin
                  spinning={
                    !!isTasksCompletionLoading &&
                    robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.CLEANING_DATA_CHANGED
                  }
                >
                  <RobotCleaningStatusResult
                    percentage={tasksCompletionData?.stopped || 0}
                    robotTasksCompletionStatus={TaskCompletion.Stopped}
                  />
                </Spin>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Spin
                  spinning={
                    !!isRobotsWithTotalCleanedAreaLoading &&
                    robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.CLEANING_DATA_CHANGED
                  }
                >
                  <RobotTotalCleanedAreaChart data={robotsWithTotalCleanedAreaData} />
                </Spin>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Spin
                  spinning={
                    !!isRobotsWithTotalCleanedHourLoading &&
                    robotRealTimeUpdatedEvent !== SubscriptionMachineEvent.CLEANING_DATA_CHANGED
                  }
                >
                  <RobotTotalCleanedHourChart data={robotsWithTotalCleanedHourData} />
                </Spin>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </StyledRobotDashboard>
  );
};
