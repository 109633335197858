import { useContext } from 'react';
import { WorkIntervalDataRequestGuardContext } from './WorkIntervalDataRequestGuardContext';
import { isProductionBuild } from 'lib/utils/environment/isProductionBuild';

/**
 * Hook to confirm that component is wrapped in a component that dispatches the work interval request action
 *
 * @returns - Boolean value for confirmation
 */
export function useWorkIntervalDataRequestGuard(): boolean {
  const contextValue = useContext(WorkIntervalDataRequestGuardContext);

  if (!isProductionBuild() && !contextValue.iConfirmThatThisComponentDispatchesWorkIntervalRequestAction) {
    throw new Error(
      'Is this component wrapped in another component which dispatches the work interval request action? ' +
        'This should be done for performance reasons, so we dispatch the data request action as early as possible ' +
        'when loading the page. ' +
        'Usually the right component to dispatch this action is the root component which is directly within the Page ' +
        '(e.g. <SiteDetails> in <SiteDetailsPage>). Please dispatch the action from the root component, wrap all its ' +
        'consumers (or simply all its children) in <WorkIntervalDataRequestGuardContext.Provider> and pass in "true" ' +
        'within the confirmation object.'
    );
  }

  return contextValue.iConfirmThatThisComponentDispatchesWorkIntervalRequestAction;
}
