import isNil from 'lodash-es/isNil';
import dayjs from 'dayjs';
import { DateTime } from '../../../../lib/utils/date-handling/DateTime';
import { InputPeriod, OperatingTimeInterval } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Machine, MachineWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { ReactChartUtils } from 'app/utils/react-charts/ReactChartUtils';

export interface OperatingHoursChartData {
  label: string;
  data: OperatingHoursDatum[];
}

export interface OperatingHoursDatum {
  primary: Date;
  secondary: number;
  deviationMs: number;
}

export interface OperatingHoursComparisonChartData {
  label: string;
  data: OperatingHoursComparisonDatum[];
}

export interface OperatingHoursComparisonDatum {
  primary: string;
  secondary: number;
  deviationMs: number;
}

export const START_DATE_LAST_MONTH = DateTime.someMonthsAgo(1);
export const START_DATE = DateTime.someDaysAgo(7);
export const END_DATE = DateTime.today();

export const OPERATING_HOURS_CHART_COLUMN_LABEL_ONE = 'machineDetails.operatingHoursChart.actual';
export const OPERATING_HOURS_CHART_COLUMN_LABEL_TWO = 'machineDetails.operatingHoursChart.planned';

export const OPERATING_HOURS_COMPARISON_CHART_COLUMN_LABEL_ONE = 'machineDetails.operatingHoursChart.actual';
export const OPERATING_HOURS_COMPARISON_CHART_COLUMN_LABEL_TWO = 'machineDetails.operatingHoursChart.planned';

export class OperatingHoursChartUtils {
  public static prepareStartDate(startDate: Date): Date {
    return dayjs(startDate).startOf('day').toDate();
  }

  public static prepareEndDate(endDate: Date): Date {
    return dayjs(endDate).endOf('day').toDate();
  }

  public static convertOperatingTimeIntervalsToChartData(
    operatingTimeIntervals: OperatingTimeInterval[]
  ): OperatingHoursChartData[] {
    const data: OperatingHoursChartData[] = [
      { label: OPERATING_HOURS_CHART_COLUMN_LABEL_ONE, data: [] },
      { label: OPERATING_HOURS_CHART_COLUMN_LABEL_TWO, data: [] },
    ];

    operatingTimeIntervals.forEach(({ startAt, actualOperatingTimeMs, plannedOperatingTimeMs, deviationMs }) => {
      const dateLabel = dayjs(startAt).local().toDate();
      data[0].data.push({
        primary: dateLabel,
        secondary: actualOperatingTimeMs,
        deviationMs,
      });
      data[1].data.push({
        primary: dateLabel,
        secondary: plannedOperatingTimeMs,
        deviationMs,
      });
    });

    return data;
  }

  public static convertCleaningReportPeriodsToComparisonChartData(
    machines: Machine[]
  ): OperatingHoursComparisonChartData[] {
    const data: OperatingHoursComparisonChartData[] = [
      { label: OPERATING_HOURS_COMPARISON_CHART_COLUMN_LABEL_ONE, data: [] },
      { label: OPERATING_HOURS_COMPARISON_CHART_COLUMN_LABEL_TWO, data: [] },
    ];

    const machinesWithCleaningReportPeriods = machines.filter(
      machine => !isNil(machine.operatingTimeForPeriod)
    ) as MachineWithCleaningReport[];

    machinesWithCleaningReportPeriods.forEach((machine: MachineWithCleaningReport) => {
      data[0].data.push({
        primary: ReactChartUtils.combineColumnName(machine.name, machine.id),
        secondary: machine.operatingTimeForPeriod.actualTotalOperatingTimeMs,
        deviationMs:
          machine.operatingTimeForPeriod.actualTotalOperatingTimeMs -
          machine.operatingTimeForPeriod.plannedTotalOperatingTimeMs,
      });
      data[1].data.push({
        primary: ReactChartUtils.combineColumnName(machine.name, machine.id),
        secondary: machine.operatingTimeForPeriod.plannedTotalOperatingTimeMs,
        deviationMs:
          machine.operatingTimeForPeriod.actualTotalOperatingTimeMs -
          machine.operatingTimeForPeriod.plannedTotalOperatingTimeMs,
      });
    });

    return data;
  }

  public static getStartDateEndDateByPeriod(period?: InputPeriod): [Date, Date] {
    let initStartDate = this.prepareStartDate(START_DATE);
    let initEndDate = this.prepareEndDate(END_DATE);

    if (period?.startAt && period?.endAt) {
      initStartDate = dayjs(period?.startAt).local().toDate();
      initEndDate = dayjs(period?.endAt).local().toDate();
    }

    return [initStartDate, initEndDate];
  }
}
