import { produce } from 'immer';
import { AnyAction } from 'redux';
import { FleetOperatingTimeActions } from './fleetOperatingTimeActions';
import { Optional } from 'lib/types/Optional';
import { FleetOperatingTime } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface FleetOperatingTimeState {
  data: Optional<FleetOperatingTime>;
  isLoading: Optional<boolean>;
}

export const initialState: FleetOperatingTimeState = {
  data: null,
  isLoading: null,
};

export const fleetOperatingTimeReducer = (state = initialState, action: AnyAction): FleetOperatingTimeState =>
  produce(state, draft => {
    switch (action.type) {
      case FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_SUCCESS: {
        const {
          fleetOperatingTime: { data },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;
        return draft;
      }

      case FleetOperatingTimeActions.GET_FLEET_OPERATING_TIME_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case FleetOperatingTimeActions.RESET_STATE: {
        return initialState;
      }

      default:
        return draft;
    }
  });
