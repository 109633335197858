import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import * as robotDashboardSelectors from '../../state/RobotDashboardSelectors';
import { RobotDashboardActions } from '../../state/RobotDashboardSlice';
import { StyledRobotCleaningStatusResult } from './RobotCleaningStatusResult.styles';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { CircleProgress } from 'lib/components/Progress/CircleProgress/CircleProgress';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { RoutePaths } from 'config/route-paths';
import { CleaningReportListActions } from 'app/modules/cleaning/cleaning-report-list/state/cleaningReportListActions';
import {
  MachineClassification,
  TaskCompletion,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { OperatingHoursChartUtils, START_DATE, END_DATE } from 'app/modules/cleaning/utils/OperatingHoursChartUtils';
import { theme } from 'config/theme';

interface IRobotCleaningStatusResultProps {
  percentage: number;
  robotTasksCompletionStatus: TaskCompletion;
}

export const RobotCleaningStatusResult = ({
  percentage,
  robotTasksCompletionStatus,
}: IRobotCleaningStatusResultProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startDate = useSelector(robotDashboardSelectors.selectPeriodStartDate);
  const endDate = useSelector(robotDashboardSelectors.selectPeriodEndDate);
  const activeSitesFilter = useSelector(robotDashboardSelectors.selectActiveSites);

  const handleRedirectToCleaningReport = (): void => {
    dispatch(CleaningReportListActions.setActiveSiteFilter(activeSitesFilter));
    dispatch(
      CleaningReportListActions.setActivePeriodFilter({
        startDate: startDate || OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
        endDate: endDate || OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
      })
    );
    dispatch(CleaningReportListActions.setActiveTaskCompletionFilter([robotTasksCompletionStatus]));
    dispatch(CleaningReportListActions.setSelectDeviceType(MachineClassification.Robot));
    dispatch(RobotDashboardActions.robotDashboardIsRedirectedActive());
    navigate(RoutePaths.EVENTS);
  };

  const getColorForCircleProgress = (): string => {
    switch (robotTasksCompletionStatus) {
      case TaskCompletion.Completed:
        return theme.colors.secondary;
      case TaskCompletion.Interrupted:
        return theme.colors.secondary200;
      case TaskCompletion.Stopped:
        return theme.colors.grey200;
      default:
        return theme.colors.gray85;
    }
  };

  return (
    <StyledRobotCleaningStatusResult className="robot-cleaning-status-result">
      <InfoTooltipGlobalStyles />
      <Row justify="center" align="middle" className="robot-cleaning-status-result__container">
        <Col span={24}>
          <Row justify="space-between" className="robot-cleaning-status-result__header">
            <h4 className="robot-cleaning-status-result__title">
              {t(`robotDashboard.cleaningStatusResult.label.${robotTasksCompletionStatus.toLowerCase()}`)}
            </h4>
            <Tooltip
              title={
                <InfoTooltip
                  content={t(`robotDashboard.cleaningStatusResult.tooltip.${robotTasksCompletionStatus.toLowerCase()}`)}
                />
              }
              overlayClassName="tooltip-overlay"
              placement="bottomLeft"
            >
              <div>
                <SvgIcon name="info" className="robot-cleaning-status-result__info-icon" />
              </div>
            </Tooltip>
          </Row>
          <Row justify="center" align="middle" className="robot-cleaning-status-result__body">
            <CircleProgress
              type="circle"
              circleProgressColor={getColorForCircleProgress()}
              percent={percentage}
              size={194}
              strokeLinecap="round"
              onClickCircleProgress={handleRedirectToCleaningReport}
            />
          </Row>
        </Col>
      </Row>
    </StyledRobotCleaningStatusResult>
  );
};
