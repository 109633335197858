import { useTranslation } from 'react-i18next';
import { RobotViewModel } from '../../../RobotViewModel';
import { StyledRobotWaterLevel } from './RobotWaterLevel.styles';
import { RobotWaterLevelIcon } from './WaterLevelIcon';
import { Odometer } from 'lib/components/Odometer/Odometer';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';

export const RobotWaterLevel = ({ robot }: { robot: Machine }): JSX.Element => {
  const { t } = useTranslation();
  const robotModelView = new RobotViewModel(robot);
  const isUSusers = false;

  const waterLevel = isUSusers
    ? ConvertUnitUtils.convertLitersToGallons(Number(robotModelView.getFreshWaterLevel))
    : robotModelView.getFreshWaterLevel;

  return (
    <StyledRobotWaterLevel className="robot-water-level">
      <div className="robot-water-level__wrapper">
        <div className="robot-water-level__icon">
          <RobotWaterLevelIcon waterPercent={robotModelView.getWaterPercentage} />
        </div>
        <div className="robot-water-level__data">
          <Odometer value={waterLevel.toString()} />
          {isUSusers ? (
            <span className="robot-water-level__unit">gal</span>
          ) : (
            <span className="robot-water-level__unit">&#76;</span>
          )}
        </div>
      </div>
      <span className="robot-water-level__label">{t('robotDashboard.waterLevel')}</span>
    </StyledRobotWaterLevel>
  );
};
