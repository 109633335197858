import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Col, Row, Skeleton } from 'antd';
import { RobotImageConnectionStatus } from '../../RobotImageConnectionStatus/RobotImageConnectionStatus';
import { RobotName } from '../../RobotName/RobotName';
import { RobotCleaningStatus } from '../../RobotCleaningStatus/RobotCleaningStatus';
import { RobotCleaningRoute } from '../../RobotCleaningRoute/RobotCleaningRoute';
import { RobotCleaningProgress } from '../../RobotCleaningProgress/RobotCleaningProgress';
import { RobotConnectionStatusIndicator } from '../../RobotConnectionStatusIndicator/RobotConnectionStatusIndicator';
import { RobotRoutine } from '../../../../../../../../components/RobotRoutine/RobotRoutine';
import { RobotModalsActions } from '../../../../modals/state/RobotModalsSlice';
import { RobotBattery } from '../../RobotBattery/RobotBattery';
import { RobotDocking } from '../../RobotDocking/RobotDocking';
import { StyledRobotBoxCV50 } from './RobotBoxCV50.styles';
import { RobotStatus } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachinePictureLoader } from 'app/modules/machine-inventory/components/MachinePictureLoader/MachinePictureLoader';
import { LegacyTextButton } from 'lib/components/LegacyButton/LegacyTextButton/LegacyTextButton';
import { Card } from 'lib/components/Card/Card';

interface RobotBoxProps {
  robot: Machine;
  isShowStatus: boolean;
  areRobotListPicturesLoading: boolean;
  areRobotListTelemetriesLoading: boolean;
  areRobotListLatestCtrLoading: boolean;
  areRobotListLatestRoutineLoading: boolean;
}

export const RobotBoxCV50 = ({
  robot,
  isShowStatus,
  areRobotListPicturesLoading,
  areRobotListTelemetriesLoading,
  areRobotListLatestCtrLoading,
  areRobotListLatestRoutineLoading,
}: RobotBoxProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDocking = robot.robotStatus === RobotStatus.Docking;

  const handleOpenRobotAssignSiteModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    dispatch(RobotModalsActions.showRobotAssignSiteModal({ robotId: robot.id }));
  };

  return (
    <StyledRobotBoxCV50>
      <Card>
        <div className="robot-box__info">
          <Row className="robot-box__header">
            <RobotConnectionStatusIndicator
              lastActivityAt={robot?.lastActivityAt}
              connectionStatus={robot?.connectionStatus}
            />
            <Col span={6} className="robot-box__robot-image-wrapper">
              {robot?.variant && !areRobotListPicturesLoading ? (
                <RobotImageConnectionStatus
                  imageUrl={robot?.variant?.picture.thumbnail}
                  connectionStatus={robot.connectionStatus}
                />
              ) : (
                <MachinePictureLoader />
              )}
            </Col>
            <Col span={14} className="robot-box__robot-info">
              <RobotName machineName={robot.name} serialNumber={robot.serialNumber} />
            </Col>
          </Row>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 6, width: ['40%', '100%', '100%', '100%'] }}
            loading={
              (areRobotListTelemetriesLoading && areRobotListLatestRoutineLoading) || areRobotListLatestCtrLoading
            }
          >
            <div className="robot-box__body">
              <div className="robot-box__body-content">
                <div className="robot-box__status">
                  {isShowStatus && (
                    <RobotCleaningStatus robotStatus={robot.robotStatus} connectionStatus={robot.connectionStatus} />
                  )}
                </div>
                {!robot?.site?.name ? (
                  <LegacyTextButton className="robot-box__assign-site-btn" onClick={handleOpenRobotAssignSiteModal}>
                    {t('robotDashboard.modals.assignSite.button.assignToSite')}
                  </LegacyTextButton>
                ) : (
                  !isShowStatus && (
                    <span className="robot-box__robot-site-name">{robot?.site?.name || t('common.NA')}</span>
                  )
                )}
                <Row className="robot-box__robot-routine-wrapper">
                  <RobotRoutine robot={robot} />
                </Row>
                <Row className="robot-box__cleaning-router">
                  <RobotCleaningRoute robot={robot} />
                </Row>
                <Row className="robot-box__robot-progress-wrapper">
                  <RobotCleaningProgress robot={robot} />
                </Row>
              </div>
            </div>

            <div className="robot-box__footer">
              <hr className="robot-box__line" />
              <Row className="robot-box__cleaning-power" justify="space-around">
                {isDocking && (
                  <Col>
                    <RobotDocking />
                  </Col>
                )}
                <Col>
                  <RobotBattery robot={robot} batteryPackNumber={1} />
                </Col>
                <Col>
                  <RobotBattery robot={robot} batteryPackNumber={2} />
                </Col>
              </Row>
            </div>
          </Skeleton>
        </div>
      </Card>
    </StyledRobotBoxCV50>
  );
};
