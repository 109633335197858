import React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '../../../../../../../../lib/components/SvgIcon/SvgIcon';
import { StyledRobotKpiBar } from './RobotKpiBar.styles';
import { ShortInfo } from 'lib/components/ShortInfo/ShortInfo';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { ConvertUnitUtils } from 'app/utils/convert-unit/ConvertUnitUtils';

export interface RobotKpiBarProps {
  totalCleaningHours?: number;
  totalCleanedArea?: number;
  totalDistance?: number;
  taskCoverage?: number;
  taskCompletionRate?: number;
}

export const RobotKpiBar = ({
  totalCleaningHours = 0,
  totalCleanedArea = 0,
  totalDistance = 0,
  taskCoverage = 0,
  taskCompletionRate = 0,
}: RobotKpiBarProps): JSX.Element => {
  const { t } = useTranslation();
  const isUSusers = false;

  return (
    <StyledRobotKpiBar className="robot-kpi-bar">
      <ShortInfo
        className="robot-kpi-bar__short-info"
        icon={<SvgIcon name="wallClock" />}
        title={t('robotDashboard.robotKpis.infos.cleanHrs')}
        value={Numbers.formatNumberDecimalRounded(totalCleaningHours, 1)}
        unit={t('common.hUnit')}
      />
      <ShortInfo
        className="robot-kpi-bar__short-info"
        icon={<SvgIcon name="clean" />}
        title={t('robotDashboard.robotKpis.infos.cleanedArea')}
        value={
          isUSusers
            ? ConvertUnitUtils.covertSquareMetersToSquareFeet(totalCleanedArea || 0)
            : Numbers.formatNumberDecimalRounded(totalCleanedArea || 0, 1)
        }
        unit={isUSusers ? t('common.areaUnitFeetSquare') : t('common.areaUnit')}
      />
      <ShortInfo
        className="robot-kpi-bar__short-info"
        icon={<SvgIcon name="distance" />}
        title={t('robotDashboard.robotKpis.infos.distance')}
        value={
          isUSusers
            ? ConvertUnitUtils.converMetersToMiles(totalDistance || 0)
            : ConvertUnitUtils.convertMetersToKilometers(totalDistance || 0)
        }
        unit={isUSusers ? t('common.mileUnit') : t('common.kmUnit')}
      />
      <ShortInfo
        className="robot-kpi-bar__short-info"
        icon={<SvgIcon name="coverage" />}
        title={t('robotDashboard.robotKpis.infos.coverage')}
        value={Math.round(taskCoverage * 100)}
        unit={t('common.percentUnit')}
      />
      <ShortInfo
        className="robot-kpi-bar__short-info"
        icon={<SvgIcon name="successRace" />}
        title={t('robotDashboard.robotKpis.infos.taskCompleted')}
        value={Math.round(taskCompletionRate)}
        unit={t('common.percentUnit')}
      />
    </StyledRobotKpiBar>
  );
};
