import { Empty, TablePaginationConfig } from 'antd';
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { isNil } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsLinkActivated } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { AnalyticsLink } from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import {
  CleaningTaskReport,
  InputFilterCleaningTaskReportsList,
  InputSortOptions,
  MachineClassification,
  SortOrders,
} from '../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { CleaningReportListActions } from '../../state/cleaningReportListActions';
import * as cleaningReportListSelectors from '../../state/cleaningReportListSelectors';
import { StyledCleaningReportListExact } from './CleaningReportListExact.styles';
import { getCleaningReportListExactColumns } from './columns/CleaningReportListExact.columns';
import { Optional } from 'lib/types/Optional';
import { useGetMachineIdsFilter } from 'app/modules/cleaning/hooks/useGetMachineIdsFilter';
import { Table } from 'lib/components/Table/Table';
import { InfiniteScrollConstants } from 'config/constants';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';

export const CleaningReportListExact = ({ activeTabKey }: { activeTabKey: MachineClassification }): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRowKey, setSelectedRowKey] = useState<string>('');

  const data = useSelector(cleaningReportListSelectors.selectExactData) || [];
  const nextPaginationToken = useSelector(cleaningReportListSelectors.selectExactNextPaginationToken);
  const isLoading = !!useSelector(cleaningReportListSelectors.selectExactIsLoading);
  const isLoadingMoreData = useSelector(cleaningReportListSelectors.selectExactIsLoadingMoreData);
  const sortField = useSelector(cleaningReportListSelectors.selectExactSortField) || 'startedAt';

  const sortOrder = useSelector(cleaningReportListSelectors.selectExactSortOrder) || SortOrders.Desc;
  const availableFilters = useSelector(cleaningReportListSelectors.selectFilters);
  const areFiltersLoading = useSelector(cleaningReportListSelectors.selectAreFiltersLoading);
  const activeSiteFilter = useSelector(cleaningReportListSelectors.selectActiveSiteFilter);
  const activeMachinesFilter = useSelector(cleaningReportListSelectors.selectActiveMachinesFilter);
  const startDate = useSelector(cleaningReportListSelectors.selectPeriodStartDate);
  const endDate = useSelector(cleaningReportListSelectors.selectPeriodEndDate);

  // As the CleaningJob is missing some fields for the chart
  // Commented it until we have final confirm from PO
  /* const chartData = OperatingHoursChartUtils.convertCleaningReportPeriodsToComparisonChartData(data);

  const firstChartColumnData = chartData[0]?.data;
  const secondChartColumnData = chartData[1]?.data;
  const shouldRenderChart =
    !isNil(chartData) &&
    !isNil(firstChartColumnData) &&
    !isNil(secondChartColumnData) &&
    firstChartColumnData.length > 0 &&
    secondChartColumnData.length > 0; */

  const machineIdsFilter = useGetMachineIdsFilter(
    activeMachinesFilter,
    activeSiteFilter,
    availableFilters,
    MachineClassification.Gcd
  );

  const requestParams = (
    startAt: string,
    endAt: string
  ): {
    filter?: InputFilterCleaningTaskReportsList;
    sortOptions?: InputSortOptions;
  } => ({
    filter: {
      period: {
        startAt,
        endAt,
      },
      machineIds: machineIdsFilter,
      machines: {
        classifications: [activeTabKey],
      },
    },
    sortOptions: {
      field: sortField,
      order: sortOrder,
    },
  });

  useEffect(() => {
    if (isNil(startDate) || isNil(endDate)) {
      return;
    }

    // Skip duplicate notifications loading on initial state or filters are loading
    if (
      (typeof availableFilters?.machines === 'undefined' && typeof activeMachinesFilter === 'undefined') ||
      areFiltersLoading
    ) {
      return;
    }

    dispatch(
      CleaningReportListActions.getCleaningReportListExactRequest({
        ...requestParams(startDate, endDate),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_EVENT_ITEMS,
          paginationToken: '',
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    activeSiteFilter,
    startDate,
    endDate,
    availableFilters?.machines,
    activeMachinesFilter,
    areFiltersLoading,
    machineIdsFilter,
    sortField,
    sortOrder,
  ]);

  const loadMore = (): void => {
    if (isNil(startDate) || isNil(endDate)) {
      return;
    }
    dispatch(
      CleaningReportListActions.getCleaningReportListExactMoreDataRequest({
        ...requestParams(startDate, endDate),
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_EVENT_ITEMS,
          paginationToken: nextPaginationToken,
        },
      })
    );
  };

  useEffect(
    () => (): void => {
      dispatch(CleaningReportListActions.exactResetState());
      dispatch(DrawersActions.hideMachineDetailsDrawer());
      dispatch(DrawersActions.hideSiteDetailsDrawer());
    },
    [dispatch]
  );

  const handleClickMachineName = (cleaningTaskReport: CleaningTaskReport): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_EXACT__OPEN_MACHINE_DETAILS,
    });

    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: cleaningTaskReport.machine.id }));
  };

  const handleClickSiteName = (cleaningTaskReport: CleaningTaskReport): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_EXACT__OPEN_SITE_DETAILS,
    });

    if (cleaningTaskReport.machine.site?.id) {
      dispatch(DrawersActions.showSiteDetailsDrawer({ siteId: cleaningTaskReport.machine.site.id }));
    }
  };

  const onTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CleaningTaskReport> | SorterResult<CleaningTaskReport>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    const sorterOrder = sorter.order === 'descend' ? SortOrders.Desc : SortOrders.Asc;
    // Handle for field as array like: ['machine', 'name'] => machine.name
    const sorterField = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field;

    dispatch(CleaningReportListActions.exactSetActiveSortField(sorterField as Optional<string>));
    dispatch(CleaningReportListActions.exactSetActiveSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const tableColumns: ColumnsType<CleaningTaskReport> = getCleaningReportListExactColumns({
    t,
    i18n,
    handleClickMachineName,
    handleClickSiteName,
  });

  const rowClassName = (record: CleaningTaskReport): string =>
    `${record.machine.id}-${record.id}` === selectedRowKey ? 'table__selected-row' : '';

  return (
    <StyledCleaningReportListExact className="cleaning-report-list-exact">
      <div className="cleaning-report-list-exact__container--fluid">
        <Table
          dataSource={data}
          loading={isLoading}
          className="cleaning-report-list-exact__table"
          columns={tableColumns}
          rowKey={(record: CleaningTaskReport): string => `${record.machine.id}-${record.id}`}
          onChange={onTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          locale={{
            emptyText: (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('cleaningReportList.noEventsFound')} />
            ),
          }}
          onRow={(record: CleaningTaskReport): { onClick(): void } => ({
            onClick: (): void => {
              setSelectedRowKey(`${record.machine.id}-${record.id}`);
            },
          })}
          rowClassName={rowClassName}
          scroll={{ x: 1288 }}
          infiniteScroll={{
            height: 798,
            id: 'cleaning-report-list-exact-infinite-scroll',
            next: loadMore,
            nextPaginationToken,
            isLoadingMoreData,
          }}
          showScrollButtons={true}
        />
      </div>
      {/* {shouldRenderChart && (
        <MachineOperatingHoursComparisonChart data={chartData} startDate={startDate ?? ''} endDate={endDate ?? ''} />
      )} */}
    </StyledCleaningReportListExact>
  );
};
