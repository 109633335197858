import styled from 'styled-components';

export const StyledRobotCleaningStatus = styled.div`
  &.robot-cleaning-status__data {
    width: fit-content;
    min-width: 120px;
    height: 24px;
    margin-top: -5px;
    border-radius: 24px;
    text-align: center;
    padding: 5px 8px;
    color: ${(props): string => props.theme.colors.white};
    font-family: ${(props): string => props.theme.fonts.heading3};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};

    &.cleaning-status {
      background-color: ${(props): string => props.theme.colors.semanticInfo};
    }

    &.standby-status {
      background-color: ${(props): string => props.theme.colors.primary};
    }

    &.docking-status {
      background-color: #64d9ff;
    }

    &.manualcharging-status {
      background-color: #00e200;
    }

    &.offline-status {
      background-color: ${(props): string => props.theme.colors.grey300};
    }

    &.na-status {
      background-color: ${(props): string => props.theme.colors.brightGrey};
    }

    &.exploration-status {
      background-color: ${(props): string => props.theme.colors.secondary};
    }

    &.standby-status,
    &.docking-status,
    &.manualcharging-status,
    &.na-status {
      color: ${(props): string => props.theme.colors.black};
    }
  }
`;
