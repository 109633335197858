import styled from 'styled-components';

export const StyledRobotDashboardFilter = styled.div`
  width: 100%;
  padding-top: 24px;

  .robot-dashboard-filter__select-wrapper {
    width: 160px;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .robot-dashboard-filter__select-period {
    width: 250px;
  }

  .robot-dashboard-filter__filter-button,
  .robot-dashboard-filter__filter-reset-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: ${(props): string => props.theme.spacing.xs};
    ${(props): string => props.theme.fontStyles.redesign.base.strong};
  }

  .robot-dashboard-filter__filter-button-icon {
    width: 14px;

    svg {
      margin-bottom: -2px;
    }
  }

  .button-active {
    background-color: ${(props): string => props.theme.colors.black} !important;
    color: white !important;
    path {
      fill: ${(props): string => props.theme.colors.white};
    }
  }

  .robot-dashboard-filter__filter-button-counter {
    line-height: 20px;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2px;
    padding-left: 5px;
    padding-right: 5px;

    color: ${(props): string => props.theme.colors.black};
    background-color: ${(props): string => props.theme.colors.white};
  }

  .robot-dashboard-filter__filter-wrapper-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: ${(props): string => props.theme.spacing.base};
    margin-top: ${(props): string => props.theme.spacing.lg};
  }

  .robot-dashboard-filter__filter-wrapper-container--hidden {
    display: none;
  }

  .robot-dashboard-filter__filter-reset-button-icon {
    width: 16px;
    svg {
      margin-bottom: -3px;
    }
  }
`;
