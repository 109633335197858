import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chunk } from 'lodash-es';
import classnames from 'classnames';
import { RobotTotalCleanedAreaChartElement } from './RobotTotalCleanedAreaChartElement/RobotTotalCleanedAreaChartElement';
import { StyledRobotTotalCleanedAreaChart } from './RobotTotalCleanedAreaChart.styles';
import { ReactComponent as UpArrow } from 'lib/assets/images/upArrow.svg';
import {
  RobotDashboardTotalCleanedAreaData,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { SortIcon } from 'app/components/SortIcon/SortIcon';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';

export interface IRobotTotalCleanedAreaChartProps {
  data: RobotDashboardTotalCleanedAreaData[];
}

const ELEMENTS_PER_PAGE = 10;

export const RobotTotalCleanedAreaChart = ({ data }: IRobotTotalCleanedAreaChartProps): JSX.Element => {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState<SortOrders>(SortOrders.Desc);

  const cleanedAreasMaximum = useMemo(
    () => Math.max(...data.map((datum: RobotDashboardTotalCleanedAreaData): number => datum.cleanedArea ?? 0)),
    [data]
  );

  const sortedData = useMemo(() => {
    const sorterAscending = (a: RobotDashboardTotalCleanedAreaData, b: RobotDashboardTotalCleanedAreaData): number =>
      (a.cleanedArea ?? 0) - (b.cleanedArea ?? 0);
    const sorterDescending = (a: RobotDashboardTotalCleanedAreaData, b: RobotDashboardTotalCleanedAreaData): number =>
      sorterAscending(a, b) * -1;

    const sorterFn = sortOrder === SortOrders.Asc ? sorterAscending : sorterDescending;

    // We need to create a shallow copy of the data, so React updates the DOM after sorting. If we don't do this,
    // React doesn't know the contents of the array changed since it only checks for reference equality.
    return [...data].sort(sorterFn);
  }, [data, sortOrder]);

  const dataPages = useMemo(() => chunk(sortedData, ELEMENTS_PER_PAGE), [sortedData]);

  const isFirstPage = useMemo(() => pageIndex === 0, [pageIndex]);
  const isLastPage = useMemo(() => pageIndex === dataPages.length - 1, [dataPages.length, pageIndex]);

  const handlePrevious = (): void => setPageIndex(index => Math.max(0, index - 1));
  const handleNext = (): void => setPageIndex(index => Math.min(index + 1, dataPages.length - 1));

  const handleSortOrderChange = (): void =>
    setSortOrder(currentSortOrder => {
      setPageIndex(0);

      if (currentSortOrder === SortOrders.Asc) {
        return SortOrders.Desc;
      }

      return SortOrders.Asc;
    });

  return (
    <StyledRobotTotalCleanedAreaChart className="robot-total-cleaned-area-chart">
      <InfoTooltipGlobalStyles />
      <h4 className="robot-total-cleaned-area-chart__title-bar">
        <span className="robot-total-cleaned-area-chart__title">
          {t('robotDashboard.totalCleanedAreaChart.title')}
          <div
            className={classnames('robot-total-cleaned-area-chart__sort-order-button')}
            onClick={handleSortOrderChange}
          >
            <SortIcon sortOrder={sortOrder} />
          </div>
        </span>
        <Tooltip
          title={<InfoTooltip content="robotDashboard.totalCleanedAreaChart.tooltip" />}
          overlayClassName="tooltip-overlay"
          placement="bottomRight"
        >
          <div>
            <SvgIcon name="info" className="robot-dashboard__info-icon" />
          </div>
        </Tooltip>
      </h4>
      <div
        onClick={handlePrevious}
        className={classnames(
          'robot-total-cleaned-area-chart__previous-button',
          'robot-total-cleaned-area-chart__pagination-button',
          { 'robot-total-cleaned-area-chart__pagination-button--disabled': isFirstPage }
        )}
      >
        <UpArrow />
      </div>
      <div className="robot-total-cleaned-area-chart__elements">
        {dataPages[pageIndex] &&
          dataPages[pageIndex].map((datum, index) => (
            <RobotTotalCleanedAreaChartElement
              className="robot-total-cleaned-area-chart__element"
              // While it is usually bad practice to use an index as a collection key in React and should be avoided
              // we doing it here on purpose so DOM elements are reused and the transition animation is applied
              // when changing pages or sorting
              keyIndex={`robot-total-cleaned-area-chart__element-index-${index}`}
              totalCleanedAreasDatum={datum}
              cleanedAreasMaximum={cleanedAreasMaximum}
              key={`robot-total-cleaned-area-chart__element-index-${index}`}
            />
          ))}
      </div>
      <div
        onClick={handleNext}
        className={classnames(
          'robot-total-cleaned-area-chart__next-button',
          'robot-total-cleaned-area-chart__pagination-button',
          { 'robot-total-cleaned-area-chart__pagination-button--disabled': isLastPage }
        )}
      >
        <UpArrow className="robot-total-cleaned-area-chart__next-button-icon" />
      </div>
    </StyledRobotTotalCleanedAreaChart>
  );
};
