// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { t } from 'i18next';
import dayjs, { Dayjs } from 'dayjs';
import { OperatingHoursChartUtils } from './utils/OperatingHoursChartUtils';
import { DateTime } from 'lib/utils/date-handling/DateTime';

export type FormItemValidator = ValidatorRule['validator'];
export type DisabledDatePredicate = (date: Dayjs) => boolean;

// maximum date range up to 5 years including possibility of 2 leap years = 1827
const MAXIMUM_DATE_RANGE_DAYS = 365 * 5 + 2;

export class CleaningReportRequestDateRangeRestrictor {
  public static disabledDatePredicate: DisabledDatePredicate = date => {
    const latestValidDateTime = dayjs(OperatingHoursChartUtils.prepareEndDate(DateTime.today()));

    return dayjs(date).isAfter(latestValidDateTime);
  };

  public static validate: FormItemValidator = async (_ruleObject, value) => {
    const [unpreparedStartDate, unpreparedEndDate] = value;
    const startDate = dayjs(OperatingHoursChartUtils.prepareStartDate(unpreparedStartDate));
    const endDate = dayjs(OperatingHoursChartUtils.prepareEndDate(unpreparedEndDate));

    const durationAsDays = dayjs.duration(endDate.diff(startDate)).asDays();

    if (durationAsDays <= MAXIMUM_DATE_RANGE_DAYS) {
      return Promise.resolve(true);
    }

    return Promise.reject(
      t('cleaningReportRequestDateRangeRestrictor.errors.dateRangeDurationMaximumExceeded', {
        maximumDays: MAXIMUM_DATE_RANGE_DAYS,
      })
    );
  };
}
