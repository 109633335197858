import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { Flex, Form } from 'antd';
import { isArray } from 'lodash-es';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from '../../../../../utils/OperatingHoursChartUtils';
import * as RobotDashboardSelectors from '../../../state/RobotDashboardSelectors';
import { RobotDashboardActions } from '../../../state/RobotDashboardSlice';
import { StyledRobotDashboardFilter } from './RobotDashboardFilter.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { RobotStatusDisplayName } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { OpenSearch, ROBOT_LIST_GROUP_BY, UNASSIGNED } from 'config/constants';
import { CleaningReportRequestDateRangeRestrictor } from 'app/modules/cleaning/CleaningReportRequestDateRangeRestrictor';
import { RangePicker } from 'lib/components/RangePicker/RangePicker';
import { SelectUtils } from 'lib/components/Select/SelectUtils';
import { Select } from 'lib/components/Select/Select';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { ThemeConstants } from 'config/theme';
import { Segmented } from 'lib/components/Segmented/Segmented';
import { Switch } from 'lib/components/Switch/Switch';

interface IRobotDashboardFilterForm {
  dateRangeLocal: [Dayjs | null, Dayjs | null] | null;
}

export const RobotDashboardFilter = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState(false);
  const [isSitesFilterOpen, setIsSitesFilterOpen] = useState(false);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);

  const isSitesLoading = useSelector(RobotDashboardSelectors.selectSitesIsLoading);
  const activeSitesFilter = useSelector(RobotDashboardSelectors.selectActiveSites);
  const status = useSelector(RobotDashboardSelectors.selectStatus);
  const groupBy = useSelector(RobotDashboardSelectors.selectGroupBy);
  const startDate = useSelector(RobotDashboardSelectors.selectPeriodStartDate);
  const endDate = useSelector(RobotDashboardSelectors.selectPeriodEndDate);
  const isHidingOffline = useSelector(RobotDashboardSelectors.selectIsHidingOfflineRobots);
  const sites = useSelector(RobotDashboardSelectors.selectAvailableSites);

  const siteOptions = [{ label: t('robotDashboard.unassigned'), value: UNASSIGNED }].concat(
    (sites || []).map(site => ({
      label: site.name,
      value: site.id,
    }))
  );

  const statusOptions = useMemo(
    () =>
      Object.entries(RobotStatusDisplayName)
        .filter(([_key, value]) => (isHidingOffline ? value !== RobotStatusDisplayName.Offline : value))
        .map(([key, value]) => ({
          label: t(`robotDashboard.${key}`),
          value,
        })),
    [isHidingOffline, t]
  );

  const groupByOptions = useMemo(
    () =>
      Object.entries(ROBOT_LIST_GROUP_BY).map(([_key, value]) => ({
        label: t(`robotDashboard.filter.groupBy.${value}`),
        value,
      })),
    [t]
  );

  const getTotalFilterCount = (): number => {
    const sanitizedActiveStatusFilter = status ?? [];
    const sanitizedActiveSiteFilter = activeSitesFilter ?? [];

    const count = [sanitizedActiveStatusFilter, sanitizedActiveSiteFilter]
      .map(value => {
        if (!isArray(value)) {
          return [value];
        }

        return value;
      })
      .reduce((acc, filterValues) => acc + (filterValues ?? []).length, 0);

    return count;
  };

  const handleHideOfflineRobots = (checked: boolean): void => {
    dispatch(
      RobotDashboardActions.robotDashboardSetActiveHidingOfflineRobotsFilter({
        isHidingOfflineRobots: checked,
      })
    );
  };

  useEffect(() => {
    dispatch(
      RobotDashboardActions.robotDashboardGetSitesListRequest({
        paginationOptions: {
          limit: OpenSearch.MAX_RESULT_WINDOW,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearFilter = useCallback(() => {
    form.resetFields();
    dispatch(RobotDashboardActions.robotDashboardFilterResetState());
  }, [form, dispatch]);

  const handleChangeStatuses = (values: string[]): void => {
    dispatch(RobotDashboardActions.robotDashboardFilterSetStatus(values));
  };

  const handleChangeSite = (values: string[]): void => {
    dispatch(RobotDashboardActions.robotDashboardFilterSetSites(values));
  };

  const handleChangeGroupBy = (value: string): void => {
    dispatch(RobotDashboardActions.robotDashboardFilterSetGroupBy(value));
  };

  const onFinish = (values: IRobotDashboardFilterForm): void => {
    const { dateRangeLocal: dates } = values;

    if (dates && dates[0] && dates[1]) {
      dispatch(
        RobotDashboardActions.robotDashboardFilterSetPeriod({
          startDate: OperatingHoursChartUtils.prepareStartDate(dates[0].toDate()).toISOString(),
          endDate: OperatingHoursChartUtils.prepareEndDate(dates[1].toDate()).toISOString(),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      RobotDashboardActions.robotDashboardFilterSetPeriod({
        startDate: OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
        endDate: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      dateRangeLocal: [dayjs(startDate), dayjs(endDate)],
    });
  }, [endDate, form, startDate]);

  useEffect(
    () => (): void => {
      handleClearFilter();
    },
    [handleClearFilter]
  );

  return (
    <StyledRobotDashboardFilter>
      <div className="robot-dashboard-filter">
        <Form
          form={form}
          className="robot-dashboard-filter__form"
          name="robot-dashboard-filter__form"
          layout="horizontal"
          onFinish={onFinish}
          onValuesChange={(): void => {
            form.submit();
          }}
          autoComplete="off"
          validateTrigger="onSubmit"
          initialValues={{
            dateRangeLocal: [dayjs(startDate), dayjs(endDate)],
          }}
        >
          <Flex vertical={false} gap={ThemeConstants.BASE_GAP} wrap>
            <div className="robot-dashboard-filter__select-wrapper robot-dashboard-filter__select-period">
              <Form.Item name="dateRangeLocal" required className="robot-dashboard-filter__select">
                <RangePicker disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate} />
              </Form.Item>
            </div>

            <SecondaryButton
              loading={isSitesLoading}
              size="s"
              onClick={(): void => setAreFiltersVisible(prevState => !prevState)}
              className={classnames('robot-dashboard-filter__filter-button', {
                'button-active': areFiltersVisible,
              })}
              key="robot-dashboard-filter__filter-button"
            >
              <span>{t('machineList.filter.filter')}</span>
              {getTotalFilterCount() > 0 && (
                <span className="robot-dashboard-filter__filter-button-counter">{getTotalFilterCount() || ''}</span>
              )}
              <SvgIcon name="filter" className="robot-dashboard-filter__filter-button-icon" />
            </SecondaryButton>

            <div className="robot-dashboard-filter__segmented-group-by">
              <Segmented<string> options={groupByOptions} onChange={handleChangeGroupBy} value={groupBy || undefined} />
            </div>

            <Flex className="robot-dashboard-filter__hide-robot-offline" align="center" gap={8}>
              <Switch
                defaultChecked={isHidingOffline}
                onChange={handleHideOfflineRobots}
                className="robot-dashboard-filter__switch"
              />
              {t('robotDashboard.filter.hideOfflineRobots')}
            </Flex>
          </Flex>
          <div
            className={classnames('robot-dashboard-filter__filter-wrapper-container', {
              'robot-dashboard-filter__filter-wrapper-container--hidden': !areFiltersVisible,
            })}
          >
            <div className="robot-dashboard-filter__select-wrapper">
              <Select
                className="robot-dashboard-filter__select"
                onChange={handleChangeStatuses}
                showSearch={true}
                {...SelectUtils.getMultiSelectionProps({
                  mode: 'multiple',
                  options: statusOptions,
                  onDropdownVisibleChange: (isOpen: boolean): void => setIsStatusFilterOpen(isOpen),
                  dropdownVisibleState: isStatusFilterOpen,
                  valueArray: status,
                  dropdownLabel: t('robotDashboard.filter.status'),
                })}
              />
            </div>

            <div className="robot-dashboard-filter__select-wrapper">
              <Select
                loading={isSitesLoading}
                className="robot-dashboard-filter__select"
                onChange={handleChangeSite}
                showSearch={true}
                {...SelectUtils.getMultiSelectionProps({
                  mode: 'multiple',
                  options: siteOptions,
                  onDropdownVisibleChange: (isOpen: boolean): void => setIsSitesFilterOpen(isOpen),
                  dropdownVisibleState: isSitesFilterOpen,
                  valueArray: activeSitesFilter,
                  dropdownLabel: t('robotDashboard.filter.site'),
                })}
              />
            </div>

            <SecondaryButton
              size="s"
              onClick={handleClearFilter}
              className="robot-dashboard-filter__filter-reset-button"
              key="robot-dashboard-filter__filter-reset-button"
            >
              <SvgIcon name="arrowSkip" className="robot-dashboard-filter__filter-reset-button-icon" />
              <span>{t('common.resetAll')}</span>
            </SecondaryButton>
          </div>
        </Form>
      </div>
    </StyledRobotDashboardFilter>
  );
};
