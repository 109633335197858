import styled from 'styled-components';

export const StyledRobotCleaningProgress = styled.div`
  width: 100%;

  .robot-cleaning-progress__next-progress,
  .robot-cleaning-progress__progressing {
    font-family: ${(props): string => props.theme.fonts.captionNews};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
  }

  .robot-cleaning-progress__next-progress {
    display: flex;
    gap: 8px;
  }

  .robot-cleaning__progress-date {
    margin: 0;
    width: auto;
    word-break: break-word;
    white-space: normal;

    div {
      margin: 0;
      width: auto;
      word-break: break-word;
      white-space: normal;
    }
  }
`;
